import React, { useCallback } from 'react'
import TrainerSection from '../../../../../../../components/TrainerSection'

import PriceSection from './components/PriceSection'
import TextSection from './components/TextSection'
import { Form } from 'antd'

function ChannelFormMarketplace({
  onPrice,
  onMarketplaceValues,
  marketplaceData,
  form
}) {
  const validateForm = useCallback(() => {
    const { validateFields } = form
    validateFields()
  })
  return (
    <Form>
      <TrainerSection />
      <PriceSection
        onPriceObj={obj => {
          onPrice(obj)
          validateForm()
        }}
        form={form}
        values={marketplaceData}
      />
      <TextSection
        onValues={val => {
          onMarketplaceValues(val)
          validateForm()
        }}
        form={form}
        values={marketplaceData}
      />
    </Form>
  )
}

export default Form.create()(ChannelFormMarketplace)
