import React, { Component } from 'react'
import { Button } from 'antd'
import ReactCrop from 'react-image-crop'
import { customContentClass } from './cropImage.ctrl'
import './styles.scss'
import 'react-image-crop/lib/ReactCrop.scss'

class ImageCropContainer extends Component {
  state = {
    crop: {
      unit: '%', // default, can be 'px' or '%'
      x: 0,
      y: 0,
      aspect: this.props.circle ? 1 : this.props.freeCrop ? undefined : 16 / 9,
      width: 100,
      ...(this.props.freeCrop && { height: '100' })
    },
    croppedImageUrl: null
  }

  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop)
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    // resolve as base64
    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL('image/jpeg')
      resolve(this.fileUrl)
    })
  }

  handleBtnCropAction = async type => {
    const { file, handleUploadImage, handleClosePopup } = this.props

    if (type === 'save') {
      const data = file
      data.croppedImage = this.fileUrl
      handleUploadImage(data)
    }
    handleClosePopup(type)
  }

  onCropChange = newCrop => {
    this.setState({
      crop: newCrop
    })
  }

  render() {
    const { crop } = this.state
    const { image, node, circle, t } = this.props
    console.log(image, node, circle)
    return (
      <div className={customContentClass(node)}>
        <div className="crop-container">
          <ReactCrop
            imageStyle={{
              maxHeight: '65rem'
            }}
            style={{ backgroundColor: 'white' }}
            src={image}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            circularCrop={circle}
            keepSelection
          />
        </div>
        <div className="wrap-btn-actions">
          <Button onClick={() => this.handleBtnCropAction('close')}>
            {t('labels:Cancel')}
          </Button>
          <Button onClick={() => this.handleBtnCropAction('save')}>
            {t('labels:done')}
          </Button>
        </div>
      </div>
    )
  }
}

export default ImageCropContainer
