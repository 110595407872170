import React, { Component, Fragment } from 'react'
import {
  ReportTable,
  CustomTabs,
  ReportSelect,
  PaginationRightAligned,
  BackButton
} from '../../styled.js'
import { LeftOutlined } from '@ant-design/icons'
import { Tabs, Icon, Row, Col, Card, Divider, Pagination, Select } from 'antd'
import { generateCSV } from '../../../../utils'
import moment from 'moment/moment'
import '../../Report/style.css'
import { isSafari } from 'react-device-detect'
import { Search, Spinner } from '../../../../components'
import { withTranslation } from 'react-i18next'
import { userImage } from '../../images/index.js'
import ReportHeader from '../../components/ReportHeader.jsx'
import TypeSelect from '../../components/TypeSelect.jsx'
import { report } from '../../../../constants'
import { api } from '../../../../services'

const { TabPane } = Tabs

const columns = t => [
  {
    title: 'Participant',
    dataIndex: 'avatar',
    render: record => (
      <img
        alt=""
        src={record && record.link ? record.link : userImage}
        className="report-table-image"
      />
    )
  },
  {
    textWrap: 'word-break',
    title: 'Username',
    dataIndex: 'username'
  },
  {
    title: t('v4:group'),
    dataIndex: 'group'
  },

  {
    title: t('v4:pass_score'),
    dataIndex: 'pass_score'
  },
  {
    title: t('v4:avg_score'),
    dataIndex: 'avg_score'
  },

  {
    title: t('v2:time_spent'),
    dataIndex: 'spending_time',
    render: time => {
      const t = moment.duration(time).format('d[d] h[hr ] m[min] s[s]')
      return t
    }
  },
  {
    title: t('v4:detail')
  }
]

let expandSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="#F0F2F5" />
    <path d="M4 6L8.5 11L13 6" stroke="#246CF6" />
  </svg>
)

class Details extends Component {
  state = {
    activeFilterValue: 24,
    showLessen: false,
    lesson: [],
    visible: false,
    expendIdNo: 0,
    coverImageLink: '',
    loading: true,
    enrollSize: 0,
    viewerSize: 0,
    completedUserSize: 0,
    isLesson: false,
    expandedRowKeys: 0,
    searchParticipantsData: [],
    participantsData: [],
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE,
    searchType: 'search',
    groups: []
  }

  onChange = (field, value) => {
    this.setState({
      [field]: value
    })
  }

  download = value => {
    const { courseData: data, participantsData: enrolled_users } = this.state

    console.log({ data })

    let dataCSV = []
    let header = []
    data &&
      dataCSV.push({
        title: data.title,
        username: '',
        group: '',
        employee_id: '',
        date: '',
        lessons: data.courses_lessons && data.courses_lessons.length,
        quizzes: data.quizzes && data.quizzes.length,
        score: '',
        pass_status: '',
        total_pass: value ? `${value.toFixed(2)} %` : '0 %',
        time_spent: ''
      })
    enrolled_users &&
      enrolled_users.forEach(item => {
        dataCSV.push({
          title: '',
          username: item.username,
          group: item.group && item.group.replace(/,/g, ' -'),
          employee_id: item.employee_id || 'N/A',
          date: moment(item.created_at).format('YYYY-MM-DD'),
          lessons: '',
          quizzes: '',
          score: item.avg_score ? item.avg_score.toFixed() : 0,
          pass_status: item.status === true ? 'Passed' : 'Not Passed',
          total_pass: '',
          time_spent: item.spending_time
            ? `${moment
                .duration(item.spending_time)
                .asMinutes()
                .toFixed(2)} minutes`
            : 'N/A'
        })
      })

    header = [
      'Title',
      'Username',
      'Group',
      'Employee Id',
      'Date Taken',
      'Lessons',
      'Quizzes',
      'Score',
      'Pass Status',
      'Total Pass Percentage',
      'Time Spent In Course'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-report.csv')
      ele.click()
    }
  }

  downloadLesson = () => {
    const {
      Lessons: {
        data: { lesson_lists = [] }
      }
    } = this.props.data
    const dataCSV = lesson_lists
      .map(x => {
        return {
          id: x.id,
          title: x.title,
          description: x.description,
          quizzes: x.quizzes,
          completed: x.completed,
          date: moment(x.created_at).format('YYYY-MM-DD')
        }
      })
      .filter(x => {
        return x !== null
      })
    const header = ['#', 'Title', 'Description', 'Quizzes', 'Completed', 'Date']
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-lesson-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-lesson-report.csv')
      ele.click()
    }
  }

  componentDidMount() {
    const {
      match: { params },
      getCourseReport
    } = this.props
    // getCourseReport(params.id, 'Lessons')
    getCourseReport(params.id).then(data => {
      if (data) {
        this.setState({
          participantsData: data.enrolled_users,
          searchParticipantsData: data.enrolled_users,
          loading: false,
          courseData: data
        })
      }
    })
    this.fetchGroups()
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      const enroll = this.props.data.Analytics.data.enrollments
      const enrollSize = enroll ? enroll.length * 68 : 0
      const viewers = this.props.data.Analytics.data.viewers
      const viewerSize = viewers ? viewers.length * 68 : 0
      const completeUsers = this.props.data.Analytics.data.completed_users
      const completeUserSize = completeUsers ? completeUsers.length * 68 : 0

      this.setState({
        enrollSize,
        viewerSize,
        completeUserSize
      })
    }
  }

  handleChange(value) {
    this.setState({
      activeFilterValue: value === '1' ? 24 : Number(value)
    })
  }

  onExpand = (expanded, record) => {
    if (this.state.expendIdNo == record.id) {
      this.setState({
        expendIdNo: 0
      })
    } else {
      this.setState({
        expendIdNo: record.id
      })
    }

    var keys = []
    if (expanded) {
      keys.push(record.id)
    }

    console.log(keys, record)

    this.setState({ expandedRowKeys: keys })
  }

  customExpandIcon = props => {
    return this.state.expendIdNo == props.record.id ? (
      <Icon type="up" />
    ) : (
      <Icon type="down" />
    )
  }

  getTimeDuration = time => {
    const t = moment.duration(time).format('m [min] s[s]')
    return t
  }

  renderLessonTable = ({ lessons }) => {
    const { t } = this.props
    const totalScore = lessons.reduce((total, item) => total + item.scores, 0)
    const totalSpendingTime = lessons.reduce(
      (total, item) => total + item.spend_time,
      0
    )

    return (
      <table className="lessons-table">
        <thead>
          <tr>
            <th>{t('reports:lesson')}</th>
            <th>{t('reports:score')}</th>
            <th>{t('v2:time_spent')}</th>
          </tr>
        </thead>
        <tbody>
          {lessons.map((item, index) => (
            <tr key={index} style={{ fontWeight: 'normal' }}>
              <td>{item.title}</td>
              <td style={{ textAlign: 'center' }}>{item.scores}</td>
              <td>{this.getTimeDuration(item.spend_time)}</td>
            </tr>
          ))}
          <tr>
            <td>{t('v2:total')}</td>
            <td style={{ textAlign: 'center' }}>{totalScore}</td>
            <td>{this.getTimeDuration(totalSpendingTime)}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  onChangePagination = page => {
    this.setState({
      page: page
    })
  }

  handleSearchType = type => {
    const {
      Analytics: {
        data: { enrolled_users: list }
      }
    } = this.props.data

    if (type === 'name') {
      this.setState({
        searchType: 'searchByname'
      })
    } else if (type === 'id') {
      this.setState({
        searchType: 'searchById'
      })
    } else if (type === 'email') {
      this.setState({
        searchType: 'searchByemail'
      })
    } else if (type === 'group_name') {
      this.setState({
        searchType: 'searchByGroup'
      })
    } else {
      this.setState({
        searchType: 'search',
        participantsData: list
      })
    }
  }

  onSearchWithType = val => {
    const { searchType, groups } = this.state

    // const {
    //   Analytics: {
    //     data: { enrolled_users: list }
    //   }
    // } = this.props.data
    // console.log('test--->', this.state.participantsData)
    var list = this.state.searchParticipantsData

    if (val === '') {
      this.setState({ participantsData: list })
      return
    }

    if (searchType === 'search') {
      const searchResult =
        this.state.participantsData &&
        this.state.participantsData.filter(item => {
          return item.username.toLowerCase().includes(val.toLowerCase())
        })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByname') {
      const searchResult =
        list &&
        list.filter(item => {
          return item.username.toLowerCase().includes(val.toLowerCase())
        })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByGroup' && groups.length > 0) {
      const searchResult =
        list &&
        list.filter(item => {
          return (
            item.group && item.group.toLowerCase().includes(val.toLowerCase())
          )
        })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchById') {
      const searchResult =
        list &&
        list.filter(item => {
          return val.includes(item.user_id)
        })
      this.setState({ participantsData: searchResult })
    } else if (searchType === 'searchByemail') {
      const searchResult =
        list &&
        list.filter(item => {
          return item.email.toLowerCase().includes(val.toLowerCase())
        })
      this.setState({ participantsData: searchResult })
    }
  }

  handleGroupSearch = value => {
    this.onSearchWithType(value)
  }

  fetchGroups = () => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    api.organizations.get().then(res => {
      const activeOrg = res.data.rows.filter(
        item => item.id === active_organization_id
      )
      if (activeOrg && activeOrg[0]) {
        this.setState({
          groups: activeOrg[0].groups
        })
      }
    })
  }

  callbackTabClicked = key => {
    if (key === '1') {
      this.props.history.push('/reports', 'user')
    } else if (key === '3') {
      this.props.history.push(`/reports`, 'marketplace')
    }
  }

  render() {
    const {
      loading,
      participantsData: list,
      page,
      pageSize,
      searchType,
      groups,
      courseData
    } = this.state
    const { t } = this.props
    const {
      CourseAnalytic: { fetch }
    } = this.props.data

    const filterPassedData = list.filter(users => users.status === true)
    const total_percentage = (filterPassedData.length / list.length) * 100

    console.log({
      fl: filterPassedData.length,
      en: list.length,
      tp: total_percentage
    })

    const dataList = Array.isArray(list)
      ? list.slice(
          this.createOffset(page, pageSize),
          this.createOffset(page, pageSize) + 10
        )
      : []

    return (
      <Fragment>
        {fetch || loading ? (
          <Spinner />
        ) : (
          <div className="content-warp">
            <div className="report-warp" style={{ direction: 'ltr' }}>
              <CustomTabs
                defaultActiveKey="0"
                onTabClick={this.callbackTabClicked}
                tabBarExtraContent={
                  <ReportHeader
                    download={() => this.download(total_percentage)}
                    isDateActive
                  />
                }
              >
                <TabPane tab={t('reports:workshop_report')} key="0">
                  <TypeSelect defaultValue="courses" />

                  <div className="custom-table">
                    <Row>
                      <Col span={8}>
                        <Row>
                          <BackButton
                            onClick={() =>
                              this.props.history.push('/reports/course/')
                            }
                            // type="link"
                            size="large"
                            // style={{  }}
                            // icon={}
                            style={{
                              // width: '92%',
                              marginTop: '20px',
                              marginLeft: '15px'
                            }}
                          >
                            <LeftOutlined />
                            {t('buttons:back')}
                          </BackButton>
                        </Row>
                        <Card
                          className={
                            list && list.length === 0
                              ? 'custom-card-empty'
                              : 'custom-card'
                          }
                        >
                          <img
                            className="table-img"
                            src={
                              courseData &&
                              courseData.cover &&
                              courseData.cover.link
                            }
                            alt=""
                          />
                          <h3 className="custom-title">
                            {courseData && courseData.title}
                          </h3>
                          <p className="custom-description">
                            {courseData && courseData.description}
                          </p>
                          <Divider
                            type="horizontal"
                            dashed
                            style={{ borderColor: 'rgb(155 155 155)' }}
                          />

                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:created_date').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {moment(
                                courseData && courseData.created_at
                              ).format('YYYY/MM/DD')}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:participants').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {courseData && courseData.enrolled_users.length}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('labels:lessons').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {courseData && courseData.courses_lessons.length}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('reports:quizzes').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {courseData && courseData.quizzes.length}
                            </Col>
                          </Row>
                          <Row gutter={[0, 40]}>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:passed').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {courseData && courseData.passed.length}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              push={1}
                              style={{ fontWeight: 'bold', color: 'black' }}
                            >
                              {t('v4:not_completed').toUpperCase()}
                            </Col>
                            <Col span={12} push={6}>
                              {courseData && courseData.failed.length}
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col span={16}>
                        <ReportTable
                          rowKey="id"
                          columns={columns(t)}
                          dataSource={dataList}
                          expandedRowRender={record =>
                            this.renderLessonTable(record)
                          }
                          onExpand={this.onExpand}
                          expandIcon={this.customExpandIcon}
                          expandedRowKeys={this.state.expandedRowKeys || []}
                          expandIconAsCell={false}
                          expandIconColumnIndex={6}
                          expandRowByClick
                          pagination={false}
                          width
                          style={{
                            width: '92%',

                            marginLeft: '35px'
                          }}
                          title={() => (
                            <>
                              <Row
                                className="sharelook-course-participants-wrapper"
                                style={{ marginLeft: '15px', marginTop: '3px' }}
                              >
                                <Col span={13}>
                                  <div className="reports-user-search">
                                    {searchType === 'searchByGroup' &&
                                      groups.length > 0 && (
                                        <ReportSelect
                                          onChange={this.handleGroupSearch}
                                          className="rounded-select"
                                          dropdownStyle={{
                                            borderRadius: '9px'
                                          }}
                                          arrowColor
                                          defaultValue={t('v4:select_group')}
                                        >
                                          {groups.map(group => {
                                            return (
                                              <Select.Option
                                                key={group.id}
                                                value={group.title}
                                                className="select-option-user"
                                              >
                                                {group.title}
                                              </Select.Option>
                                            )
                                          })}
                                        </ReportSelect>
                                      )}

                                    {(searchType !== 'searchByGroup' ||
                                      (searchType === 'searchByGroup' &&
                                        groups.length === 0)) && (
                                      <Search
                                        placeholder={t('v4:search_people')}
                                        onChange={e =>
                                          this.onSearchWithType(e.target.value)
                                        }
                                      />
                                    )}
                                  </div>
                                </Col>

                                <Col className="report-title" span={5} push={2}>
                                  {t('v4:total')} {list && list.length}{' '}
                                  {t('reports:participants').toUpperCase()}
                                </Col>
                                <Col
                                  className="report-pagination"
                                  span={6}
                                  style={{ marginTop: '14px' }}
                                >
                                  <PaginationRightAligned>
                                    <Pagination
                                      total={list && list.length}
                                      current={page}
                                      pageSize={pageSize}
                                      onChange={this.onChangePagination}
                                      size="small"
                                      simple
                                    />
                                  </PaginationRightAligned>
                                </Col>
                              </Row>
                              <Divider style={{ margin: '0px' }} />
                            </>
                          )}
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab={t('reports:user_report')} key="1"></TabPane>
                <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
              </CustomTabs>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
export default withTranslation('report')(Details)
