import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Canvas, Node, Edge } from 'reaflow'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import './styles.scss'

import { getNestedChildren } from '../mazeHelper'
import { Alert, Icon, Popover, Tooltip } from 'antd'

const MazeTree = ({
  data: propData,
  goToTemplate,
  showRightPanel,
  markDeadEnd,
  unmarkDeadEnd
}) => {
  const [state, setState] = useState({
    myScenarioIndex: 1,
    myLayerIndex: 0,
    layers: 2,
    treeData: [],
    isLayerActive: true,
    data: [],
    nodes: [],
    links: [],
    maxWidth: 0,
    maxHeight: 0
  })

  useEffect(() => {
    if (propData !== state.data) {
      customizeTreeData(propData)
    }
  }, [propData])

  const setWidthHeight = useCallback(layout => {
    const { height, width } = layout
    setState(prevState => ({
      ...prevState,
      maxWidth: width,
      maxHeight: height
    }))
  }, [])

  const handleGoToTemplate = useCallback(
    (e, code) => {
      if (e.detail === 2) {
        goToTemplate(code)
      }
    },
    [goToTemplate]
  )

  const customizeTreeData = useCallback(data => {
    var nest = getNestedChildren(data)
    if (nest) generateIdealPath(data, nest[0])
  }, [])

  const generateIdealPath = useCallback((data, JSON = {}) => {
    var idealPath = []
    function checkIdealCondition(arr) {
      if (arr.length === 0 || arr === undefined) return
      arr.forEach(item => {
        if (item.values.is_ideal_option) {
          const scenario = item.children
          delete item.children
          idealPath.push(item)
          if (scenario.length === 0) return
          const scenarioChildren = scenario[0].children
          delete scenario[0].children
          idealPath.push(scenario[0])
          checkIdealCondition(scenarioChildren)
        }
      })
    }

    function getIdealPath() {
      if (JSON.code === 1) {
        const children = JSON.children
        delete JSON.children
        idealPath.push(JSON)
        checkIdealCondition(children)
      }
    }

    getIdealPath()

    var ids = new Set(idealPath.map(d => d.code))
    var merged = [...idealPath, ...data.filter(d => !ids.has(d.code))]
    const formattedData = merged.map(item => {
      const isIdealPath = idealPath.find(e => e.code === item.code)
      if (isIdealPath) {
        return { ...item, pathType: 'IDEAL' }
      }
      return item
    })

    const treeData = formattedData.sort((a, b) => a.code - b.code)

    setLinksAndNodes(treeData)
  }, [])

  const setLinksAndNodes = useCallback(async (data = []) => {
    let myLinks = []
    const nodes =
      data &&
      data.map(item => ({
        id: item.code,
        type: item.type,
        content: item.values.content,
        pathType: item.pathType,
        values: item.values,
        noChildren: item.children && item.children.length < 1,
        isDeadEnd: item.is_dead_end,
        index: item.index,
        height: 125,
        width: 250
      }))

    data.forEach((item, index) => {
      if (item && item.parent) {
        let isNormalIdealPath = false

        const otherChildItem = data.find(el => el.code === item.parent)
        if (
          (otherChildItem &&
            otherChildItem.pathType === 'IDEAL' &&
            item.pathType === 'IDEAL') ||
          (item.type === 'Option' && item.values.is_ideal_option) ||
          (otherChildItem &&
            otherChildItem.type === 'Option' &&
            otherChildItem.values.is_ideal_option)
        ) {
          isNormalIdealPath = true
        }
        myLinks.push({
          from: item.parent,
          to: item.code,
          id: `${item.parent} - ${item.code}`,
          isIdealPath: isNormalIdealPath
        })
      }

      if (item && item.possible_scenario_code) {
        const possibleScenarioItem = data.filter(
          item => item.type === 'Scenario'
        )[item.possible_scenario_code - 1]

        myLinks.push({
          to: possibleScenarioItem.code,
          from: item.code,
          id: `${item.code} - ${possibleScenarioItem.code}`
        })
      }

      if (index === data.length - 1) {
        setState(prevState => ({
          ...prevState,
          nodes,
          links: myLinks
        }))
      }
    })
  }, [])

  const { nodes = [], links = [], maxHeight, maxWidth } = state

  return (
    <TransformWrapper
      initialScale={0.75}
      minScale={0.25}
      maxScale={2}
      limitToBounds={true}
      doubleClick={{
        mode: 'reset'
      }}
      initialPositionX={0}
      initialPositionY={0}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div className="maze-tree-wrapper">
          <div className="maze-tree-wrapper__controls">
            <Tooltip title="Zoom In">
              <Icon type="zoom-in" onClick={() => zoomIn(0.25)} />
            </Tooltip>
            <Tooltip title="Zoom Out">
              <Icon type="zoom-out" onClick={() => zoomOut(0.25)} />
            </Tooltip>
            <Tooltip title="Reset">
              <Icon type="reload" onClick={() => resetTransform()} />
            </Tooltip>
          </div>

          <TransformComponent
            wrapperStyle={{
              position: 'relative',
              width: '100%',
              height: '100%'
            }}
          >
            <div
              style={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: '100%',
                width: '100%'
              }}
            >
              {showRightPanel && nodes.length > 0 && links.length > 0 && (
                <Canvas
                  maxWidth={maxWidth}
                  maxHeight={maxHeight}
                  nodes={nodes}
                  edges={links}
                  fit={true}
                  center={true}
                  node={nodeProps => (
                    <Node>
                      {event => (
                        <foreignObject
                          height={event.height}
                          width={event.width}
                          x={0}
                          y={0}
                        >
                          <div
                            className="main-node"
                            style={{
                              borderColor: nodeProps.properties.isDeadEnd
                                ? 'red'
                                : 'black',
                              background:
                                nodeProps.properties.pathType === 'IDEAL'
                                  ? '#5057d5'
                                  : nodeProps.properties.type === 'Scenario' &&
                                    nodeProps.properties.content
                                  ? '#5057d5'
                                  : nodeProps.properties.type === 'Option' &&
                                    nodeProps.properties.values.is_ideal_option
                                  ? '#5057d5'
                                  : nodeProps.properties.content
                                  ? 'green'
                                  : 'white',
                              color:
                                nodeProps.properties.pathType === 'IDEAL' ||
                                nodeProps.properties.content
                                  ? 'white'
                                  : 'black'
                            }}
                            onClick={e =>
                              handleGoToTemplate(e, nodeProps.properties.id)
                            }
                          >
                            {nodeProps.properties.type}{' '}
                            {nodeProps.properties.index}
                            {nodeProps.properties.noChildren && (
                              <div
                                className="deadend"
                                style={{
                                  backgroundColor: nodeProps.properties
                                    .isDeadEnd
                                    ? 'red'
                                    : 'black'
                                }}
                                onClick={() =>
                                  nodeProps.properties.isDeadEnd
                                    ? unmarkDeadEnd(nodeProps.properties.id)
                                    : markDeadEnd(nodeProps.properties.id)
                                }
                              >
                                {nodeProps.properties.isDeadEnd
                                  ? 'Unmark as deadend'
                                  : 'Mark as deadend'}
                              </div>
                            )}
                          </div>
                        </foreignObject>
                      )}
                    </Node>
                  )}
                  edge={edgeProps => (
                    <Edge
                      style={{
                        stroke: edgeProps.properties.isIdealPath
                          ? 'blue'
                          : 'black',
                        strokeWidth: edgeProps.properties.isIdealPath
                          ? '3px'
                          : '1px'
                      }}
                    />
                  )}
                  onLayoutChange={layout => setWidthHeight(layout)}
                />
              )}
            </div>
            {/* </div> */}
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  )
}

export default MazeTree
