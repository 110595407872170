import { isSafari } from 'react-device-detect'
import moment from 'moment'

const getPercent = (part, total) =>
  total && part ? Math.round((part / total) * 100) : 0

function bytesToSize(bytes) {
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  if (bytes === 0) {
    return '0 Byte'
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + SIZES[i]
}

function generateCSV(dataCSV, header) {
  let csvContent =
    header.join(',') +
    '\n' +
    dataCSV
      .map(dataRow =>
        Object.keys(dataRow)
          .map(key => `"${dataRow[key]}"`)
          .join(',')
      )
      .join('\n')

  if (isSafari) {
    return csvContent
  } else {
    return csvContent
    // window.open('data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent))
  }
}

function getUrlParameter(name, search) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const rangeArray = n => [...Array(n).keys()]

function getFileType(types) {
  const type = types ? types.split('/') : ['']
  return type[0]
}

function getFileExtension(types) {
  const extension = types ? types.split('/') : ['']
  return extension[1]
}

// MAHESH_ADDED

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (audio/mpeg)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @return Blob
 */
function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  let byteCharacters = atob(b64Data)
  let byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)

    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, { type: contentType })
}

/**
 * Create an Audio file according to its data base64 content only.
 *
 * @param filename {String} The name of the file that will be created
 * @param content {String} Important : The content can't contain the following string (data:audio/mpeg[or any other format];base64,). Only the base64 string is expected.
 * @param contentType {String}
 */
function saveBase64AsAudioFile(filename, content, contentType) {
  // Convert the base64 string in a Blob
  let blob = b64toBlob(content, contentType)
  return new File([blob], filename, { type: contentType })
}

function getImageDimension(image) {
  let img = new Image()
  img.src = image
  return `${img.width} * ${img.height}`
}

function durationToTime(duration) {
  let sec_num = parseInt(duration, 10)
  let hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - hours * 3600) / 60)
  let seconds = sec_num - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ':' + minutes + ':' + seconds
}

const getMinutes = (startTimeStamp, endTimeStamp) => {
  const formatStartTimeStamp = moment(startTimeStamp).format('YYYY-MM-DD HH:mm')
  const formatEndTimeStamp = moment(endTimeStamp).format('YYYY-MM-DD HH:mm')
  const startTime = moment(formatStartTimeStamp)
  const endTime = moment(formatEndTimeStamp)
  const duration = moment.duration(endTime.diff(startTime))
  return duration.format('hh:mm:ss', { trim: false })
}

export {
  getPercent,
  bytesToSize,
  generateCSV,
  getUrlParameter,
  rangeArray,
  getFileType,
  getFileExtension,
  saveBase64AsAudioFile,
  getImageDimension,
  durationToTime,
  getMinutes
}
