import React, { useEffect, useRef, useState } from 'react'
import className from 'classnames'
import Dropzone from 'react-dropzone'
import { Button, Form, Icon, Modal, Menu, Dropdown, Spin } from 'antd'
import ImageCropContainer from '../../ImageCrop'
import { general, config } from '../../../constants'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import actions from '../../../store/actions'
import { PopupMedia } from '../../../views/Library/internal'
import _ from 'lodash'
import AiImages from '../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Image/components/AiImages'
import {
  uploadImage,
  uploadMedia,
  uploadAi,
  uploadCanve
} from '../../Core/ImageUpload/images'
import './imageSelect.scss'
import ReactSVG from 'react-svg'
import EditIcon from '../../../assets/edit.svg'

function InsertMedia({ onCoverChange, coverInput, label }) {
  const [file, setFile] = useState(null)
  const [image, setImage] = useState(null)
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [isMediaLibraryModalOpen, setIsMediaLibraryModalOpen] = useState(false)
  const [imageName, setImageName] = useState("")
  const [imageSize, setImageSize] = useState("")
  const [isAiModal, setAiModal] = useState(false)
  const [cover, setCover] = useState({
    id: null,
    link: ''
  })
  const [isPreviewModal, setPreviewModal] = useState(false)
  const [imageSelected, setImageSelected] = useState(false)
  const [loading, setLoading] = useState(false)

  const inputFileRef = useRef(null)

  const dispatch = useDispatch()
  useEffect(() => {
    setImageName(coverInput && coverInput.filename)
    setImageSize(coverInput && coverInput.file_size)
    setCover({ id: coverInput && coverInput.id, link: coverInput && coverInput.link })
    setImageSelected(!!coverInput)
  }, [coverInput])
  const onDrop = (dropped, handleAfterUpload, handlebeforeUpload) => {
    const file = dropped[0]
    if (!file) {
      return
    }

    const image = URL.createObjectURL(file)
    setImage(image)
    setFile(file)
    setImageName(file.name)
    setImageSize(file.size)

    handleAfterUpload = () => {
      setLoading(false)
      setImageSelected(true)
    }

    handlebeforeUpload = () => {
      setLoading(true)
    }
  }

  const { t } = useTranslation()

  const handleUploadImage = data => {
    setIsImageLoading(true)
    dispatch(actions.library.upload(data)).then(({ id, link }) => {
      setCover({ id, link })
      onCoverChange({ id, link })
      setImage(null)
      setFile(null)
      setIsImageLoading(false)
      setImageSelected(true)
    })
  }

  const useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Presentation'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  /**
   * Fetches an image from a URL and sends it to a handleUploadImage function.
   * @param {string} url - The URL of the image to be fetched.
   * @param {string} title - The title of the image.
   */
  const handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title.split(' ').join('_'),
      size: blob.size,
      type: blob.type
    }
    handleUploadImage(body)
  }

  const handleClosePopup = type => {
    if (type === general.SAVE) {
      setImage(null)
    } else {
      setImage(null)
      setFile(null)
    }
  }

  const handleSelectedImage = image => {
    const newCover = {
      id: image.id,
      link: image.link
    }
    setCover(newCover)
    onCoverChange(newCover)
    setIsMediaLibraryModalOpen(false)
    setAiModal(false)
    setImageSelected(true)
    setImageName(image.filename)
    setImageSize(image.file_size)
  }

  const handleFileInputClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      onDrop([file])
    }
  }

  const onImage = item => {
    handleImageUrl(item.link, 'Presentation ai')
    setAiModal(false)
  }
  const handleImageClick = () => {
    setPreviewModal(true)
  }
  const menu = (
    <Menu className="secondary-image-upload">
      <Menu.Item key="1" onClick={e => handleFileInputClick(e.domEvent)}>
        <ReactSVG src={uploadImage} />
        {t('buttons:upload_image')}
      </Menu.Item>
      <Menu.Item key="2" onClick={e => setIsMediaLibraryModalOpen(e.domEvent)}>
        <ReactSVG src={uploadMedia} />
        {t('buttons:select_media_library')}
      </Menu.Item>
      <Menu.Item key="3" onClick={e => useCanva(e.domEvent)}>
        <ReactSVG src={uploadCanve} />
        {t('v4:canva')}
      </Menu.Item>
      <Menu.Item key="4" onClick={e => setAiModal(true)}>
        <ReactSVG src={uploadAi} />
        {t('v3:ai_creator')}
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {isAiModal && (
        <Modal
          visible={isAiModal}
          footer={false}
          destroyOnClose
          centered
          onCancel={e => {
            e.preventDefault()
            setAiModal(false)
          }}
          width={'80%'}
        >
          <AiImages selectedImage={cover} onImage={onImage} />
        </Modal>
      )}

      <Form.Item
        style={{ marginBottom: 0 }}
        label={label || t('labels:picture')}
        colon={false}
        required={false}
      >
        {imageSelected ? (
          <Spin spinning={loading} tip="Uploading Image">
            <div className="shl-image-upload">
              <div className="shl-image-upload-inner">
                <div
                  className="shl-image-image-wrap"
                  onClick={handleImageClick}
                >
                  <div className="shl-image-data">
                    <img src={cover && cover.link} alt="media" />
                  </div>
                  <div className="shl-image-info">
                    <div>
                      <label>Image name:</label>
                      <span>{imageName || 'N/A'}</span>
                    </div>
                    <div>
                      <label>Image size:</label>
                      <span>{imageSize ? imageSize + ' KB' : 'N/A'}</span>
                    </div>
                  </div>
                </div>

                <div className="shl-image-choose">
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <span className="image-edit" size="large">
                      <img src={EditIcon} alt="" />
                    </span>
                  </Dropdown>
                  <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/jpeg, image/png"
                  />
                </div>
              </div>
            </div>
          </Spin>
        ) : (
          <Dropzone
            accept={'image/*'}
            onDrop={onDrop}
            style={{}}
            disabled={isImageLoading}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  className={className(
                    'upload-dropzone',
                    'upload-dropzone_event',
                    {
                      'upload-dropzone_active': isDragActive,
                      'upload-dropzone_invalid': !cover.id || !cover.link
                    }
                  )}
                >
                  <div className="wrap_upload_buttons">
                    {isImageLoading ? (
                      <Icon type="loading" style={{ fontSize: '3rem' }} />
                    ) : (
                      <>
                        <Button
                          className="rounded"
                          size="large"
                          style={{ margin: '5px auto' }}
                        >
                          <ReactSVG src={uploadImage} />
                          {t('buttons:upload_image')}
                        </Button>
                        <Button
                          style={{ margin: '5px auto' }}
                          className="rounded"
                          size="large"
                          onClick={e => {
                            e.stopPropagation()
                            setIsMediaLibraryModalOpen(true)
                          }}
                        >
                          <ReactSVG src={uploadMedia} />
                          {t('buttons:select_media_library')}
                        </Button>
                        <Button
                          style={{ margin: '5px auto' }}
                          className="rounded"
                          size="large"
                          onClick={e => {
                            e.stopPropagation()
                            useCanva()
                          }}
                        >
                          <ReactSVG src={uploadCanve} />
                          {t('v4:canva')}
                        </Button>
                        <Button
                          style={{ margin: '5px auto' }}
                          className="rounded"
                          size="large"
                          onClick={e => {
                            e.preventDefault()
                            setAiModal(true)
                          }}
                        >
                          <ReactSVG src={uploadAi} />
                          {t('v3:ai_creator')}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )
            }}
          </Dropzone>
        )}
        {isPreviewModal && (
          <Modal
            visible={isPreviewModal}
            footer={false}
            onCancel={() => setPreviewModal(false)}
            className="image-popup-show"
            centered
          >
            <img src={cover.link} alt="Preview" style={{ width: '100%' }} />
          </Modal>
        )}

        {image && (
          <ImageCropContainer
            image={image}
            handleUploadImage={handleUploadImage}
            handleClosePopup={handleClosePopup}
            file={file}
            t={t}
          />
        )}

        {isMediaLibraryModalOpen && (
          <OrganizationImageSelect
            visible={isMediaLibraryModalOpen}
            onClose={() => setIsMediaLibraryModalOpen(false)}
            onSelectImage={handleSelectedImage}
          />
        )}
      </Form.Item>
    </>
  )
}

const OrganizationImageSelect = ({ onClose, visible, onSelectImage }) => {
  const handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const item = _.head(tempData.filter(value => value.isSelected))
    onSelectImage(item)
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={'80%'}
      centered
    >
      <div className="wrap_modal_tabs">
        <PopupMedia
          isPageBuilder
          handleInsertData={handleInsertData}
          types="images"
        />
      </div>
    </Modal>
  )
}

export default InsertMedia
