import React, { Component, Fragment } from 'react'
import { Icon, Switch, Tooltip, message } from 'antd'
import { builder } from '../../../constants'
import nanoid from 'nanoid'
import './Toolbar.scss'
import className from 'classnames'
import { withTranslation } from 'react-i18next'

import customImages from './Images/index'

const { toolbar, types } = builder

class Toolbar extends Component {
  ref = null

  setRef = n => {
    this.ref = n
  }

  addElement = type => {
    const { handleData } = this.props
    const width = type === types.TEXT ? 450 : 400

    const data = {
      uid: nanoid(8),
      type: type,
      params: {
        content: '',
        width,
        height: 200,
        x: 0,
        y: 0
      }
    }

    if (handleData) {
      handleData(data, true)
    }
  }

  removeBackgroundElement = () => {
    const { handleData, pageStructure } = this.props
    const data = pageStructure.filter(
      node => node.type !== types.BACKGROUND_IMAGE
    )
    handleData(data)
  }

  handleToolClick = ({ type }) => {
    const { mode, pageStructure } = this.props

    const pageHasScenario = pageStructure.some(
      node => node.type === types.SCENARIO
    )

    if (pageHasScenario) {
      message.error(
        'You cannot add other element in a scenario page. Please try other pages or remove the scenario from the page first.',
        10
      )
      return
    }

    if (mode === types.ANNOTATION) {
      const {
        handleShowAnnotationImageModal,
        handleShowAnnotationTextModal,
        handleShowAnnotationVideoModal,
        handleShowAnnotationAudioModal
      } = this.props
      if (type === types.TEXT) {
        handleShowAnnotationTextModal()
      } else if (type === types.IMAGE) {
        handleShowAnnotationImageModal()
      } else if (type === types.VIDEO) {
        handleShowAnnotationVideoModal()
      } else if (type === types.AUDIO) {
        handleShowAnnotationAudioModal()
      }
    } else {
      const {
        handleShowAnnotationModal,
        handleShowAudioModal,
        handleShowImageModal,
        handleShowVideoModal,
        handleShowTextModal,
        handleShowQuizModal,
        handleShowGrid
      } = this.props
      if (type === types.AUDIO) {
        handleShowAudioModal()
      } else if (type === types.IMAGE) {
        const isAR = false
        handleShowImageModal(isAR)
      } else if (type === types.AR_IMAGE) {
        const isAR = true
        handleShowImageModal(isAR)
      } else if (type === types.VIDEO) {
        handleShowVideoModal()
      } else if (type === types.TEXT) {
        handleShowTextModal()
      } else if (type === types.ANNOTATION) {
        handleShowAnnotationModal()
      } else if (type === types.QUIZ) {
        handleShowQuizModal()
      } else if (type === types.GRID) {
        handleShowGrid()
      } else {
        this.addElement(type)
      }
    }
  }

  onChangeAutoBgChange = () => {
    const {
      currentPage,
      pages,
      pages_landscape,
      handleLessonInput,
      pageStructure,
      onAutoBackgroundChange,
      lessonBckgrndImg,
      lessonBckgrndImgId
    } = this.props
    const pagesTemp = [...pages]
    const pagesLandscapeTemp = [...pages_landscape]
    const pageStructureTemp = [...pageStructure]
    const indexVal = pageStructure.findIndex(
      item => item.type === 'BACKGROUND_IMAGE_AUTO'
    )
    if (lessonBckgrndImg !== null && indexVal === -1) {
      const dataLND = {
        uid: nanoid(8),
        type: 'BACKGROUND_IMAGE_AUTO',
        auto: true,
        params: { content: lessonBckgrndImg, content_id: lessonBckgrndImgId }
      }
      pagesTemp[currentPage - 1].data.push(dataLND)
      pagesLandscapeTemp[currentPage - 1].data.push(dataLND)
      handleLessonInput('pages', pagesTemp)
      handleLessonInput('pages_landscape', pagesLandscapeTemp)
      onAutoBackgroundChange()
    }

    if (
      indexVal !== -1 &&
      pageStructure.length > 0 &&
      pageStructure[indexVal].hasOwnProperty('auto')
    ) {
      if (pageStructure[indexVal].auto === false) {
        pageStructureTemp[indexVal].auto = true
      } else {
        pageStructureTemp[indexVal].auto = false
      }
      pagesTemp[currentPage - 1].data = pageStructureTemp
      pagesLandscapeTemp[currentPage - 1].data = pageStructureTemp
      handleLessonInput('pages', pagesTemp)
      handleLessonInput('pages_landscape', pagesLandscapeTemp)
      onAutoBackgroundChange()
    }
  }
  getToolbarImage = type => {
    switch (type) {
      case types.ANNOTATION:
        return <img src={customImages.Annotation} alt="Annotation" />
      case types.AR_IMAGE:
        return <img src={customImages.ArImage} alt="AR" />
      case types.IMAGE:
        return <img src={customImages.Image} alt="Image" />
      case types.AUDIO:
        return <img src={customImages.Audio} alt="Audio" />
      case types.BACKGROUND_IMAGE:
        return <img src={customImages.Background} alt="Background" />
      case types.GRID:
        return <img src={customImages.Grid} alt="Grid" />
      case types.QUIZ:
        return <img src={customImages.Quiz} alt="Quiz" />
      case types.TEXT:
        return <img src={customImages.Text} alt="Text" />
      case types.VIDEO:
        return <img src={customImages.Video} alt="Video" />
      default:
        return null
    }
  }

  render() {
    const {
      mode,
      pageStructure,
      handleChangeBackground,
      isLandScape,
      enableGrid,
      t
    } = this.props

    const indexVal = pageStructure.findIndex(
      item => item.type === 'BACKGROUND_IMAGE_AUTO' && item.auto === true
    )
    const items = toolbar.filter(item => item.mode.includes(mode))

    const classNames = className('toolbar__list', {
      toolbar__list__annotation: mode === types.ANNOTATION
    })

    return (
      <div className={mode === types.ANNOTATION ? '' : 'toolbar'}>
        <div className="toolbar__inner">
          <ul className={classNames}>
            {items.map((item, index) => {
              if (item.type === types.BACKGROUND_IMAGE) {
                return pageStructure.find(
                  node => node.type === types.BACKGROUND_IMAGE
                ) ? (
                  <>
                    <Tooltip title={item.name} key={index}>
                      <div
                        className="toolbar__item"
                        onClick={() => handleChangeBackground()}
                      >
                        <div className="toolbar__item-icon">
                          {/* <Icon type={item.icon} /> */}
                          {this.getToolbarImage(item.type)}
                        </div>
                        <div className="toolbar__item-text">{t('change')}</div>
                      </div>
                      <div
                        key={index}
                        className="toolbar__item"
                        onClick={() => this.removeBackgroundElement()}
                      >
                        <div className="toolbar__item-icon">
                          <Icon type={'delete'} />
                        </div>
                        <div className="toolbar__item-text">{t('remove')}</div>
                      </div>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title={item.name} key={index}>
                    <div
                      className="toolbar__item"
                      onClick={() => handleChangeBackground()}
                    >
                      <div className="toolbar__item-icon">
                        {this.getToolbarImage(item.type)}
                      </div>
                      <div className="toolbar__item-text">{item.name}</div>
                    </div>
                  </Tooltip>
                )
              } else {
                return (
                  <Fragment key={index}>
                    {!isLandScape && (
                      <Tooltip title={item.name}>
                        <div
                          className={`toolbar__item ${
                            enableGrid && item.type === 'GRID'
                              ? 'active_grid'
                              : ''
                          }`}
                          onClick={() => this.handleToolClick(item)}
                        >
                          <div className="toolbar__item-icon">
                            {this.getToolbarImage(item.type) || (
                              <Icon type={item.icon} />
                            )}
                          </div>
                          <div className="toolbar__item-text">{item.name}</div>
                        </div>
                      </Tooltip>
                    )}
                  </Fragment>
                )
              }
            })}
          </ul>
          {!pageStructure.find(
            node => node.type === types.BACKGROUND_IMAGE
          ) && (
            <div className="toolbar__autobg">
              <p>{t('auto_background')}</p>
              <Switch
                checked={
                  !pageStructure.find(
                    node => node.type === types.BACKGROUND_IMAGE
                  ) &&
                  pageStructure.length > 0 &&
                  indexVal !== -1 &&
                  pageStructure[indexVal].hasOwnProperty('auto') &&
                  pageStructure[indexVal].auto === true
                    ? true
                    : false
                }
                onChange={this.onChangeAutoBgChange}
              />
            </div>
          )}
        </div>

        {/* <input
          uid={nanoid(8)}
          type="file"
          accept="image/*"
          ref={this.setRef}
          style={{ display: 'none' }}
          onChange={this.changeBackgroundElement}
        /> */}
      </div>
    )
  }
}

export default withTranslation('v2')(Toolbar)
