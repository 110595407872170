import React, { Fragment } from 'react'
import ReactSVG from 'react-svg'
import { Typography, Menu, Tooltip } from 'antd'
import './index.css'
import { live, courses, coursesAI } from '../../../Courses/Create/nestedComponents/CourseCreateMain/images'
import NewHeader from '../../../../components/NewHeader'
const { Title } = Typography
const creationTypes = {
  live: 'live',
  custom: 'custom',
  machine: 'machine'
}
const FormHeader = ({ t, isEnd, isRecording, podcastId, isMarketPlace, showAiGenerationOption, handleMenuClick, discussionRoomType }) => {
  const creationType = discussionRoomType
  return (
    <Fragment>
      <div className="create-course">
        <div className="create-course__content">
          <Title level={4}>{isMarketPlace ? t("v4:marketplaceHeader") : isEnd ? t("v4:publishHeader") : podcastId ? t("v4:editHeader") : t("v4:createHeader")}</Title>
        </div>
        <div className="shl-podcast-head">
          <Tooltip
            placement="topLeft"
            title={!isEnd && `Recording: ${isRecording ? 'On' : 'Off'}`}
            arrow
          >
            <div className="podcast-record">
              <span style={{ color: isRecording && 'red' }}>REC</span>
              <i
                class={`podcast-record-icon ${isEnd &&
                  'podcast-record-disabled'} ${isRecording &&
                  'podcast-record-enabled'}`}
              ></i>
            </div>
          </Tooltip>
        </div>
        <NewHeader />

      </div>
      <div className="create-course-menu">
        {showAiGenerationOption && (
          <Menu
            defaultSelectedKeys={[creationType]}
            onClick={handleMenuClick}
            mode="horizontal"
          >
            <Menu.Item key={creationTypes.live} disabled={podcastId && creationTypes.live !== discussionRoomType}>
              <div className="create-course-box">
                <ReactSVG src={live} />
                {t('v4:create_podcast_live')}
              </div>
            </Menu.Item>
            <Menu.Item key={creationTypes.custom} disabled={podcastId && creationTypes.custom !== discussionRoomType}>
              <div className="create-course-box">
                <ReactSVG src={courses} />
                {t('v4:create_podcast_custom')}
              </div>
            </Menu.Item>
            <Menu.Item key={creationTypes.machine} disabled>
              <div className="create-course-box">
                <ReactSVG src={coursesAI} />
                {t('v4:ai_Assisted_podcast_reation')}
              </div>
            </Menu.Item>
          </Menu>
        )}
      </div>
    </Fragment>
  )
}

export { FormHeader }
