import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Spin, Tooltip, Popconfirm } from 'antd'
import calsvg from './calender.svg'
import signalsvg from './signal.svg'
import handsvg from './hand.svg'
import userImg from './../../assets/user.svg'
import ListenerModal from '../ListenerModal/ListenerModal'
// import { UsersModal } from '../../../DiscussionCreateMain/components/UsersModal'
import { MicrophoneActivity } from 'amazon-chime-sdk-component-library-react'
import { CloseCircleFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useFetchReviews } from '../../../../../components/Reviews/Reviews'

import './PreviewStatus.scss'

const PreviewStatus = props => {
  const {
    isConfCreator,
    interestStatus,
    joinPodcast,
    joinStatus,
    joinLoading,
    isLive,
    isPast,
    userId,
    event,
    hostsData,
    allUsers,
    setIsEnd,
    setIsLeaving,
    currentSpeakerIds,
    changeMuteStatus,
    setUserToKick,
    podcastHost,
    totalHostList,
    isDisabled,
    setIsDisabled,
    loadingData,
    changeLoading,
    getData
  } = props
  useFetchReviews(event.id, 'event')
  const [isModalVisible, setIsModalVisible] = useState({
    kick: false,
    requuest: false,
    user: {},
    role: ''
  })
  const [isHandRaised, setIsHandRaise] = useState(false)

  const getPassedTime = time => {
    let duration = moment.duration(moment().diff(moment(time)))
    let hours = duration.hours()
    let minutes = duration.minutes()
    let seconds = duration.seconds()
    let timeAgo = ''
    if (hours > 0) {
      timeAgo += hours < 10 ? '0' + hours + ':' : hours + ':'
    } else {
      timeAgo += '00:'
    }
    if (minutes > 0) {
      timeAgo += minutes < 10 ? '0' + minutes + ':' : minutes + ':'
    } else {
      timeAgo += '00:'
    }
    if (seconds > 0) {
      timeAgo += seconds < 10 ? '0' + seconds + ' ago' : seconds + ' ago'
    } else {
      timeAgo += '00 ago'
    }
    return timeAgo
  }
  const [timePassed, setTimePassed] = useState(
    getPassedTime(event.start_date_time)
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setTimePassed(getPassedTime(event.start_date_time))
    }, 1000)
    return () => clearInterval(interval)
  }, [event.start_date_time])

  // to lighten up speaking user
  useEffect(() => {
    // Remove the current-speaker class from all avatars
    const avatars = document.querySelectorAll('.avatar-circle')
    avatars.forEach(avatar => {
      avatar.classList.remove('current-speaker')
    })

    // Add the current-speaker class to avatars of current speakers
    currentSpeakerIds.forEach(speakerId => {
      const avatar = document.querySelector(
        `.avatar-circle[data-id="${speakerId}"]`
      )
      if (avatar) {
        avatar.classList.add('current-speaker')
      }
    })
  }, [currentSpeakerIds])

  const handleCancel = () => {
    setIsModalVisible({ ...isModalVisible, kick: false, request: false })
  }

  const handleKick = user => {
    setUserToKick(user)
    setIsModalVisible({ ...isModalVisible, request: false, kick: false })
  }

  const getAvatar = id => {
    let avatar = userImg
    if (id) {
      totalHostList.forEach(el => {
        if (parseInt(el.id) === parseInt(id)) {
          avatar = el.avatar ? el.avatar.link : ''
          if (loadingData.status) {
            changeLoading(false)
          }
        }
      })
    }
    return avatar || userImg
  }
  const { t } = useTranslation()
  const [showFullContent, setShowFullContent] = useState(false)

  const toggleContent = () => {
    setShowFullContent(!showFullContent)
  }
  return (
    <div className="event__status__dc-room" style={{ position: 'relative' }}>
      <div className="event__status__dc-room-container">
        <div className="live__status__dc-room">
          {/*before event starting */}
          {event ? (
            <>
              <div
                className={`${
                  isLive && joinStatus ? 'live' : ''
                }__metadata__dc-room`}
              >
                <div className="__preview__dc-room">
                  <div className="__title__dc-room">{event.title}</div>
                  <div className="__description__dc-room">
                    <p className={showFullContent ? 'expanded' : 'collapsed'}>
                      {event.description}
                    </p>
                    {showFullContent ? (
                      <button className="toggle-btn" onClick={toggleContent}>
                        {t('v4:see_less')}
                      </button>
                    ) : (
                      <button className="toggle-btn" onClick={toggleContent}>
                        {t('v4:see_more')}
                      </button>
                    )}
                  </div>

                  {isLive && !joinStatus && (
                    <div className="__started__dc-room">
                      {t('v3:started')} :{' '}
                      {moment(event.start_date_time).format('LTS')}
                    </div>
                  )}
                  {isLive && joinStatus && (
                    <div className="__started__dc-room">
                      {t('v3:started')} : {timePassed}{' '}
                      <img
                        src={signalsvg}
                        alt="signalsvg"
                        className="signal-icon"
                      />
                    </div>
                  )}
                </div>
                <div className="__buttons__dc-room">
                  {isLive && joinStatus ? (
                    <>
                      {/* {!isHandRaised && (
                        <Button
                          className="__request__button__dc-room"
                          onClick={() => {
                            setIsHandRaise(true)
                          }}
                        >
                          RAISE{' '}
                          <img
                            src={handsvg}
                            alt="handsvg"
                            className="hand-icon"
                          />
                        </Button>
                      )}
                      {true && (
                        <Button
                          className="__request__button__dc-room"
                          onClick={() => {
                            requestToSpeak(userId)
                          }}
                        >
                          REQUEST{' '}
                          <img
                            src={handsvg}
                            alt="handsvg"
                            className="hand-icon"
                          />
                        </Button>
                      )} */}
                      {!isDisabled && (
                        <Popconfirm
                          title="Are you sure to leave podcast?"
                          description="Are you sure to leave podcast?"
                          onConfirm={() => {
                            !isDisabled && setIsLeaving(true)
                            getData()
                          }}
                          okText="Leave"
                          cancelText="Cancel"
                        >
                          <Button className="__leave__button__dc-room">
                            {t('v4:leave')}
                          </Button>
                        </Popconfirm>
                      )}

                      {isConfCreator && (
                        <Popconfirm
                          title="Are you sure to end this podcast?"
                          description="Are you sure to end this podcast?"
                          onConfirm={() => {
                            !isDisabled && setIsEnd(true)
                            !isDisabled && setIsDisabled(true)
                          }}
                          okText="End"
                          cancelText="Cancel"
                        >
                          <Button
                            className="__end__button__dc-room"
                            loading={isDisabled}
                          >
                            {t('v3:end')}
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  ) : !joinStatus && !isPast ? (
                    <>
                      {isLive ? (
                        <Button
                          className={`__interested__button__dc-room ${
                            interestStatus ? 'active' : ''
                          }`}
                          onClick={joinPodcast}
                        >
                          {t('v3:join_now')}
                        </Button>
                      ) : (
                        <></>
                        // <Button
                        //   className={`__interested__button__dc-room ${
                        //     interestStatus ? 'active' : ''
                        //   }`}
                        //   onClick={onInterestPodcast}
                        // >
                        //   {interestStatus && (
                        //     <CheckCircleFilled
                        //       className="__interested__button_icon_dc-room"
                        //       style={{ fontSize: '2rem' }}
                        //     />
                        //   )}
                        //   Interested
                        // </Button>
                      )}
                      {/* <Button
                        className="__invite__button__dc-room"
                        onClick={() => {
                          handleInviteModalVisible()
                        }}
                      >
                        Invite Friend
                      </Button>
                      <UsersModal
                        visible={inviteModalVisible}
                        onCancel={handleInviteModalClose}
                        searchText={searchText}
                        onSearchChange={handleSearchChange}
                        users={filteredUsers}
                        selectedUsers={usersToInvite}
                        onCheckboxChange={handleUserCheckboxChange}
                        onSelectAllChange={handleSelectAll}
                        isSelectAllChecked={isInviteAllChecked}
                        isInviteAble={isInviteAble}
                        isInvite={isInvite}
                        setIsInvite={setIsInvite}
                      /> */}
                    </>
                  ) : isPast ? (
                    <>
                      <Button className="__finished__button__dc-room">
                        <CloseCircleFilled
                          className="__finished__button_icon_dc-room"
                          style={{ fontSize: '2rem' }}
                        />
                        {t('v3:podcastEnd')}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {!isLive && !joinStatus && (
                <div className="__details__dc-room">
                  <div className="__date__dc-room">
                    <img src={calsvg} alt="calsvg" className="calsvg-icon" />
                    &nbsp;
                    {moment(event.start_date_time).format('L')} {' : '}
                    {moment(event.start_date_time).format('LT')}
                  </div>

                  {/* <div className="__users__count__dc-room">
                    <UserOutlined className="userIcon" /> Interested{' '}
                    {allUsers ? allUsers.length : ''}{' '}
                    <MessageOutlined className="messageIcon" /> 0
                  </div> */}
                </div>
              )}

              <div className="__all_users__dc-room">
                <ListenerModal
                  isRequest={isModalVisible.request}
                  user={isModalVisible.user}
                  isModalVisible={isModalVisible.request || isModalVisible.kick}
                  handleCancel={handleCancel}
                  handleKick={handleKick}
                  // requestToSpeak={requestToSpeak}
                  userImg={userImg}
                  userRole={isModalVisible.userRole}
                />
                {isLive && joinStatus && allUsers ? (
                  <>
                    {joinLoading ? (
                      <div className="__loading__dc-room">
                        <Spin />
                      </div>
                    ) : (
                      <>
                        <UserListTitle title="SPEAKERS" data={hostsData} />
                        <div className="__hosts__dc-room">
                          {hostsData.map((user, index) => {
                            return (
                              <Tooltip key={index + 's'} title={user.user_name}>
                                <div className={'user-card ' + user.user_id}>
                                  <div
                                    className={`avatar-circle ${
                                      currentSpeakerIds.includes(user.chime_id)
                                        ? 'current-speaker'
                                        : 'null'
                                    }`}
                                    data-id={user.user_id}
                                  >
                                    <img
                                      src={getAvatar(user.user_id)}
                                      alt={user.username}
                                    />
                                  </div>
                                  {/* Hand raise button */}
                                  {isHandRaised && (
                                    <button className="handraise-button">
                                      <img src={handsvg} alt="handraise" />
                                    </button>
                                  )}
                                  {/* Microphone button */}
                                  <button
                                    className="microphone-button"
                                    onMouseOver={e => {
                                      e.preventDefault()
                                      setIsHandRaise(false)
                                    }}
                                    onClick={() => {
                                      // muting yourself
                                      console.log(
                                        user.user_id,
                                        JSON.stringify(userId)
                                      )
                                      if (
                                        user.user_id === JSON.stringify(userId)
                                      ) {
                                        changeMuteStatus()
                                      }
                                      // // muting other users
                                      // else if (isConfCreator) {
                                      //   setMutedUserId(user.user_id)
                                      // }
                                    }}
                                  >
                                    <MicrophoneActivity
                                      attendeeId={user.chime_id}
                                    />
                                  </button>
                                  {/* <h3>{user.role}</h3> */}
                                </div>
                              </Tooltip>
                            )
                          })}
                          {loadingData.id && loadingData.status && (
                            <Tooltip key="loading" title={loadingData.username}>
                              <div className={'user-card ' + loadingData.id}>
                                <div
                                  className={`avatar-circle progress_border`}
                                  data-id={loadingData.id}
                                >
                                  <img
                                    className="avatar_image"
                                    src={loadingData.avatar || userImg}
                                    alt={loadingData.username}
                                  />
                                </div>
                                <span className="connecting_text">
                                  {t('v3:connecting')}
                                </span>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <UserListTitle
                      title={t('v4:GUESTS SPEAKER').toUpperCase()}
                    />
                    <div className="__hosts__dc-room">
                      {podcastHost.map((host, index) => {
                        return (
                          <Tooltip
                            key={index + 'g'}
                            title={host.first_name + ' ' + host.last_name}
                          >
                            <div className="avatar-circle">
                              <img
                                src={host.avatar ? host.avatar.link : userImg}
                                alt={host.user_name}
                              />
                            </div>
                          </Tooltip>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

const UserListTitle = ({ title }) => {
  return (
    <div className="__title__dc-room">
      <h1>{title}</h1>
    </div>
  )
}

export default PreviewStatus
