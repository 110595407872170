import React from 'react'
import QuizView from '../QuizView'
import AnnotationView from '../AnnotationView'

import { Preview } from '../../../../../components/Builder'
import { builder } from '../../../../../constants'
import ScenarioView from '../ScenarioView'

const { types } = builder

// const getWidth = (width, x) => {
//   if (width > 1024) {
//     return 1024
//   }
//   if (width >= 640 && x >= 100) {
//     return 1024
//   }
//   if (width >= 700 && width <= 710) {
//     return 1024
//   }
//   return width
// }

export default props => {
  const {
    node,
    node: {
      auto,
      type,
      params,
      params: { width, height, x, y }
    },
    ids,
    onDragStart,
    onDragStop,
    toggleQuizRestriction
  } = props

  const style = {
    position: 'absolute',
    width: width,
    height,
    top: y,
    left: x,
    zIndex: 5
  }

  console.log('Tempalte logs', props.node)

  if (type === types.BACKGROUND_IMAGE) {
    return (
      <img
        style={{ width: '100%', maxHeight: '586px' }}
        src={params.content}
        alt=""
      />
    )
  }

  if (type === types.BACKGROUND_IMAGE_AUTO && auto === true) {
    return (
      <img
        style={{ width: '100%', maxHeight: '586px' }}
        src={params.content}
        alt=""
      />
    )
  }

  return (
    <div style={style}>
      {type === types.TEXT && <Preview node={node} isView />}
      {type === types.IMAGE && <Preview node={node} />}
      {type === types.ARIMAGE && <Preview node={node} />}
      {type === types.VIDEO && <Preview node={node} />}
      {type === types.AUDIO && (
        <Preview
          elementHeight={32}
          elementWidth={width < 199 ? 45 : width}
          node={node}
        />
      )}
      {type === types.VIDEO_STREAM && <Preview node={node} />}
      {type === types.ANNOTATION && (
        <AnnotationView content={params.content} ids={ids} />
      )}
      {type === types.QUIZ && (
        <QuizView
          onDragStart={onDragStart}
          onDragStop={onDragStop}
          content={params}
          ids={ids}
          toggleQuizRestriction={toggleQuizRestriction}
        />
      )}
      {type === types.SCENARIO && <ScenarioView content={params} />}
    </div>
  )
}
