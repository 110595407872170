/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-08-21 15:46:12
 * @LastEditors: Please set LastEditors
 */
import http, {
  aiCourseCreationInstance,
  httpShareBotInstance,
  questionAnswerAiInstance
} from './http'

/**
 * Get list of courses.
 * @param {{mode: string}} params
 */
function getCoursesList(params) {
  return http({
    method: 'GET',
    url: '/courses',
    params
  })
}

/**
 * Get list of lessons in the course.
 * @param {number} id
 * @param {number} limit
 * @param {number} offset
 */
function getLessonsInCourse(id, limit, offset) {
  return http({
    method: 'GET',
    url: `/courses/${id}/lessons`,
    params: {
      limit,
      offset
    }
  })
}

/**
 * Get single course.
 * @param {number} id
 */
function getCourseById(id) {
  return http({
    method: 'GET',
    url: '/courses/' + id
  })
}

/**
 * Create new course.
 * @param {object} data
 */
function courseCreate(data) {
  return http({
    method: 'POST',
    url: '/courses',
    data
  })
}

/**
 * Update course.
 * @param {object} data
 * @param {number} id
 */
function courseUpdate(data, id) {
  const { isSendMail } = data
  const query = `?isSendMail=${isSendMail || false}`

  return http({
    method: 'PUT',
    url: `/courses/${id}${query}`,
    data
  })
}

/**
 * Delete course.
 * @param {number} id
 */
function courseDelete(id) {
  return http({
    method: 'DELETE',
    url: '/courses/' + id
  })
}

/**
 * Delete course.
 * @param {number} courseId
 * @param {number} lessonId
 */
function lessonFromCourseDelete(courseId, lessonId) {
  return http({
    method: 'DELETE',
    url: '/courses/' + courseId + '/lessons/' + lessonId,
    timeout: 180000
  })
}

/**
 * Enroll course.
 * @param {number} courseId
 * @param {obj} progress
 */
const courseEnroll = (courseId, progress) => {
  return http({
    method: 'POST',
    url: `/courses/${courseId}/enroll`,
    data: { progress }
  })
}

/**
 * Unenroll course.
 * @param {number} courseId
 */
const courseUnenroll = courseId => {
  return http({
    method: 'POST',
    url: `/courses/${courseId}/unenroll`
  })
}

/**
 * View course.
 * @param {number} courseId
 */
const viewCourse = courseId => {
  return http({
    method: 'POST',
    url: `/courses/${courseId}/view`
  })
}

const updateLessonsOrderByCourseId = (id, array) => {
  return http({
    method: 'POST',
    url: `/courses/${id}/lessons/order`,
    data: {
      ids: array
    }
  })
}

const bindLessonsToCourse = (courseId, lessonsId, data) => {
  return http({
    method: 'POST',
    url: `/courses/${courseId}/lessons/${lessonsId}`,
    data
  })
}

function checkInCourse(id, data) {
  return http({
    method: 'POST',
    url: `/courses/${id}/checkin`,
    data
  })
}

function checkOutCourse(id, data) {
  return http({
    method: 'PUT',
    url: `/courses/${id}/checkout`,
    data
  })
}

const cloneCourse = (id, data) => {
  return http({
    method: 'POST',
    url: `/courses/${id}/clone`,
    data
  })
}

const changePublishedMarketplace = (params, data) => {
  return http({
    method: 'PUT',
    url: `/${params.type}/marketplace/${params.id}/`,
    data
  })
}

const fetchCategory = () => {
  return http({
    method: 'GET',
    url: `/categories`
  })
}

const askAiCourseCreation = data => {
  return aiCourseCreationInstance({
    method: 'POST',
    url: ``,
    data
  })
}

const aiContentGenerationUrl = data => {
  return httpShareBotInstance({
    method: 'POST',
    url: `/content-generation`,
    data
  })
}

const saveGeneratedContent = data => {
  return httpShareBotInstance({
    method: 'PUT',
    url: `/save-generated-content`,
    data
  })
}

const getGeneratedContent = params => {
  return httpShareBotInstance({
    method: 'GET',
    url: `/save-generated-content?orgId=${params.orgId}&courseId=${
      params.courseId ? params.courseId : ''
    }`
  })
}

const generateAllAILessons = (params, data) => {
  return http({
    method: 'POST',
    url: `/lessons/createAILessons/${params.courseId}`,
    data
  })
}

const generateContentFromInternet = data => {
  return questionAnswerAiInstance({
    method: 'POST',
    url: `/?service=internet`,
    data
  })
}

const generateStory = data => {
  return questionAnswerAiInstance({
    method: 'POST',
    url: `/?service=story-generation`,
    data
  })
}
const setSequence = data => {
  return http({
    method: 'PUT',
    url: `/courses/priorities`,
    data
  })
}

const createScenarioData = (lessonId, data) => {
  return http({
    method: 'POST',
    url: `/lessons/${lessonId}/scenario`,
    data
  })
}

const saveScenarioData = (lessonId, data) => {
  return http({
    method: 'PUT',
    url: `/lessons/scenario/${lessonId}`,
    data
  })
}

const getScenarioData = lessonId => {
  return http({
    method: 'GET',
    url: `/lessons/scenario/${lessonId}`
  })
}

const putScenarioAttempt = (lessonId, data) => {
  return http({
    method: 'PUT',
    url: `/lessons/scenario/${lessonId}/attempts`,
    data
  })
}

const generateScenario = data => {
  return httpShareBotInstance({
    method: 'POST',
    url: `/scenario-generation`,
    data
  })
}

const downloadLesson = id => {
  return http({
    method: 'GET',
    url: `/lessons/${id}/link`
  })
}

export {
  getCoursesList,
  getLessonsInCourse,
  getCourseById,
  courseCreate,
  courseUpdate,
  courseDelete,
  lessonFromCourseDelete,
  courseEnroll,
  courseUnenroll,
  viewCourse,
  updateLessonsOrderByCourseId,
  bindLessonsToCourse,
  checkInCourse,
  checkOutCourse,
  cloneCourse,
  changePublishedMarketplace,
  fetchCategory,
  askAiCourseCreation,
  aiContentGenerationUrl,
  saveGeneratedContent,
  getGeneratedContent,
  generateAllAILessons,
  generateContentFromInternet,
  generateStory,
  setSequence,
  saveScenarioData,
  getScenarioData,
  createScenarioData,
  putScenarioAttempt,
  generateScenario,
  downloadLesson
}
