import React, { Component } from 'react'
import { Progress } from 'antd';
import { Row, Col } from 'antd'
import { quotes } from '../assets/quotes'

class Quotes extends Component {
  constructor() {
    super()
    this.state = { textIdx: 0 }
  }

  componentDidMount() {
    setInterval(() => {
      let randomNumber = Math.floor(Math.random() * 5)
      this.setState({ textIdx: randomNumber })
    }, 3500)
  }

  render() {
    let myQuote = quotes[this.state.textIdx]
    return (
      <>
        "{myQuote.text}"
        <br />- {myQuote.by}
        <br />
        <br />
        <Row>
          <Col span={10} offset={8}>
            <Progress percent={parseInt(this.props.percentageLoader, 10)} />
          </Col>
        </Row>
      </>
    )
  }
}

export default Quotes
