import React, { Fragment } from 'react'
import { Button, Row, Col } from 'antd'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { CustomRangePicker, ReportHeaderSelect, ReportSelect } from '../styled'
import NewHeader from '../../../components/NewHeader'

const { Option } = ReportSelect
const dateFormat = 'YYYY-MM-DD'

function ReportHeader(props) {
  const {
    t,
    dateChange,
    workshopDateType = '5',
    download,
    // backToDashboard,
    handleDate,
    dateFrom,
    dateTo,
    isDateActive
  } = props

  const showDateFiter = workshopDateType === '2'

  return (
    <Fragment>
      {/* <Row className="nav-report-left">
          <Button style={{border: "none", padding:"0px"}} onClick={backToDashboard}>
            <Icon type="left" style={{fontSize: "2rem"}} />
          </Button>
        </Row> */}
      <Row className="nav-report">
        {!isDateActive ? (
          <div className="shl-nav-report-date">
            <Col span={2}>{t('v4:date')}</Col>

            <Col span={4}>
              <div id="report-header-time" className="relative">
                <ReportHeaderSelect
                  value={workshopDateType}
                  onChange={dateChange}
                  dropdownStyle={{ borderRadius: '10px' }}
                  getPopupContainer={() =>
                    document.getElementById('report-header-time')
                  }
                >
                  <Option value="5" className="select-header-option">
                    {t('reports:all_time')}
                  </Option>
                  <Option value="1" className="select-header-option">
                    {t('reports:today')}
                  </Option>
                  <Option value="7" className="select-header-option">
                    {t('reports:seven_days')}
                  </Option>
                  <Option value="30" className="select-header-option">
                    {t('reports:this_month')}
                  </Option>
                  <Option value="12" className="select-header-option">
                    {t('reports:one_year')}
                  </Option>
                  <Option value="2" className="select-header-option">
                    {t('v4:other')}
                  </Option>
                </ReportHeaderSelect>
              </div>
            </Col>
            {showDateFiter && (
              <Col span={4}>
                <div id="report-header-range" className="relative">
                  <CustomRangePicker
                    format={dateFormat}
                    onChange={handleDate}
                    value={
                      dateFrom && dateTo
                        ? [moment(dateFrom), moment(dateTo)]
                        : null
                    }
                    separator="-"
                  />
                </div>
              </Col>
            )}
          </div>
        ) : (
          ''
        )}
        <Col span={5} style={{ float: 'right' }}>
          <Button
            icon="download"
            className="download-header-report"
            onClick={download}
          >
            {t('reports:report_download')}
          </Button>
        </Col>
        <Col span={9}>
          <div className="shl-nav-report-head">
            <NewHeader />
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default withTranslation('report')(ReportHeader)
