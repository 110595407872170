import React, { Fragment, useEffect, useState } from 'react'
import { Modal, Form, Upload, Button, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { ImageSelect } from '../../../../../components/Form'
const UploadAudioModal = ({
  t,
  isUploading,
  audioData,
  open,
  close,
  form,
  onFinish,
  type
}) => {
  const {
    getFieldDecorator,
    validateFields,
    setFieldsValue,
    resetFields
  } = form
  const [fileList, setFileList] = useState([])
  const [image, setImage] = useState({ id: '', link: '' })
  useEffect(() => {
    if (audioData.id) {
      setFieldsValue({
        description: audioData.description,
        title: audioData.title,
        cover_id: audioData.cover && audioData.cover.id
      })
      setFileList([
        {
          uid: audioData.filename,
          name: audioData.filename,
          url: audioData.audio_link
        }
      ])
    }
    if (audioData.cover) {
      setImage({ id: audioData.cover.id, link: audioData.cover.link })
    }
  }, [audioData])
  const handleOk = () => {
    validateFields().then(values => {
      onFinish(values)
    })
  }
  const setCoverImage = data => {
    setImage(data)
    setFieldsValue({ cover_id: data.id })
  }
  const uploadProps = {
    onRemove: file => {
      setFileList(prevFileList =>
        prevFileList.filter(item => item.uid !== file.uid)
      )
    },
    beforeUpload: file => {
      setFileList(prevFileList => [...prevFileList, file])
      return false // Prevent automatic upload
    },
    fileList
  }
  return (
    <Modal
      style={{ position: 'relative', zIndex: 1 }}
      visible={open}
      title={type === 'ADD' ? 'Upload' : 'Update' + ' Audio File'}
      onCancel={close}
      afterClose={() => {
        resetFields()
        setFileList([])
        setImage({ id: '', link: '' })
      }}
      footer={[
        <Fragment>
          <Button loading={isUploading} type="primary" onClick={handleOk}>
            {type === 'ADD' ? 'Upload' : 'Update'}
          </Button>
          <Button disabled={isUploading} onClick={close}>
            {type === 'ADD' ? 'Close' : 'Cancel'}
          </Button>
        </Fragment>
      ]}
    >
      <Form layout="vertical">
        <Fragment>
          <Form.Item
            required={false}
            colon={false}
            className="no_bottom_margin"
          >
            {getFieldDecorator('cover_id', {
              initialValue: image.id,
              rules: [{ required: true, message: 'Please upload audio cover!' }]
            })(
              <ImageSelect
                label={'Audio Cover'}
                coverInput={audioData.cover}
                onCoverChange={setCoverImage}
              />
            )}
          </Form.Item>
          <Form.Item required={false} colon={false} label="Audio Title">
            {getFieldDecorator('title', {
              initialValue: audioData.title || '',
              rules: [
                { required: true, message: 'Please enter the audio title!' }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item required={false} colon={false} label="Audio Description">
            {getFieldDecorator('description', {
              initialValue: audioData.description || '',
              rules: [
                {
                  required: true,
                  message: 'Please enter the audio description!'
                }
              ]
            })(
              <Input.TextArea
                placeholder={t('v3:podcast_description')}
                rows={3}
              />
            )}
          </Form.Item>
          <Form.Item required={false} colon={false} label="Upload Audio file">
            {getFieldDecorator('files', {
              initialValue:
                fileList.length !== 0 && fileList.map(file => file.url),
              rules: [
                { required: true, message: 'Please upload an audio file!' }
              ],
              getValueFromEvent: e => (Array.isArray(e) ? e : e && e.fileList)
            })(
              <Upload {...uploadProps} listType="text">
                {fileList.length === 0 && (
                  <Button icon={<UploadOutlined />}>Select Audio File</Button>
                )}
              </Upload>
            )}
          </Form.Item>
        </Fragment>
      </Form>
    </Modal>
  )
}

export default Form.create({ name: 'podcast_form' })(UploadAudioModal)
