import React, { useEffect, useState, createRef } from 'react'
import './AddCover.scss'
import { message, Modal, Spin, Button } from 'antd'
import AiImages from '../../../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Image/components/AiImages'
import { EditImageContainer } from '../../../../../components/TextOnImage'
import ImageUploadModal from '../../../../../components/Core/ImageUpload/ImageUploadModal'
import { config } from '../../../../../constants'

const AddCover = ({
  t,
  selectedCover,
  setSelectedCover,
  coverError,
  upload,
  changeCoverError,
  changeUploadingStatus
}) => {
  const [isAiModal, setAiModal] = useState(false)
  const [coverStyle, setCoverStyle] = useState({})
  const [isUploading, setUploading] = useState(false)
  const [isCropModal, setCropModal] = useState(false)
  const [libraryModal, setLibraryMdal] = useState(false)
  const [imageFile, setImageFile] = useState({
    file: {},
    url: '',
    croppedUrl: ''
  })
  const imageRef = createRef()
  useEffect(() => {
    setAiModal(false)
  }, [])
  useEffect(() => {
    setCoverStyle(
      coverError
        ? {
            background: '#FFF2F0',
            border: '1px solid #ff4d4f',
            width: '100%',
            aspectRatio: '16 / 9'
          }
        : {
            width: '100%',
            backgroundImage: imageFile.croppedUrl
              ? `url('${imageFile.croppedUrl}')`
              : '',
            aspectRatio: '16 / 9',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
          }
    )
  }, [coverError, imageFile.croppedUrl])
  useEffect(() => {
    if (selectedCover.link) {
      setImageFile({ ...imageFile, croppedUrl: selectedCover.link })
    }
  }, [selectedCover])
  const addFile = () => {
    const element = document.getElementById('cover_image')
    if (element) {
      element.click()
    } else {
      console.error(`Element with not found.`)
    }
  }
  const uploadFile = async file => {
    let reader = new FileReader()
    reader.onload = e => {
      if (e.target && e.target.result) {
        setImageFile({ file: file, url: e.target.result, croppedUrl: '' })
        setCropModal(true)
      }
    }
    reader.readAsDataURL(file)
  }
  const changeImage = (e, id) => {
    let file = e.target.files[0]
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.error(t('v4:erorMsgImg'))
      return
    }
    let img = new Image()
    img.onload = () => {
      uploadFile(file)
    }

    img.onerror = () => {
      message.error(t('v4:failedMsgImg'))
      return
    }
    img.src = URL.createObjectURL(file)
    e.target.value = ''
  }
  const selectedAiImage = img => {
    handleImageUrl(img.link, 'ai_image_podcast')
    setAiModal(false)
  }
  const handleUploadImage = file => {
    setUploading(true)
    changeUploadingStatus(true)
    return upload(file)
      .then(res => {
        const { id, link } = res
        setSelectedCover({ id: id, link: link })
        setImageFile({ file: file, url: link, croppedUrl: link })
        setCropModal(false)
        setUploading(false)
        changeUploadingStatus(false)
        changeCoverError(false)
      })
      .catch(() => {
        setUploading(false)
        changeUploadingStatus(false)
        message.error(t('v4:failedMsgUpload'))
      })
  }
  const handleFileInsert = item => {
    const { id, link } = item
    setSelectedCover({ id: id, link: link })
    setImageFile({ file: {}, url: link, croppedUrl: link })
    setLibraryMdal(false)
    changeCoverError(false)
  }
  const useCanva = type => {
    let script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: type
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              handleImageUrl(exportUrl, designTitle)
              script.remove()
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }
  const handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title.split(' ').join('_'),
      size: blob.size,
      type: blob.type
    }
    handleUploadImage(body)
  }
  return (
    <>
      <div className="cover-title">{t('v4:cover_picture')}</div>
      <div className="add-cover">
        <div className="add-cover_content" style={coverStyle}>
          <div className="container">
            {libraryModal && (
              <ImageUploadModal
                modalOpen={libraryModal}
                selectedItem={selectedCover}
                hideModal={() => {
                  setLibraryMdal(false)
                }}
                imageRef={imageRef}
                // handleInsertData={handleInsertData}
                handleFileInsert={handleFileInsert}
              />
            )}
            {isAiModal && (
              <Modal
                visible={isAiModal}
                footer={false}
                destroyOnClose
                onCancel={e => {
                  e.preventDefault()
                  setAiModal(false)
                }}
                width={'80%'}
              >
                <AiImages
                  selectedImage={selectedCover}
                  onImage={selectedAiImage}
                />
              </Modal>
            )}
            {isCropModal && (
              <EditImageContainer
                image={imageFile.url}
                handleUploadImage={handleUploadImage}
                handleClosePopup={() => {
                  setCropModal(false)
                }}
                file={imageFile.file}
              />
            )}
            {isUploading ? (
              <Spin tip="uploading..." />
            ) : (
              <>
                <input
                  type="file"
                  id="cover_image"
                  style={{ display: 'none' }}
                  onChange={e => {
                    changeImage(e, 'cover_image')
                  }}
                />
                <>
                  <Button
                    style={{ margin: '5px auto' }}
                    className="rounded"
                    size="large"
                    icon="picture"
                    onClick={e => {
                      e.preventDefault()
                      addFile()
                    }}
                  >
                    {t('v3:upload_cover')}
                  </Button>
                  <Button
                    style={{ margin: '5px auto' }}
                    className="rounded"
                    size="large"
                    icon="picture"
                    onClick={e => {
                      e.preventDefault()
                      setLibraryMdal(true)
                    }}
                  >
                    {t('buttons:select_media_library')}
                  </Button>

                  <Button
                    style={{ margin: '5px auto' }}
                    className="rounded"
                    size="large"
                    icon="picture"
                    onClick={e => {
                      e.preventDefault()
                      useCanva('Presentation')
                    }}
                  >
                    {t('v4:canva')}
                  </Button>
                  <Button
                    style={{ margin: '5px auto' }}
                    className="rounded"
                    size="large"
                    icon="picture"
                    onClick={e => {
                      e.preventDefault()
                      setAiModal(true)
                    }}
                  >
                    {t('v3:upload_ai_creator')}
                  </Button>
                </>
                {coverError && (
                  <div className="cover_content_upload">
                    <div
                      style={{
                        color: '#ff4d4f',
                        fontSize: '12px',
                        height: '1.5rem',
                        textAlign: 'center'
                      }}
                    >
                      {t('v3:required_cover_image')}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddCover
