import React, { Component, Fragment } from 'react'
import { Button, Modal, Select, Form, InputNumber, Popover } from 'antd'
import { QuillStyled } from './styled'
import './Text.scss'
import { withTranslation } from 'react-i18next'
import { toolbar } from './Text.ctrl'
import { api } from '../../../../services'
import TextLibrary from './internal/TextLibrary/index'
import { translationKeys } from '../../../../constants'
import {
  textIconInsideRectangle,
  textIconRectangle
} from '../../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Text/images'
import QuestionAnswerAI from '../../../QuestionAnswerAI/QuestionAnswerAI'

const { Option } = Select

const styleTitle = { color: 'rgb(103, 104, 108)', fontWeight: 'normal' }

class Test extends Component {
  initInterval = null
  initSummarizeInterval = null
  initGermanInterval = null
  state = {
    lang: '',
    showValue: '',
    isInsertText: false,
    isWideView: false,
    showSearchResult: false,
    isLoading: false,
    idx: '',
    tabIndex: [],
    summariseResultOptionOne: [],
    summariseResult: [],
    resultData: [],
    hasInitialize: true,
    hasInitializeGerman: false,
    hasSummarizeInitialize: false,
    loadSummarize: false,
    errorMsg: '',
    ai_language: 'en-US'
  }

  handleChangeOption = e => {
    this.setState(
      {
        lang: e
      },
      () => {
        const { content, handleInput } = this.props
        if (content === '') {
          return
        }
        const temp = {
          text: content.replace(/<[^>]+>/g, '')
        }
        handleInput('Please wait... preparing translation')
        api.audioSynthesizer
          .textTranslate(temp)
          .then(res => {
            console.log('response translation', res)
            if (res.data.result) {
              const tempVar = {
                text: content.replace(/<[^>]+>/g, ''),
                source_language: res.data.data,
                language: e
              }
              handleInput('Please wait... translating')
              api.audioSynthesizer
                .textTranslate(tempVar)
                .then(res => {
                  const { setTextContent, handleInput } = this.props
                  setTextContent(res.data.data)
                  handleInput(res.data.data)
                })
                .catch(err => {
                  console.log('error in starting event', err)
                  handleInput('')
                })
            }
          })
          .catch(err => {
            console.log('error in starting event', err)
            handleInput('')
          })
      }
    )
  }

  onHideTextModal = () => {
    const { showTextModal } = this.props
    showTextModal({ isShowTextModal: false })
  }

  handleClickImportText = () => {
    const { showTextModal } = this.props
    showTextModal({ isShowTextModal: true })
    this.setState({
      isWideView: false
    })
  }

  handleClickQAText = () => {
    const { handleInput } = this.props
    handleInput('')
    this.setState(prevState => ({
      isWideView: !prevState.isWideView,
      showSearchResult: false,
      isLoading: false
    }))
  }

  onHideQAModal = () => {
    this.setState({
      isWideView: false
    })
  }

  handleInsertText = () => {
    this.setState(
      {
        isInsertText: true
      },
      () => {
        const { showTextModal } = this.props
        showTextModal({ isShowTextModal: false })
      }
    )
  }

  handleSubmitSummarise = (e, param) => {
    e.preventDefault()
    const {
      summariseResult,
      summariseResultOptionOne,
      ai_language
    } = this.state
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form2: ', values)
        this.setState({
          ...this.state,
          idx: param.idx
        })

        const dataT1 = {
          content: param.context,
          max_length: values.num_words,
          min_length: 30,
          lang: ai_language
        }

        const dataT = {
          text: param.context,
          num_words: values.num_words,
          num_keywords: 30
        }

        // Option 1
        api.summarization
          .getSummarized(dataT1, 'abstractive')
          .then(res => {
            let data = res.data
            let dataToUpdate = summariseResultOptionOne

            const isDataAlreadyExist =
              dataToUpdate && dataToUpdate.some(data => data.id === param.idx)
            if (!isDataAlreadyExist) {
              dataToUpdate = [
                ...dataToUpdate,
                {
                  id: param.idx,
                  data: data.output
                }
              ]
            }
            const fetchIndexOfExistingData = dataToUpdate.findIndex(
              data => data.id === param.idx
            )
            if (fetchIndexOfExistingData !== -1) {
              dataToUpdate[fetchIndexOfExistingData] = {
                id: dataToUpdate[fetchIndexOfExistingData].id,
                data: data.output
              }
            }
            let newTabIndex = this.state.tabIndex
            newTabIndex[param.idx] = '3'
            this.setState({
              ...this.state,
              summariseResultOptionOne: dataToUpdate,
              loadSummarize: false,
              errorMsg: '',
              tabIndex: newTabIndex
            })
          })
          .catch(err => {
            this.setState({
              loadSummarize: false,
              errorMsg:
                err.error && err.error.msg
                  ? err.error.msg
                  : 'Something went wrong.'
            })
          })

        // Option 2
        this.setState({ loadSummarize: true, errorMsg: '' }, () => {
          api.summarization
            .getSummarized(dataT, 'extractive')
            .then(response => {
              let data = response.data
              let dataToUpdate = summariseResult
              console.log('get data', data.summary)
              const isDataAlreadyExist =
                dataToUpdate && dataToUpdate.some(data => data.id === param.idx)
              if (!isDataAlreadyExist) {
                dataToUpdate = [
                  ...dataToUpdate,
                  {
                    id: param.idx,
                    data: data.summary
                  }
                ]
              }
              const fetchIndexOfExistingData = dataToUpdate.findIndex(
                data => data.id === param.idx
              )
              if (fetchIndexOfExistingData !== -1) {
                dataToUpdate[fetchIndexOfExistingData] = {
                  id: dataToUpdate[fetchIndexOfExistingData].id,
                  data: data.summary
                }
              }
              console.log(
                'final data',
                data.summary,
                'summaryData',
                dataToUpdate
              )
              let newTabIndex = this.state.tabIndex
              newTabIndex[param.idx] = '3'
              this.setState({
                ...this.state,
                summariseResult: dataToUpdate,
                loadSummarize: false,
                errorMsg: '',
                tabIndex: newTabIndex
              })
            })
            .catch(e => {
              console.log(e)
              this.setState({
                loadSummarize: false,
                errorMsg: e.error && e.error.msg
              })
            })
        })
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        const { ai_language } = this.state
        this.setState({
          ...this.state,
          isLoading: true,
          showSearchResult: false,
          summariseResultOptionOne: [],
          summariseResult: [],
          resultData: []
        })

        const {
          info: { active_organization_id }
        } = this.props.user

        const temp = {
          question: values.question,
          no_of_documents: String(1),
          org_id: String(active_organization_id),
          ai_language: ai_language
        }

        api.audioSynthesizer
          .questionBot(temp)
          .then(res => {
            console.log('response question bot', res.data.body)
            if (res.data.body) {
              this.setState({
                ...this.state,
                showSearchResult: true,
                isLoading: false,
                resultData: res.data.body,
                tabIndex: Array(res.data.body.length).fill('1')
              })
            }
          })
          .catch(err => {
            console.log('error in starting event', err)
            const { handleInput } = this.props
            handleInput('')
            this.setState({
              ...this.state,
              showSearchResult: false,
              isLoading: false,
              resultData: []
            })
          })
      }
    })
  }

  showText = val => {
    this.setState({
      showValue: val
    })
  }

  onChangeTab = (key, idx) => {
    let newTabIndex = this.state.tabIndex
    newTabIndex[idx] = key
    this.setState({ tabIndex: newTabIndex })
  }

  handleAiLanguage = value => {
    this.setState({
      ai_language: value,
      summariseResultOptionOne: [],
      summariseResult: []
    })
  }

  render() {
    const {
      content,
      closeEditor,
      t,
      isHideSaveButton,
      handleInput,
      getLibraryList,
      getPublicList,
      getOrgList,
      clearUploadList,
      updateLibraryStatus,
      updateOrgLib,
      handleMakeType,
      library,
      user,
      topics,
      updateTopicList,
      upload,
      onSelectImage,
      pageBuilder: { isShowTextModal }
    } = this.props

    const {
      isWideView,
      showSearchResult,
      isLoading,
      summariseResult,
      idx,
      hasInitialize,
      tabIndex,
      resultData,
      summariseResultOptionOne,
      loadSummarize,
      errorMsg
    } = this.state
    const { getFieldDecorator } = this.props.form

    console.log('render', summariseResult, 'idx', idx)

    const textIcon = (
      <div className="toolbar__item-icon" style={styleTitle}>
        <div className="wrap-video-icons">
          {/* <img src={textIconRectangle} alt="" />{' '} */}
          <span style={{ color: '#67686C' }}> {t('v4:insert_text')} </span>
          {/* <img
            src={textIconInsideRectangle}
            alt=""
            className="text-icon-inside-rectangle"
          /> */}
        </div>
      </div>
    )

    const { isInsertText, showValue } = this.state

    const summariseConfirm = param => (
      <div className="sum_form">
        <p>*{t('v2:enter_number_words')}:</p>
        <Form
          layout="inline"
          onSubmit={e => this.handleSubmitSummarise(e, param)}
        >
          <Form.Item style={{ marginBottom: '10px' }}>
            {getFieldDecorator('num_words', {
              initialValue: 100,
              rules: [{ required: true, message: t('v2:enter_number_words') }]
            })(<InputNumber min={100} />)}
          </Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            {t('v2:summarize')}
          </Button>
        </Form>
      </div>
    )

    const titleSearch = () => {
      return (
        <p>
          <strong>{t('v2:answer')} </strong>
        </p>
      )
    }

    const summarySearch = (answer, context, idx) => {
      return (
        <Popover content={summariseConfirm({ context: context, idx: idx })}>
          {t('v2:summarize')}
        </Popover>
      )
    }

    // const searchResult =
    //   showSearchResult &&
    //   this.state.resultData &&
    //   this.state.resultData.map((data, idx) => {
    //     console.log(this.state.summariseResult, 'show smmariseResults')
    //     const showSummary = this.state.summariseResult.filter(s => s.id === idx)
    //     const showSummary1 = this.state.summariseResultOptionOne.filter(
    //       s => s.id === idx
    //     )

    //     return (
    //       <Fragment>
    //         <Card size="small" title={titleSearch()} style={{ width: '100%' }}>
    //           <Tabs
    //             defaultActiveKey="1"
    //             onChange={e => this.onChangeTab(e, idx)}
    //             activeKey={tabIndex[idx] || '1'}
    //             onTabClick={e => console.log('>>>>>>', e)}
    //           >
    //             <TabPane tab="Answer" key="1">
    //               <p>{data.answer}</p>
    //             </TabPane>
    //             <TabPane tab="Context" key="2">
    //               <p>{data.context}</p>
    //             </TabPane>
    //             {/* {(this.state.summariseResult !== '' && (this.state.idx === idx) && */}
    //             <TabPane
    //               tab={summarySearch(data.answer, data.context, idx)}
    //               key="3"
    //               onClick={() => console.log('>>>>>>')}
    //             >
    //               {this.state.loadSummarize ? (
    //                 'Processing...'
    //               ) : this.state.errorMsg === '' ? (
    //                 showSummary.length > 0 ? (
    //                   <div className="sum__ans">
    //                     <div className="sum__ans_item">
    //                       <p>Option 1</p>
    //                       {showSummary1[0] && showSummary1[0].data ? showSummary1[0].data :  <Spin tip="Loading..." size="small"/>}
    //                     </div>
    //                     <div className="sum__ans_item">
    //                       <p>Option 2</p>
    //                       {showSummary[0].data}
    //                     </div>
    //                   </div>
    //                 ) : (
    //                   ''
    //                 )
    //               ) : (
    //                 this.state.errorMsg
    //               )}
    //             </TabPane>
    //             {/* )} */}
    //           </Tabs>
    //         </Card>
    //         &nbsp;
    //       </Fragment>
    //     )
    //   })

    return (
      <Fragment>
        <div className="text__controls">
          <Select
            showSearch
            style={{ width: '70%' }}
            placeholder={t('v4:translate_to')}
            optionFilterProp="children"
            onChange={e => this.handleChangeOption(e)}
          >
            {translationKeys.map(item => (
              <Option key={item.code} value={item.code}>
                {/* <Flag code={item.code} height='13' /> */} {item.name}
              </Option>
            ))}
          </Select>
          &nbsp;
          <Button
            className="rounded import-from-library"
            size="large"
            onClick={this.handleClickImportText}
          >
            {t('v4:import_from_library')}
          </Button>
          &nbsp;&nbsp;
          {this.props.user &&
            this.props.user.info &&
            this.props.user.info.organization &&
            this.props.user.info.organization.enable_ai && (
              <Button
                className="rounded"
                size="large"
                onClick={this.handleClickQAText}
              >
                {t('buttons:question_answer_ai')}
              </Button>
            )}
        </div>
        <Modal
          className="custom-ant-modal"
          title={textIcon}
          visible={isShowTextModal}
          onCancel={this.onHideTextModal}
          width={'85%'}
          centered
          footer={[
            <Button
              type="default"
              shape="round"
              size="default"
              className="ant-btn rounded"
              onClick={this.onHideTextModal}
            >
              {t('buttons:cancel')}
            </Button>,
            <Button
              type="primary"
              shape="round"
              size="default"
              className="ant-btn rounded ant-btn-primary"
              onClick={this.handleInsertText}
            >
              {t('buttons:insert')}
            </Button>
          ]}
        >
          <TextLibrary
            isInsertText={isInsertText}
            getLibraryList={getLibraryList}
            getPublicList={getPublicList}
            getOrgList={getOrgList}
            upload={upload}
            clearUploadList={clearUploadList}
            updateLibraryStatus={updateLibraryStatus}
            updateOrgLib={updateOrgLib}
            handleMakeType={handleMakeType}
            library={library}
            user={user}
            topics={topics}
            updateTopicList={updateTopicList}
            showValue={showValue}
            showText={this.showText}
          />
        </Modal>
        <QuestionAnswerAI
          isWideView={isWideView}
          handleSubmit={this.handleSubmit}
          getFieldDecorator={getFieldDecorator}
          handleAiLanguage={e => this.handleAiLanguage(e)}
          hasInitialize={hasInitialize}
          hasInitializeGerman={true}
          isLoading={isLoading}
          showSearchResult={showSearchResult}
          resultData={resultData}
          summariseResult={summariseResult}
          summariseResultOptionOne={summariseResultOptionOne}
          onChangeTab={this.onChangeTab}
          tabIndex={tabIndex}
          summarySearch={summarySearch}
          loadSummarize={loadSummarize}
          errorMsg={errorMsg}
          handleInput={handleInput}
          content={content}
          onSelectImage={onSelectImage}
          titleSearch={titleSearch()}
        />
        {/* {isWideView && (
          <Row>
              <Col span={12}>
                &nbsp; &nbsp;
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item
                    label={t('v2:enter_question')}
                    style={{ marginBottom: '7px' }}
                  >
                    {getFieldDecorator('question', {
                      rules: [
                        {
                          required: true,
                          message: t('v2:enter_question')
                        }
                      ]
                    })(
                      <Input
                        placeholder={`${t('v2:eg')}: ${t(
                          'v2:what_make_sales_manager'
                        )}`}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label={t('v2:enter_relevant_documents')}
                    style={{ marginBottom: '10px' }}
                  >
                    {getFieldDecorator('no_of_documents', {
                      initialValue: 10,
                      rules: [
                        {
                          required: true,
                          message: t('v2:please_input_relevant_documents')
                        }
                      ]
                    })(<InputNumber min={1} max={20} />)}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('language', {
                      initialValue: 'en-US',
                      rules: [
                        {
                          required: true,
                          message: t('v2:please_select_lang')
                        }
                      ]
                    })(
                      <Select
                        showSearch
                        style={{ width: 185 }}
                        size="small"
                        optionFilterProp="children"
                        onChange={e => this.handleAiLanguage(e)}
                      >
                        {langOptions.map(item => (
                          <Option key={item.code} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  { 
                    (hasInitialize && hasInitializeGerman) &&
                    <Button
                      type="primary"
                      icon="search"
                      shape="round"
                      htmlType="submit"
                    >
                      {t('v2:Search')}
                    </Button>
                  }
                  {(!hasInitialize || !hasInitializeGerman) && <Spin tip="Wait a few seconds..."/>}
                </Form>
                &nbsp; &nbsp;
                {isLoading && <h2>{t('v2:please_wait_loading_results')}</h2>}
                {showSearchResult && !isLoading && (
                  <Fragment>
                    &nbsp; &nbsp;
                    {this.state.resultData.length > 0 ? (
                      <h2>
                        {t('v2:Results')}({this.state.resultData.length})
                      </h2>
                    ) : (
                      <h2>{t('v2:no_result_found_try_asking_questions')}</h2>
                    )}
                    <div className="result-relevant-answers">
                      {searchResult}
                    </div>
                  </Fragment>
                )}
              </Col>
            <Col span={11} offset={1}>
              &nbsp; &nbsp;
              <div className="el-editor">
                <div className="el-editor__component">
                  <QuillStyled
                    preserveWhitespace
                    value={content || ''}
                    onChange={handleInput}
                    modules={{
                      toolbar
                    }}
                    style={{}}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )} */}

        {!isWideView && (
          <div className="el-editor">
            {showValue && (
              <Fragment>
                <div className="el-editor__component">
                  <QuillStyled
                    preserveWhitespace
                    value={showValue}
                    modules={{
                      toolbar
                    }}
                  />
                </div>
              </Fragment>
            )}
            <div className="el-editor__component">
              {showValue && (
                <h4 style={{ color: 'red' }}>
                  * {t('v2:please_copy_desired_content')}
                </h4>
              )}
              <QuillStyled
                preserveWhitespace
                value={content || ''}
                onChange={handleInput}
                modules={{
                  toolbar
                }}
              />
            </div>
            {!isHideSaveButton && (
              <div className="el-editor__controls">
                <div className="el-editor__control">
                  <Button
                    type="default"
                    size="small"
                    icon="check"
                    onClick={() => closeEditor()}
                  >
                    {t('buttons:save')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </Fragment>
    )
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'text_form' })(Test)

export default withTranslation()(WrappedNormalLoginForm)
