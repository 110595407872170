import React, { Component, Fragment, useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import ImageCropContainer from '../../../../../../components/ImageCrop'
import ModalOrganisationScheduleTimeForm from './ScheduleTimeForm'
import {
  Form,
  Button,
  Input,
  Spin,
  Icon,
  Select,
  Row,
  Col,
  Radio,
  Divider,
  Card
} from 'antd'

import { ModalOrganisations, FormItem, LargeFormItem } from './styled'
import { withTranslation, Trans } from 'react-i18next'
import { general, mediaServices } from '../../../../../../constants'
import { flexDirection } from 'styled-system'
class ModalAddOrganisations extends Component {
  state = {
    fetchUpload: false,
    logoLink: null,
    logoId: null,
    submitted: false,
    selectedColor: null,
    enableWhiteLabel: this.props.org
      ? this.props.org.enable_white_label
      : false,
    isWhiteLabelLogo: false
  }

  onCancel = () => {
    const { form, onCancel } = this.props

    form.resetFields()

    this.setState({
      logoId: null,
      logoLink: null
    })

    onCancel()
  }

  handleDrop = (dropped, isWhiteLabelLogo) => {
    if (!dropped) return

    const file = dropped[0]
    if (!file) {
      return
    }
    const image = URL.createObjectURL(file)
    this.setState({
      file,
      image,
      isWhiteLabelLogo: isWhiteLabelLogo
    })
  }

  handleUploadImage = data => {
    const { upload } = this.props
    const { isWhiteLabelLogo } = this.state
    const file = data

    const opts = {
      isPublic: false
    }
    if (isWhiteLabelLogo) {
      this.setState({ uploadWhiteLabelLogo: true })
    } else {
      this.setState({ fetchUpload: true })
    }

    return upload(file, opts).then(res => {
      if (isWhiteLabelLogo) {
        this.setState({
          isWhiteLabelLogo: false,
          uploadWhiteLabelLogo: false,
          whiteLabelLogoId: res.id,
          whiteLabelLogoLink: res.link
        })
      } else {
        this.setState({
          fetchUpload: false,
          logoId: res.id,
          logoLink: res.link
        })
      }
    })
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null,
        isAvatarLoading: true
      })
    } else {
      this.setState({
        image: null,
        file: null,
        isAvatarLoading: false
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { form, onSubmit, org, isSuperAdmin } = this.props
    const { logoId, whiteLabelLogoId } = this.state

    const white_label_data = org && org.white_label_data
    const header = white_label_data && white_label_data.header
    const headerIconId = header && header.logo_id
    const setState = () => {
      this.setState({
        logoId: null,
        logoLink: '',
        whiteLabelLogoId: null,
        whiteLabelLogoLink: '',
        submitted: false
      })
    }
    form.validateFields((err, values) => {
      if (!err) {
        const data = {
          title: values.title,
          description: values.description,
          logo_id: logoId || (org && org.logo_id)
        }
        this.setState({
          ...this.state,
          submitted: true
        })
        if (isSuperAdmin) {
          console.log(`values`, values)
          data.media_service = values.media_service
          data.broadcast_limit = parseFloat(values.limit)
          data.transcribe_limit = parseFloat(values.transcribe_limit)
          data.enable_ai = values.enable_ai
          data.enable_maze = values.enable_maze
          data.enable_ai_course = values.enable_ai_course
          data.enable_white_label = values.enable_white_label
          data.enable_data = values.enable_data
          data.white_label_data = values.enable_white_label
            ? {
                header: {
                  color: values.header_color || (header && header.color),
                  logo_id: whiteLabelLogoId || headerIconId
                }
              }
            : null
          onSubmit(data, !!org)
          setState()
        } else {
          onSubmit(data, !!org).then(() => {
            setState()
          })
        }
      }
    })
  }

  handleColorChange = color => {
    this.setState({ selectedColor: color })
  }

  render() {
    const {
      form,
      visible,
      loading,
      org,
      t,
      isSuperAdmin,
      editData
    } = this.props
    const {
      fetchUpload,
      logoLink,
      image,
      file,
      submitted,
      isWhiteLabelLogo = false,
      whiteLabelLogoLink,
      enableWhiteLabel,
      uploadWhiteLabelLogo = false,
      selectedColor
    } = this.state

    const orgLogo = org && org.logo ? org.logo.link : ''
    const usage = (org && org.total_usage) || 0
    const orgLimit = (org && org.broadcast_limit) || 0
    const limit = orgLimit - usage
    const transcribeUsage = (org && org.total_transcribe_usage) || 0
    const transcribeOrgLimit = (org && org.transcribe_limit) || 0
    const transcribe_limit = transcribeOrgLimit - transcribeUsage
    const whiteLabelHeader =
      org && org.white_label_data && org.white_label_data.header
    const whiteLabelLogo =
      whiteLabelHeader && whiteLabelHeader.logo && whiteLabelHeader.logo.link
    const whiteLabelHeaderColor = whiteLabelHeader && whiteLabelHeader.color

    console.log({ props: this.props, state: this.state })

    return (
      <ModalOrganisations visible={visible} closable={false} width={950}>
        <ModalOrganisations.Header>
          <ModalOrganisations.Title>
            {`${org ? t('labels:edit') : t('labels:add')} ${t(
              'labels:organization'
            )}`}
          </ModalOrganisations.Title>

          <ModalOrganisations.Close>
            <Icon type="close" onClick={this.onCancel} />
          </ModalOrganisations.Close>
        </ModalOrganisations.Header>

        <Form onSubmit={this.handleSubmit}>
          <ModalOrganisations.Container>
            <Form.Item label={t('labels:title')} colon={false} required>
              {form.getFieldDecorator('title', {
                rules: [
                  { required: true, message: t('errors:title') },
                  { min: 3, message: t('errors:min_3char') },
                  { whitespace: true, message: t('errors:title_empty') }
                ],
                initialValue: org ? org.title : ''
              })(<Input type="text" />)}
            </Form.Item>

            <Form.Item label={t('labels:description')} colon={false}>
              {form.getFieldDecorator('description', {
                initialValue: org ? org.description : ''
              })(<Input.TextArea rows={4} />)}
            </Form.Item>

            <Row type="flex" justify="space-between">
              <Col span={isSuperAdmin ? 12 : 24}>
                {isSuperAdmin ? (
                  <FormItem
                    label={`${t('labels:live_stream_limit')} (${
                      org && org.total_usage ? org.total_usage : 0
                    } mins used)`}
                    colon={false}
                  >
                    {form.getFieldDecorator('limit', {
                      rules: [
                        {
                          type: 'number',
                          required: true,
                          // message: t('errors:limit'),
                          asyncValidator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                              if (value === '') {
                                reject(t('errors:limit'))
                              } else if (value < 0) {
                                reject(t('errors:number')) // reject with error message
                              } else {
                                resolve()
                              }
                            })
                          }
                        }
                      ],
                      initialValue: editData
                        ? editData.broadcast_limit
                        : org
                        ? org.broadcast_limit
                        : 60
                    })(
                      <Input
                        type="number"
                        addonAfter="mins"
                        style={{ width: '195px' }}
                      />
                    )}
                  </FormItem>
                ) : (
                  <Card>
                    <Trans i18nKey="labels:limit_stream_text" limit={limit}>
                      <span>{{ limit }}</span>
                      <br />
                      <ModalOrganisations.Icon
                        type="info-circle"
                        theme="filled"
                      />
                      <span
                        style={{ fontSize: '10px', justifyContent: 'center' }}
                      >
                        To change your monthly live-streaming limit please
                        contact
                      </span>
                      <a
                        style={{ fontSize: '10px', justifyContent: 'center' }}
                        href="mailto:admin@sharelook.com"
                      >
                        admin@sharelook.com
                      </a>
                    </Trans>
                  </Card>
                )}
                <br />

                {isSuperAdmin ? (
                  <FormItem
                    label={`${t('v4:transcribe_limit')} (${
                      org && org.total_transcribe_usage
                        ? org.total_transcribe_usage
                        : 0
                    } mins used)`}
                    colon={false}
                  >
                    {form.getFieldDecorator('transcribe_limit', {
                      rules: [
                        {
                          type: 'number',
                          required: true,
                          // message: t('errors:limit'),
                          asyncValidator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                              if (value === '') {
                                reject(t('v4:transcribe_limit'))
                              } else if (value < 0) {
                                reject(t('errors:number')) // reject with error message
                              } else {
                                resolve()
                              }
                            })
                          }
                        }
                      ],
                      initialValue: editData
                        ? editData.transcribe_limit
                        : org
                        ? org.transcribe_limit
                        : 60
                    })(
                      <Input
                        type="number"
                        addonAfter="mins"
                        style={{ width: '195px' }}
                      />
                    )}
                  </FormItem>
                ) : (
                  <Card>
                    <Trans
                      i18nKey="v3:limit_transcribe_text"
                      transcribe_limit={transcribe_limit}
                    >
                      <span>{{ transcribe_limit }}</span>
                      <br />
                      <ModalOrganisations.Icon
                        type="info-circle"
                        theme="filled"
                      />
                      <span
                        style={{ fontSize: '10px', justifyContent: 'center' }}
                      >
                        To change your monthly transcribe limit please contact
                      </span>
                      <a
                        style={{ fontSize: '10px', justifyContent: 'center' }}
                        href="mailto:admin@sharelook.com"
                      >
                        admin@sharelook.com
                      </a>
                      <br />
                    </Trans>
                  </Card>
                )}

                {isSuperAdmin && (
                  <FormItem
                    label={'Media Service'}
                    colon={false}
                    required={false}
                  >
                    {form.getFieldDecorator('media_service', {
                      rules: [],
                      initialValue: editData
                        ? editData.media_service
                        : org
                        ? org.media_service
                        : 'CHIME'
                    })(
                      <Select
                        optionFilterProp="children"
                        showSearch
                        // size="large"
                        filterOption={(input, option) =>
                          !_.isArray(option.props.children) &&
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '195px' }}
                      >
                        {Object.keys(mediaServices).map(item => (
                          <Select.Option key={item} value={item}>
                            {t(`mediaServices:${item}`)}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                )}

                {org && (
                  <ModalOrganisationScheduleTimeForm
                    form={form}
                    isSuperAdmin={isSuperAdmin}
                    org={org}
                    submitted={submitted}
                  ></ModalOrganisationScheduleTimeForm>
                )}

                <LargeFormItem
                  label="Company Logo"
                  colon={false}
                  required
                  style={{ flexDirection: isSuperAdmin ? 'row' : 'column' }}
                >
                  <ModalOrganisations.Container>
                    <ModalOrganisations.Upload>
                      <Spin spinning={fetchUpload}>
                        <Dropzone
                          accept="image/*"
                          onDrop={dropped => this.handleDrop(dropped, false)}
                          style={{ width: '195px', height: '150px' }}
                        >
                          {({ isDragActive }) => (
                            <ModalOrganisations.Dropzone
                              active={isDragActive}
                              background={logoLink || orgLogo}
                            >
                              {isDragActive ? (
                                <ModalOrganisations.Dropzone.Text>
                                  {t('labels:drop_file')}
                                </ModalOrganisations.Dropzone.Text>
                              ) : (
                                <ModalOrganisations.Dropzone.Button
                                  hide={false}
                                >
                                  <Icon type="picture" />
                                  {t('buttons:upload_image')}
                                </ModalOrganisations.Dropzone.Button>
                              )}
                            </ModalOrganisations.Dropzone>
                          )}
                        </Dropzone>
                      </Spin>
                    </ModalOrganisations.Upload>
                  </ModalOrganisations.Container>
                </LargeFormItem>
              </Col>
              {isSuperAdmin && (
                <Divider type="vertical" style={{ height: 'inherit' }} />
              )}

              <Col span={10} className="org-form-col-2">
                {isSuperAdmin && (
                  <Fragment>
                    <RadioRow
                      {...this.props}
                      label={t('v4:enable_ai')}
                      fieldKey="enable_ai"
                    />
                    <RadioRow
                      {...this.props}
                      label={t('enable_maze:enable_maze')}
                      fieldKey="enable_maze"
                    />

                    <RadioRow
                      {...this.props}
                      label="Enable Course Creation with AI"
                      fieldKey="enable_ai_course"
                    />

                    <RadioRow
                      {...this.props}
                      label="Enable Showing Reports to Admin"
                      fieldKey="enable_data"
                    />

                    <RadioRow
                      {...this.props}
                      label="Enable White Label"
                      fieldKey="enable_white_label"
                      onChange={value =>
                        this.setState({ enableWhiteLabel: value })
                      }
                    />
                    {enableWhiteLabel && (
                      <Fragment>
                        <LargeFormItem
                          label={'Header Color'}
                          required
                          colon={false}
                        >
                          {form.getFieldDecorator('header_color', {
                            initialValue: whiteLabelHeaderColor
                          })(
                            <ColorList
                              selectedColor={
                                selectedColor || whiteLabelHeaderColor
                              }
                              onChange={this.handleColorChange}
                            />
                          )}
                        </LargeFormItem>
                        <LargeFormItem
                          label="Header Logo"
                          required
                          colon={false}
                        >
                          <ModalOrganisations.Container>
                            <ModalOrganisations.Upload>
                              <Spin spinning={uploadWhiteLabelLogo}>
                                <Dropzone
                                  accept="image/*"
                                  onDrop={dropped =>
                                    this.handleDrop(dropped, true)
                                  }
                                  style={{ width: '195px', height: '150px' }}
                                >
                                  {({ isDragActive }) => (
                                    <ModalOrganisations.Dropzone
                                      active={isDragActive}
                                      background={
                                        whiteLabelLogoLink || whiteLabelLogo
                                      }
                                    >
                                      {isDragActive ? (
                                        <ModalOrganisations.Dropzone.Text>
                                          {t('labels:drop_file')}
                                        </ModalOrganisations.Dropzone.Text>
                                      ) : (
                                        <ModalOrganisations.Dropzone.Button
                                          hide={false}
                                        >
                                          <Icon type="picture" />
                                          {t('buttons:upload_image')}
                                        </ModalOrganisations.Dropzone.Button>
                                      )}
                                    </ModalOrganisations.Dropzone>
                                  )}
                                </Dropzone>
                              </Spin>
                            </ModalOrganisations.Upload>
                          </ModalOrganisations.Container>
                        </LargeFormItem>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Col>
            </Row>

            <Divider />

            <ModalOrganisations.BtnGroup>
              <Button
                className="rounded"
                onClick={this.onCancel}
                style={{ margin: '0 1rem' }}
              >
                {t('buttons:cancel')}
              </Button>
              <Spin spinning={loading || fetchUpload}>
                <Button
                  type="primary"
                  className="rounded"
                  htmlType="submit"
                  style={{ margin: '0 1rem' }}
                >
                  {`${org ? t('labels:edit') : t('labels:add')} ${t(
                    'labels:organization'
                  )}`}
                </Button>
              </Spin>
            </ModalOrganisations.BtnGroup>
          </ModalOrganisations.Container>
        </Form>

        {image && (
          <ImageCropContainer
            circle={!isWhiteLabelLogo}
            t={t}
            freeCrop={isWhiteLabelLogo}
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
          />
        )}
      </ModalOrganisations>
    )
  }
}

function RadioRow({
  label,
  form: { getFieldDecorator },
  fieldKey,
  onChange,
  org,
  editData
}) {
  const [selected, setSelected] = useState('no')
  useEffect(() => {
    setSelected(getSelectedValue() ? 'yes' : 'no')
  }, [org, editData])

  function getSelectedValue() {
    if (editData) {
      return editData[fieldKey]
    } else if (org) {
      return org[fieldKey]
    }
    return false
  }

  return (
    <FormItem
      key={fieldKey}
      label={label}
      colon={false}
      required
      className="org-radio-row"
    >
      {getFieldDecorator(fieldKey, {
        initialValue: selected
      })(
        <Radio.Group
          onChange={e => {
            const value = e.target.value
            onChange && onChange(value === 'yes' ? true : false)
          }}
        >
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      )}
    </FormItem>
  )
}

function ColorList({ selectedColor, onChange, ...props }) {
  const background_colors = useMemo(() => {
    return [
      '#f44336',
      '#e91e63',
      '#9c27b0',
      '#673ab7',
      '#3f51b5',
      '#2196f3',
      '#03a9f4',
      '#00bcd4',
      '#009688',
      '#4caf50',
      '#8bc34a',
      '#cddc39',
      '#ffc107',
      '#ff9800'
    ]
  }, [])
  const [colorList, setColorList] = useState(background_colors)

  useEffect(() => {
    if (selectedColor && !colorList.includes(selectedColor)) {
      setColorList([...colorList, selectedColor])
    }
  }, [selectedColor])

  console.log(colorList)

  return (
    <div className="color-list">
      {colorList.map((color, index) => (
        <div
          key={index}
          style={{ backgroundColor: color }}
          className={`color ${selectedColor === color ? 'active' : ''}`}
          onClick={() => onChange(color)}
        />
      ))}
    </div>
  )
}

export default Form.create()(
  withTranslation('management')(ModalAddOrganisations)
)
