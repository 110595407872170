import React, { Fragment, useState } from 'react';
import { Modal, Form, Upload, Button, Select, Input, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const AiAudioModal = ({ open, close, form, onFinish }) => {
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const { getFieldDecorator, validateFields, getFieldsValue, setFieldsValue } = form;

  const handleCancel = () => {
    close();
    setStep(0);
    setFormValues({});
  };

  const saveCurrentStepValues = () => {
    const currentValues = getFieldsValue();
    setFormValues(prevValues => ({
      ...prevValues,
      ...currentValues
    }));
  };

  const next = () => {
    validateFields().then(values => {
      saveCurrentStepValues();
      setStep(step + 1);
    });
  };

  const prev = () => {
    saveCurrentStepValues();
    setStep(step - 1);
  };

  const handleOk = () => {
    validateFields().then(values => {
      const allValues = {
        ...formValues,
        ...values
      };
      onFinish(allValues);
    });
  };

  const uploadProps = {
    beforeUpload: file => {
      const files = getFieldsValue().files || [];
      if (files.length >= 3) {
        setFieldsValue({
          files: [...files, file]
        });
        return false;
      }
      return true;
    },
    multiple: true
  };

  const voiceUploadProps = {
    beforeUpload: file => {
      const voiceFiles = getFieldsValue().voiceFiles || [];
      if (voiceFiles.length >= 2) {
        setFieldsValue({
          voiceFiles: [...voiceFiles, file]
        });
        return false;
      }
      return true;
    },
    multiple: true
  };

  return (
    <Modal
      visible={open}
      title="Generate Podcast Audio"
      onCancel={handleCancel}
      footer={[
        step > 0 && (
          <Button key="back" onClick={prev}>
            Previous
          </Button>
        ),
        step < 1 && (
          <Button key="next" type="primary" onClick={next}>
            Next
          </Button>
        ),
        step === 1 && (
          <Button key="submit" type="primary" onClick={handleOk}>
            Generate Podcast
          </Button>
        )
      ]}
    >
      <Form layout="vertical">
        {step === 0 && (
          <Fragment>
            <Form.Item label="Upload file (up to 3 files)">
              {getFieldDecorator('files', {
                initialValue: formValues.files || [],
                rules: [{ required: true, message: 'Please upload files' }],
                valuePropName: 'fileList',
                getValueFromEvent: e => (Array.isArray(e) ? e : e && e.fileList)
              })(
                <Upload {...uploadProps} listType="text">
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              )}
            </Form.Item>
            <Form.Item label="How many speakers">
              {getFieldDecorator('speakers', {
                initialValue: formValues.speakers || undefined,
                rules: [
                  { required: true, message: 'Please select the number of speakers' }
                ]
              })(
                <Select>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="List the name of the host">
              {getFieldDecorator('host', {
                initialValue: formValues.host || '',
                rules: [{ required: true, message: 'Please enter the host name' }]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Name of guests">
              {getFieldDecorator('guests', {
                initialValue: formValues.guests || '',
                rules: [{ required: true, message: 'Please enter the guests names' }]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Choose tone of podcast">
              {getFieldDecorator('tone', {
                initialValue: formValues.tone || undefined,
                rules: [{ required: true, message: 'Please select the tone of the podcast' }]
              })(
                <Select>
                  <Option value="serious">Serious</Option>
                  <Option value="light_humor">Light Humor</Option>
                  <Option value="casual">Casual</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Length">
              {getFieldDecorator('length', {
                initialValue: formValues.length || '',
                rules: [{ required: true, message: 'Please enter the length of the podcast' }]
              })(<Input placeholder="e.g., 15 min" />)}
            </Form.Item>
          </Fragment>
        )}
        {step === 1 && (
          <Fragment>
            <Form.Item label="Choose voice or upload voice file">
              {getFieldDecorator('voiceChoice', {
                initialValue: formValues.voiceChoice || undefined,
                rules: [{ required: true, message: 'Please choose or upload a voice file' }]
              })(
                <Radio.Group>
                  <Radio value="chooseVoice">Choose Voices</Radio>
                  <Radio value="uploadVoice">Upload Voice Files</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            {getFieldsValue().voiceChoice === 'chooseVoice' && (
              <Form.Item label="Name the voice">
                {getFieldDecorator('chosenVoices', {
                  initialValue: formValues.chosenVoices || undefined,
                  rules: [{ required: true, message: 'Please choose voice' }]
                })(
                  <Select>
                    <Option value="voice1">Voice 1</Option>
                    <Option value="voice2">Voice 2</Option>
                    <Option value="voice3">Voice 3</Option>
                    <Option value="voice4">Voice 4</Option>
                  </Select>
                )}
              </Form.Item>
            )}
            {getFieldsValue().voiceChoice === 'uploadVoice' && (
              <Form.Item label="Upload up to 2 voice files">
                {getFieldDecorator('voiceFiles', {
                  initialValue: formValues.voiceFiles || [],
                  rules: [{ required: true, message: 'Please upload voice files' }],
                  valuePropName: 'fileList',
                  getValueFromEvent: e => (Array.isArray(e) ? e : e && e.fileList)
                })(
                  <Upload {...voiceUploadProps} listType="text">
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                )}
              </Form.Item>
            )}
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'podcast_form' })(AiAudioModal);
