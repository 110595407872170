import React from 'react'
import { Switch, Router, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import history from '../../../history'

import Report from '../Report'

class ReportRouting extends React.Component {
  render() {
    const {
      userInfo: {
        info,
        info: {
          organization: { enable_data }
        }
      },
      isUser
    } = this.props
    const isAdmin = info.is_super_admin
      ? true
      : info.role
      ? info.role.name === 'Admin'
      : false
    const userId = info.id ? info.id : false

    return (
      <Router history={history}>
        <Switch>
          {userId &&
            (isAdmin && enable_data ? (
              <Route path="/reports" component={Report} />
            ) : (
              <Redirect
                to={!isUser ? `/reports/user/participant/${userId}` : '/'}
              />
            ))}
        </Switch>
      </Router>
    )
  }
}

export default connect(state => ({ userInfo: state.user }))(ReportRouting)
