import React, { useMemo } from 'react'

import { withTranslation } from 'react-i18next'
import './CreateChannelHeader.scss'
import { Button } from 'antd'
import NewHeader from '../../../../../components/NewHeader'

function CreateChannelHeader({
  t,
  isMarketplaceView,
  onToggleMarketplace,
  channelId
}) {
  const marketPlaceButtonText = useMemo(
    () =>
      isMarketplaceView
        ? t('v3:save_and_continue').toUpperCase()
        : t('buttons:market_place').toUpperCase(),
    [isMarketplaceView]
  )

  return (
    <div className="shl-create-channel-header">
      <h1 className="shl-create-channel-header-title">
        {t('v3:create_a_new_channel')}
      </h1>

      <div class="marketplace-head">
        <Button
          size="large"
          shape="round"
          className="button-blue_color"
          onClick={onToggleMarketplace}
          disabled={!channelId}
        >
          {marketPlaceButtonText}
        </Button>
        <NewHeader />
      </div>
    </div>
  )
}

export default withTranslation()(CreateChannelHeader)
