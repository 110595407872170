import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Icon,
  Upload,
  message,
  List,
  Typography,
  Tooltip
} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import './styles.scss'
import { api } from '../../../../../../services'
import { general } from '../../../../../../constants'
import { bytesToSize } from '../../../../../../utils'
import actions from '../../../../../../store/actions'

const { Text } = Typography

const formFields = {
  end_goal: {
    value: 'End Goal',
    description: 'The ultimate objective or desired outcome of the scenario.'
  },
  problem_statement: {
    value: 'Problem Statement',
    description:
      'A concise description of the challenge or issue to be addressed in the scenario.'
  },
  learner_role: {
    value: 'Learner Role',
    description:
      'The specific role or position the learner is expected to assume within the scenario.'
  },
  depth: {
    value: 'Depth',
    description: 'The number of layers of scenarios within maze'
  },
  no_of_options: {
    value: 'No of Options',
    description:
      'The number of alternative choices or paths available to the learner within the scenario.'
  }
}

const AiMazeGeneration = ({ form, onMazeGenerated, onClose }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [fileList, setFileList] = useState([])
  const [fileError, setFileError] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [fileUploadStatus, setFileUploadStatus] = useState({})

  const dispatch = useDispatch()
  const library = useSelector(state => state.library)
  const { isFetching, uploadProgress, uploadSuccess } = library

  useEffect(() => {
    checkFileError()
  }, [fileList])

  const handleOk = async e => {
    e.preventDefault()
    form.validateFields(async (errors, values) => {
      if (fileError) return
      if (!errors) {
        try {
          // First, upload the files
          setIsUploading(true)
          const newFiles = await uploadFiles(fileList)
          setIsUploading(false)

          // Then, generate the maze with the uploaded file IDs
          setIsGenerating(true)
          const files = newFiles.map(item => item.path)
          await generateMaze(values, files)
        } catch (err) {
          console.log(err)
          message.error('An error occurred during the process.')
        } finally {
          setIsUploading(false)
          setIsGenerating(false)
        }
      }
    })
  }

  const uploadFiles = async files => {
    setUploadedFiles([])
    setFileUploadStatus({})
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      try {
        setFileUploadStatus(prevStatus => ({
          ...prevStatus,
          [file.name]: 'uploading'
        }))
        const res = await dispatch(
          actions.library.upload(file.originFileObj, {
            ai_language: 'en-US',
            isAiEnabled: true
          })
        )
        const newFiles = [...uploadedFiles, res]
        setUploadedFiles(newFiles)
        setFileUploadStatus(prevStatus => ({
          ...prevStatus,
          [file.name]: 'success'
        }))

        return newFiles
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error)
        message.error(`Failed to upload ${file.name}`)
        setFileUploadStatus(prevStatus => ({
          ...prevStatus,
          [file.name]: 'error'
        }))
        throw error
      }
    }
  }

  const generateMaze = async (values, fileList) => {
    const {
      end_goal,
      problem_statement,
      learner_role,
      depth,
      number_of_options
    } = values

    const payload = {
      file_list: fileList,
      end_goal,
      problem_statement,
      learner_role,
      depth,
      number_of_options
    }

    try {
      const res = await api.maze.generateMazeByAi(payload)
      if (res.data) {
        const resArray = res.data.body
        const sortedArray = restructureInput(resArray)
        onMazeGenerated(sortedArray)
      }
    } catch (err) {
      console.log(err)
      message.error('An error occurred while generating the maze.')
    }
  }

  const checkFileError = () => {
    if (fileList.length === 0) {
      setFileError('Please upload at least one PDF file.')
    } else if (fileList.length > 5) {
      setFileError(
        'You can upload a maximum of 5 files. For more, please use the media library.'
      )
    } else {
      setFileError('')
    }
  }

  const handleFileChange = info => {
    let newFileList = [...info.fileList]

    // Only accept PDF files
    newFileList = newFileList.filter(file => {
      if (file.type !== 'application/pdf') {
        message.error(`${file.name} is not a PDF file`)
        return false
      }
      return true
    })

    setFileList(newFileList)
  }

  function restructureInput(input) {
    let newArray = []
    let codeCount = 1

    input.sort((a, b) => {
      if (a.parent === b.parent) {
        return input.indexOf(a) - input.indexOf(b)
      } else if (a.parent === null) {
        return -1
      } else if (b.parent === null) {
        return 1
      } else {
        return a.parent - b.parent
      }
    })

    input.forEach(item => {
      let oldCode = null
      if (item.parent === null) {
        newArray.unshift(item)
        codeCount++
      } else {
        let newItem = {}
        oldCode = item.code
        newItem = {
          ...item,
          code: codeCount,
          oldCode: oldCode
        }

        if (item.type == 'Scenario') {
          input.forEach((i, index) => {
            if (i.parent === oldCode) {
              if (i.is_changed) return
              input[index] = {
                ...input[index],
                parent: codeCount,
                oldParent: i.parent
              }
            }
          })

          const temp = newArray.find(x => x.oldCode === item.parent)
          newItem.parent = temp.code
        }

        newArray.push(newItem)
        codeCount++
      }
    })

    let output = newArray.map(item => {
      let newItem = { ...item }

      delete newItem.oldCode
      delete newItem.oldParent

      return newItem
    })

    return output
  }

  const { getFieldDecorator } = form

  const checkedCompletUpload = item => {
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )
    return !(uploadSuccess.length && filterSuccessFiles.length)
  }

  const renderFileStatus = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={fileList}
        renderItem={file => (
          <List.Item>
            <List.Item.Meta
              title={file.name}
              description={
                <>
                  {fileUploadStatus[file.name] === 'uploading' && (
                    <Text type="secondary">
                      <Icon type="loading" /> Uploading...
                    </Text>
                  )}
                  {fileUploadStatus[file.name] === 'success' && (
                    <Text type="success">
                      <Icon type="check-circle" /> Uploaded successfully
                    </Text>
                  )}
                  {fileUploadStatus[file.name] === 'error' && (
                    <Text type="danger">
                      <Icon type="close-circle" /> Upload failed
                    </Text>
                  )}
                </>
              }
            />
          </List.Item>
        )}
      />
    )
  }

  console.log({ uploadedFiles })

  return (
    <Modal
      visible
      centered
      footer={null}
      closable={false}
      className="ai-maze-form-modal"
      width={800}
    >
      <div className="ai-maze-form-modal__header">
        <p>AI Metamaze Generation</p>
        <Icon type="close" onClick={onClose} />
      </div>
      <Form layout="vertical" onSubmit={handleOk}>
        <Form.Item
          label="Upload Content (PDF files, max 5)"
          colon={false}
          required={true}
        >
          <Upload
            multiple
            accept=".pdf"
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false} // Prevent auto upload
          >
            <Button>
              <Icon type="upload" /> Select Files
            </Button>
          </Upload>
          {fileError && (
            <div style={{ color: 'red', marginTop: '8px' }}>{fileError}</div>
          )}
        </Form.Item>
        {(isUploading || isGenerating) && renderFileStatus()}

        {uploadProgress.length > 0 &&
          uploadSuccess.length !== uploadProgress.length &&
          uploadProgress.map((item, ind) =>
            item.percent < 100 ? (
              <div key={item.uid} className="upload-modal__item">
                <div className="upload-modal-item">
                  <div className="upload-modal-item__percent-border">
                    <div
                      className={`upload-modal-item__percent ${
                        item.status === general.CANCELED
                          ? 'upload-modal-item__percent__cancel'
                          : ''
                      }`}
                      style={{ width: item.percent + '%' }}
                    />
                  </div>
                </div>

                {item.status !== general.CANCELED && (
                  <div className="upload-modal-item__weight">
                    {checkedCompletUpload(item) && (
                      <span>
                        {bytesToSize(item.loaded)} of {bytesToSize(item.total)}
                      </span>
                    )}
                    {!checkedCompletUpload(item) && (
                      <span className="upload-modal-item__weight__complete">
                        {uploadedFiles[ind] && uploadedFiles[ind].removed
                          ? 'Removed'
                          : 'Completed'}
                      </span>
                    )}
                  </div>
                )}
              </div>
            ) : null
          )}

        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.end_goal.value}</span>
              <Tooltip title={formFields.end_goal.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('end_goal', {
            rules: [{ required: true, message: 'Please input end goal' }]
          })(<Input.TextArea autoSize />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.problem_statement.value}</span>
              <Tooltip title={formFields.problem_statement.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('problem_statement', {
            rules: [
              { required: true, message: 'Please input problem statement' }
            ]
          })(<Input.TextArea autoSize />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.learner_role.value}</span>
              <Tooltip title={formFields.learner_role.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('learner_role', {
            rules: [{ required: true, message: 'Please input learner role' }]
          })(<Input.TextArea autoSize />)}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.depth.value}</span>
              <Tooltip title={formFields.depth.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('depth', {
            rules: [{ required: true, message: 'Please select depth' }],
            initialValue: 2 // Set default value
          })(
            <Select>
              {[2, 3, 4, 5, 6, 7, 8].map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={
            <span className="ai-maze-form-label">
              <span>{formFields.no_of_options.value}</span>
              <Tooltip title={formFields.no_of_options.description}>
                <Icon type="info-circle" />
              </Tooltip>
            </span>
          }
          colon={false}
          required={false}
        >
          {getFieldDecorator('number_of_options', {
            rules: [
              { required: true, message: 'Please select number of options' }
            ],
            initialValue: 2 // Set default value
          })(
            <Select>
              {[2, 3, 4].map(value => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            shape="round"
            size="large"
            type="primary"
            onClick={handleOk}
            loading={isUploading || isGenerating}
          >
            {isUploading
              ? 'Uploading...'
              : isGenerating
              ? 'Generating...'
              : 'Generate Maze'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

// Wrap AiMazeGeneration component with Form.create() to access form methods via props
const WrappedAiMazeGeneration = Form.create({ name: 'ai_maze_generation' })(
  AiMazeGeneration
)

export default WrappedAiMazeGeneration
