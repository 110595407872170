import React, { Component, Fragment } from 'react'

import MazeCreateTopPanel from '../Create/components/MazeTopPanel'
import MazeCreateRoutes from '../Create/routes'
const mazeFieldMatrix = maze => {
  const {
    id,
    title,
    topics,
    description,
    author,
    minimum_pass_score,
    cover_id,
    cover,
    contents,
    background_image,
    background_image_data,
    avatar_voice,
    start_date_time,
    end_date_time,
    badge_id,
    certificate_id,
    marketplace_option,
    marketplace,
    host_emails
  } = maze

  return {
    mazeId: id,
    mazeTitle: title,
    mazeOrganiser: author,
    mazeTags: JSON.parse(topics) || [],
    mazeImg: (cover && cover.link) || '',
    mazeImgId: cover_id,
    mazeDescription: description || '',
    mazePassScore: minimum_pass_score,
    mazeContent: contents,
    mazeBackground: background_image_data,
    mazeBackgroundId: background_image,
    mazeVoice: avatar_voice,
    mazeStartDate: start_date_time,
    mazeExpDate: end_date_time,
    mazeMarketplaceOption: marketplace_option,
    mazeBadgeId: badge_id,
    mazeCertId: certificate_id,
    mazeHostEmails: host_emails || [],
    ...(marketplace && {
      mazeCategory: marketplace.category_id,
      mazeIsFree: marketplace.is_free,
      mazePrice: marketplace.price,
      mazeVideoLink: marketplace.video_link,
      mazeOrganization: marketplace.organization_id,
      mazeGroup: marketplace.group_id,
      mazeObjectives: marketplace.course_obj,
      mazeOutline: marketplace.course_outline,
      mazeMarketPlaceDescription: marketplace.course_description,
      mazeShareProfile: marketplace.is_shared_profile
    })
  }
}

class MazeEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: true
    }
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      },
      getMazeById,
      mazeBuilderActions: { handleMazeInput }
    } = this.props

    await handleMazeInput('mazeId', id)

    getMazeById(id)
      .then(response => {
        const data = mazeFieldMatrix(response.data)
        Object.keys(data).forEach(key => handleMazeInput(key, data[key]))
      })
      .catch(err => console.log('ERROR IN EDITING', err))
      .finally(() => this.setState({ fetching: false }))
  }

  render() {
    const {
      match: {
        params: { id }
      }
    } = this.props

    const mode = 'edit'
    return (
      <Fragment>
        <MazeCreateTopPanel mode={mode} id={id} />
        <MazeCreateRoutes mode={mode} id={id} fetching={this.state.fetching} />
      </Fragment>
    )
  }
}

export default MazeEdit
