import React, { useEffect, useState } from 'react'
import Unity, { UnityContext } from 'react-unity-webgl'

const unityContext = new UnityContext({
  loaderUrl: '/uBuild/Build.loader.js',
  dataUrl: '/uBuild/Build.data',
  frameworkUrl: '/uBuild/Build.framework.js',
  codeUrl: '/uBuild/Build.wasm'
})

function UnityView(props) {
  const [error, setError] = useState(null)

  console.log('UnityView props: ', props)

  const sendMessageToUnity = (gameObject, method, parameter) => {
    try {
      unityContext.send(gameObject, method, parameter)
    } catch (error) {
      console.error(`Error sending message to Unity: ${error}`)
      setError(`Error sending message to Unity: ${error}`)
    }
  }

  useEffect(() => {
    if (props.activateTTS) {
      console.log('props.activateTTS: ', props.activateTTS)
      sendMessageToUnity('_ManageJSON', 'LoadUnity', '')
    }
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveURL', props.baseURL)
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveToken', props.token)
    sendMessageToUnity('_GetTokenAndUserID', 'ReceiveUserId', props.mazeID)
    if (props.avatarGender === 'male') {
      sendMessageToUnity(
        '_GetTokenAndUserID',
        'ReceiveAssetBundle',
        'https://mj-scorm-bucket.s3.amazonaws.com/urbanman_v5'
      )
    } else {
      sendMessageToUnity(
        '_GetTokenAndUserID',
        'ReceiveAssetBundle',
        'https://mj-scorm-bucket.s3.amazonaws.com/femaleofficecharacter_v4'
      )
    }
    sendMessageToUnity(
      '_GetTokenAndUserID',
      'ReceiveGoogleTextToVoiceName',
      props.avatarVoice
    )
    sendMessageToUnity(
      '_GetTokenAndUserID',
      'ReceiveGener',
      props.avatarGender
    )
  }, [props])

  useEffect(() => {
    unityContext.on('loaded', () => {
      console.log('Loaded')
      props.isBuildLoadedFunc(unityContext)
    })

    unityContext.on('progress', progression => {
      console.log('Percentage: ', progression * 100)
      props.isPercentageLoader(progression * 100)
    })

    unityContext.on('error', message => {
      console.error('Unity error: ', message)
      setError(message)
    })

    return () => {
      unityContext.removeAllEventListeners()
    }
  }, [props])

  return (
    <>
      {error && (
        <div style={{ color: 'red', textAlign: 'center' }}>
          An error occurred: {error}
        </div>
      )}

      <Unity
        unityContext={unityContext}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    </>
  )
}

export default UnityView
