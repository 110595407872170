import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Spin, Button, Icon, Modal, Alert, Card, Popover } from 'antd'

import './styles.scss'
import Template from './Template'
import MazeTree from './MazeTree'
import ErrorModal from './ErrorModal'
import { getNestedChildren } from './mazeHelper'

import HideIcon from '../../../../../assets/hide_icon.svg'
import LeftBar from '../../../../../assets/left_arrow_rect.png'
import RightBar from '../../../../../assets/right_arrow_rect.png'
import ChoiceModal from './ChoiceModal'
import Quotes from '../../../Detail/components/SentenceLoader'
import loadingLogo from '../../../Detail/assets'

const TYPES = { SCENARIO: 'Scenario', OPTION: 'Option' }

const defaultState = {
  layerIndex: 1,
  scenarioIndex: 0,
  lastScenarioId: null,
  showChoiceModal: false,
  optionIndex: 0,
  currentTemplateIndex: 0,
  lastScenarioTemplateIndex: 0,
  children: [],
  showLeftPanel: true,
  showRightPanel: true,
  isScenarioActive: false,
  params: [],
  options: [],
  data: [],
  voices: [],
  isLayerMax: false,
  mazeScenarioLoading: true,
  isMazeError: false,
  idealPath: [],
  error: {
    textError: true,
    imageError: true,
    scoreError: true,
    idealPathError: true
  },
  scenarioParents: [],
  zoom: 0.25,
  scenarios: []
}

class MazeScenario extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    this.setupScenario()
  }

  setupScenario = async () => {
    const { fetching } = this.props
    if (!fetching) {
      // await getWorkshopCourses({ limit: null })
      setTimeout(() => this.checkLayersAndOptions(), 100)
    } else {
      setTimeout(() => this.setupScenario(), 100)
    }
  }

  addScenario = async scenarioData => {
    const {
      currentTemplateIndex,
      scenarioIndex,
      lastScenarioId,
      voices
    } = this.state
    const { t } = this.props
    const OPTION_INDEX = 0
    const newScenarioId = lastScenarioId + 1

    var isOptionChecked = {
      one: false,
      two: false
    }

    if (scenarioData) {
      var dataOpt1 = scenarioData[1]
      var dataOpt2 = scenarioData[2]

      var valueOpt1 = dataOpt1 && dataOpt1.values
      var valueOpt2 = dataOpt2 && dataOpt2.values

      if (valueOpt1 && valueOpt1.hasOwnProperty('is_ideal_option'))
        isOptionChecked.one = valueOpt1.is_ideal_option
      if (valueOpt2 && valueOpt2.hasOwnProperty('is_ideal_option'))
        isOptionChecked.two = valueOpt2.is_ideal_option
    }

    this.setState(
      {
        children: [
          ...this.state.children,
          <Template
            t={t}
            key={currentTemplateIndex + 1}
            isScenario={true}
            templateId={currentTemplateIndex + 1}
            scenarioIndex={scenarioIndex + 1}
            onDelete={this.deleteTemplate}
            lastScenarioIndex={newScenarioId}
            onTemplateValue={this.handleTemplateValues}
            data={scenarioData && scenarioData[0]}
            voices={voices}
          />,
          <Template
            t={t}
            key={currentTemplateIndex + 2}
            isScenario={false}
            templateId={currentTemplateIndex + 2}
            scenarioIndex={scenarioIndex + 1}
            optionIndex={OPTION_INDEX + 1}
            onDelete={this.deleteTemplate}
            lastScenarioIndex={newScenarioId}
            onTemplateValue={this.handleTemplateValues}
            data={scenarioData && scenarioData[1]}
            voices={voices}
            isOptionChecked={isOptionChecked.one}
            showDeadEndOption={true}
            isDeadEndOption={dataOpt1 ? dataOpt1.is_dead_end : false}
            handleDeadEnd={this.handleDeadEnd}
            handleScenarioLink={this.handleScenarioLink}
          />,
          <Template
            t={t}
            key={currentTemplateIndex + 3}
            templateId={currentTemplateIndex + 3}
            isScenario={false}
            scenarioIndex={scenarioIndex + 1}
            optionIndex={OPTION_INDEX + 2}
            onDelete={this.deleteTemplate}
            lastScenarioIndex={newScenarioId}
            onTemplateValue={this.handleTemplateValues}
            data={scenarioData && scenarioData[2]}
            voices={voices}
            isOptionChecked={isOptionChecked.two}
            showDeadEndOption={true}
            isDeadEndOption={dataOpt2 ? dataOpt2.is_dead_end : false}
            handleDeadEnd={this.handleDeadEnd}
            handleScenarioLink={this.handleScenarioLink}
          />
        ].map(item => (
          <Template
            key={item.key}
            {...item.props}
            scenarios={[...this.state.scenarios, scenarioIndex + 1]}
          />
        )),
        scenarios: [...this.state.scenarios, scenarioIndex + 1],
        optionIndex: OPTION_INDEX + 2
      },
      () => {
        console.log('on add scenario', this.state.scenarios)
        this.reRenderTemplates(newScenarioId)
        this.setTreeData(TYPES.SCENARIO, scenarioData, newScenarioId)
      }
    )
  }

  setOptionObject = (val, obj) => ({
    id: val,
    nodeToDraw: true,
    is_dead_end: obj ? obj.is_dead_end : false
  })

  addOption = async optionData => {
    const {
      currentTemplateIndex,
      scenarioIndex,
      optionIndex,
      lastScenarioId,
      voices,
      scenarios
    } = this.state
    if (optionIndex === 4) return
    const { t } = this.props

    var isChecked = false
    const data = optionData && optionData[0]

    if (data && data.values && data.values.hasOwnProperty('is_ideal_option')) {
      isChecked = data.values.is_ideal_option
    }

    this.setState(
      {
        children: [
          ...this.state.children,
          <Template
            t={t}
            key={currentTemplateIndex + 1}
            isScenario={false}
            templateId={currentTemplateIndex + 1}
            scenarioIndex={scenarioIndex}
            optionIndex={optionIndex + 1}
            onDelete={this.deleteTemplate}
            lastScenarioIndex={lastScenarioId}
            lastTemplateIndex={currentTemplateIndex + 1}
            onTemplateValue={this.handleTemplateValues}
            voices={voices}
            data={optionData && optionData[0]}
            isOptionChecked={isChecked}
            showDeadEndOption={true}
            isDeadEndOption={data ? data.is_dead_end : false}
            scenarios={scenarios}
            handleDeadEnd={this.handleDeadEnd}
            handleScenarioLink={this.handleScenarioLink}
          />
        ],
        optionIndex: optionIndex + 1,
        options: [
          ...this.state.options,
          this.setOptionObject(currentTemplateIndex + 1)
        ]
      },
      () => {
        this.reRenderTemplates(lastScenarioId, currentTemplateIndex + 1)
        this.setTreeData(TYPES.OPTION, optionData)
      }
    )
  }

  setTreeData = (type, dataArr, newScenarioId) => {
    const {
      scenarioParents,
      currentTemplateIndex,
      scenarioIndex,
      lastScenarioTemplateIndex
    } = this.state

    if (type === TYPES.SCENARIO) {
      var arr = []
      const index = currentTemplateIndex
      const parentForScenario =
        // scenarioParents === []
        scenarioParents.length === 0
          ? null
          : scenarioParents.find(
              item => item.nodeToDraw === true && item.is_dead_end === false
            )
      const id = parentForScenario && parentForScenario.id

      const scenario = this.getObject(
        TYPES.SCENARIO,
        index,
        id,
        false,
        scenarioIndex + 1
      )
      arr.push(scenario)

      const option1 = this.getObject(
        TYPES.OPTION,
        index + 1,
        index + 1,
        dataArr && dataArr[1],
        1
      )
      const option2 = this.getObject(
        TYPES.OPTION,
        index + 2,
        index + 1,
        dataArr && dataArr[2],
        2
      )
      arr.push(option1, option2)

      const myData = Array.isArray(dataArr) ? dataArr : arr

      const parents = this.state.scenarioParents.map(item => {
        if (item.id === id) {
          return {
            ...item,
            nodeToDraw: false
          }
        } else return item
      })
      this.setState(
        {
          data: [...this.state.data, ...myData],
          currentTemplateIndex: index + 3,
          scenarioIndex: scenarioIndex + 1,
          lastScenarioId: newScenarioId,
          scenarioDeleted: false,
          lastScenarioTemplateIndex: index + 1,
          scenarioParents: [
            ...parents,
            this.setOptionObject(index + 2, dataArr && dataArr[1]),
            this.setOptionObject(index + 3, dataArr && dataArr[2])
          ]
        },
        () => this.checkDeadEnd()
      )
    } else if (type === TYPES.OPTION) {
      const options = this.state.data.filter(
        item => item.parent === lastScenarioTemplateIndex
      )

      const data = this.getObject(
        TYPES.OPTION,
        currentTemplateIndex,
        lastScenarioTemplateIndex,
        dataArr && dataArr[0],
        options && options.length + 1
      )

      const myData = Array.isArray(dataArr) ? dataArr : [data]
      this.setState(
        {
          data: [...this.state.data, ...myData],
          currentTemplateIndex: currentTemplateIndex + 1,
          scenarioParents: [
            ...this.state.scenarioParents,
            this.setOptionObject(
              currentTemplateIndex + 1,
              dataArr && dataArr[0]
            )
          ]
        },
        () => this.checkDeadEnd()
      )
    }
  }

  checkDeadEnd = () => {
    this.setState(
      {
        scenarioParents: this.state.scenarioParents.map(item => {
          if (this.state.data.find(el => el.parent === item.id)) {
            return { ...item, nodeToDraw: false }
          } else return item
        })
      },
      () => {
        this.setState({
          children: this.state.children.map(item => {
            if (
              this.state.scenarioParents.find(
                el => el.id === item.key && el.nodeToDraw === false
              )
            ) {
              return (
                <Template
                  key={item.key}
                  {...item.props}
                  showDeadEndOption={false}
                />
              )
            } else return item
          })
        })
      }
    )
  }

  getObject = (name, index, parentIndex, isDeadEnd, typeIndex) => ({
    code: index + 1,
    type: `${name}`,
    ...(name === 'Option' && { is_dead_end: isDeadEnd || false }),
    parent: parentIndex,
    index: typeIndex,
    values: {}
  })

  handleTemplateValues = (name, value, id, scenarioIndex = null) => {
    const { data } = this.state
    var parentId = null

    console.log('template value', name, value, id)

    // edit value in of the specific template
    const arr = data.map(item => {
      if (item.code === id) {
        parentId = item.parent
        // multiple image selection
        if (name == 'images') {
          let modalObj = item.values
          if (item.values.hasOwnProperty('model_id'))
            delete item.values.model_id
          if (item.values.hasOwnProperty('model_obj'))
            delete item.values.model_obj

          return {
            ...item,
            values: {
              ...modalObj,
              images: [...(item.values.images || []), value]
            }
          }
        }

        //  when 3d model is selected
        if (name == 'model_id') {
          let modalObj = item.values
          if (item.values.hasOwnProperty('images')) delete item.values.images
          if (item.values.hasOwnProperty('image_list'))
            delete item.values.image_list

          return {
            ...item,
            values: {
              ...modalObj,
              model_id: value
            }
          }
        }

        // when image is deleted while multiple image selection
        if (name == 'delImage') {
          return {
            ...item,
            values: {
              ...item.values,
              images: item.values.images.filter(item => item !== value),
              ...(item.values.image_list && {
                image_list: item.values.image_list.filter(
                  item => item.id !== value
                )
              })
            }
          }
        }
        return { ...item, values: { ...item.values, [name]: value } }
      }
      return item
    })

    // uncheck values for other options of the same scenario
    if (name === 'is_ideal_option' && value === true) {
      var dataArr = arr.map(item => {
        if (item.code !== id && item.parent === parentId) {
          const data = {
            ...item,
            values: { ...item.values, is_ideal_option: !value }
          }

          this.setState({
            children: this.state.children.map(child => {
              if (
                child.props &&
                child.props.scenarioIndex === scenarioIndex &&
                child.props.templateId !== id
              ) {
                return (
                  <Template
                    key={child.key}
                    {...child.props}
                    isOptionChecked={false}
                  />
                )
              } else if (
                child.props &&
                child.props.scenarioIndex === scenarioIndex
              ) {
                return (
                  <Template
                    key={child.key}
                    {...child.props}
                    isOptionChecked={true}
                  />
                )
              } else {
                return child
              }
            })
          })
          return data
        }
        return item
      })
    } else if (name === 'is_ideal_option')
      this.setState({
        children: this.state.children.map(child => {
          if (child.props.templateId === id) {
            return (
              <Template
                key={child.key}
                {...child.props}
                isOptionChecked={value}
              />
            )
          } else return child
        })
      })
    this.setState(
      { data: name === 'is_ideal_option' && value === true ? dataArr : arr },
      () => {
        console.log('template value data', this.state.data)
        this.handleSubmit()
      }
    )
  }

  checkValues = () => {
    const arr = this.state.data
    const isTextError = arr.filter(item => item.values && !item.values.content)
    const isScoreError = arr.filter(
      item => item.type === 'Scenario' && item.values && !item.values.score
    )
    const isImageError = arr.filter(
      item =>
        item.type === 'Scenario' &&
        item.values &&
        (item.values.images
          ? item.values.images.length === 0
          : item.values.model_obj == {} ||
            !item.values.hasOwnProperty('model_id'))
    )

    const textError = isTextError.length !== 0
    const scoreError = isScoreError.length !== 0
    const imageError = isImageError.length !== 0
    this.setState(
      {
        error: {
          textError,
          scoreError,
          imageError,
          idealPathError: true
        },
        isMazeError: textError || scoreError || imageError
      },
      () => {
        this.getIdealPath()
      }
    )
  }

  checkIdealCondition = arr => {
    arr.forEach(item => {
      if (item && item.values.is_ideal_option) {
        const scenario = item.children
        this.setState(
          {
            idealPath: [...this.state.idealPath, item]
          },
          () => {
            if (scenario.length === 0) {
              this.checkTreeContainsIdealPath()
              return
            }
            const scenarioChildren = scenario[0].children
            this.setState(
              {
                idealPath: [...this.state.idealPath, scenario[0]]
              },
              () => this.checkIdealCondition(scenarioChildren)
            )
          }
        )
      }
    })

    if (!arr.some(item => item.values.is_ideal_option === true)) {
      this.checkTreeContainsIdealPath()
    }
  }

  checkTreeContainsIdealPath = () => {
    const { isMazeError } = this.state
    const idealPathErrorCondtion =
      this.state.idealPath.length % 2 !== 0 ||
      this.state.idealPath.filter(
        item =>
          item.type === 'Option' && item.children && item.children.length === 0
      ).length === 0

    if (!idealPathErrorCondtion && !isMazeError) {
      this.goToTraining()
    } else {
      this.setState({
        error: {
          ...this.state.error,
          idealPathError: idealPathErrorCondtion
        },
        isMazeError: true
      })
    }
  }

  getIdealPath = () => {
    const { data } = this.state
    var nest = getNestedChildren(data)
    const JSON = nest[0]
    if (JSON.code === 1) {
      const children = JSON.children
      delete JSON.children
      this.setState(
        {
          idealPath: [JSON]
        },
        () => this.checkIdealCondition(children)
      )
    }
  }

  handleSubmit = async () => {
    const {
      mazeBuilder: {
        mazeImgId,
        mazeTitle,
        mazePassScore,
        mazeOrganiser,
        mazeBackgroundId,
        mazeTags,
        mazeId,
        mazeVoice
      },
      updateMaze,
      mazeBuilderActions: { handleMazeInput }
    } = this.props

    const { data } = this.state

    const mazeData = {
      title: mazeTitle,
      minimum_pass_score: mazePassScore,
      avatar_voice: mazeVoice,
      author: mazeOrganiser,
      cover_id: mazeImgId,
      background_image: mazeBackgroundId,
      topics: JSON.stringify(mazeTags),
      contents: data
    }

    updateMaze({
      id: mazeId,
      data: mazeData
    })
      .then(res => {
        const updatedContents = res.data.contents
        handleMazeInput('mazeContent', updatedContents)
      })

      .catch(error => <Alert type="error" message={error} />)
  }

  checkLayersAndOptions = () => {
    const {
      mazeBuilder: { mazeLayers, mazeOptions, mazeContent }
    } = this.props

    console.log({
      mazeLayers,
      mazeOptions,
      mazeContent
    })

    if (mazeContent && mazeContent.length > 0) {
      const content = mazeContent.sort((a, b) => a.code - b.code)
      this.onEdit(content, false)
    } else if (mazeLayers && mazeOptions) {
      this.addCustomLayers()
    } else {
      this.setState({ mazeScenarioLoading: false, showChoiceModal: true })
    }
  }

  addCustomLayers = async () => {
    const {
      mazeBuilder: { mazeLayers, mazeOptions }
    } = this.props

    var layerLastIndex = 1
    var layerIndex = 1
    for (let i = 1; i >= 1; i++) {
      if (i === layerLastIndex * mazeOptions + 1) {
        layerLastIndex = i
        layerIndex++
      }
      await this.addScenario()
      if (mazeOptions > 2) {
        await this.addOption()
        if (mazeOptions === 4) {
          await this.addOption()
        }
      }

      if (layerIndex >= mazeLayers) {
        this.setState({
          mazeScenarioLoading: false,
          isLayerMax: layerIndex === 8
        })
        break
      }
    }
  }

  goBack = () => {
    const {
      history,
      mazeBuilder: { mazeId }
    } = this.props
    history.push(`/maze/${mazeId}/edit`)
  }

  toggleLeftPanel = () => {
    this.setState(
      state => ({
        showLeftPanel: !state.showLeftPanel
      }),
      () => {
        if (!this.state.showLeftPanel) {
          this.setState({ showRightPanel: true })
        }
      }
    )
  }

  toggleRightPanel = () => {
    this.setState(
      state => ({
        showRightPanel: !state.showRightPanel
      }),
      () => {
        if (!this.state.showRightPanel) {
          this.setState({ showLeftPanel: true })
        }
      }
    )
  }

  deleteTemplate = params => {
    const { isScenario, scenarioIndex, templateId } = params
    const { scenarioParents, scenarios } = this.state
    const filteredChildren = this.state.children.filter(item => {
      if (isScenario) {
        return item.props.scenarioIndex !== scenarioIndex
      } else {
        return item.props.templateId !== templateId
      }
    })

    var options = []

    if (isScenario) {
      var optionsToDelete = this.getNumberOfOptions(templateId)
      const optionArr =
        scenarioParents &&
        scenarioParents
          .reverse()
          .slice(optionsToDelete, scenarioParents.length)
          .reverse()

      var count = 0
      options = optionArr
        .reverse()
        .map(item => {
          if (item.nodeToDraw === false && count === 0) {
            count = 1
            return { ...item, nodeToDraw: true }
          } else return item
        })
        .reverse()
    } else {
      options = [...scenarioParents]
      options.pop()
    }

    const index = this.state.data.findIndex(item => item.code === templateId)
    const itemsArr = [...this.state.data].slice(0, index)

    var lastTemplate = filteredChildren[filteredChildren.length - 1]

    var lastScenarioTemplate = filteredChildren
      .reverse()
      .find(item => item.props.isScenario === true)

    var filteredScenarios = isScenario
      ? scenarios.filter(item => item !== scenarioIndex)
      : scenarios

    this.setState(
      {
        scenarioIndex: isScenario ? scenarioIndex - 1 : scenarioIndex,
        lastScenarioId: isScenario ? scenarioIndex - 1 : scenarioIndex,
        children: filteredChildren
          .reverse()
          .map(item => (
            <Template
              key={item.key}
              {...item.props}
              lastScenarioIndex={isScenario ? scenarioIndex - 1 : scenarioIndex}
              lastTemplateIndex={lastTemplate.props.templateId}
              scenarios={filteredScenarios}
            />
          )),
        lastScenarioTemplateIndex: isScenario
          ? lastScenarioTemplate.props.templateId
          : this.state.lastScenarioTemplateIndex,
        optionIndex: lastTemplate.props.optionIndex,
        data: itemsArr,
        currentTemplateIndex: lastTemplate.props.templateId,
        scenarioDeleted: isScenario,
        scenarioParents: options,
        scenarios: filteredScenarios
      },
      () => this.handleSubmit()
    )
  }

  onEdit = async (data, isSubmit = false) => {
    var parent = null
    var count = 0
    for (let i = 0; i < data.length; i++) {
      const element = data[i]
      if (element.type === 'Scenario') {
        count = 0
        const totalScenarioData = [data[i], data[i + 1], data[i + 2]]
        await this.addScenario(totalScenarioData)
        parent = element.code
      } else if (element.type === 'Option') {
        if (element.parent === parent) {
          count = count + 1
          if (count === 3 || count === 4) {
            const optionData = [data[i]]
            await this.addOption(optionData)
          }
        }
      }
      if (i === data.length - 1) {
        this.setState({ mazeScenarioLoading: false, data }, () => {
          if (isSubmit) this.handleSubmit()
        })
      }
    }
  }

  getNumberOfOptions = index => {
    const data = this.state.data.filter(item => item.parent === index)
    return data.length
  }

  reRenderTemplates = (id, lastTemplateIndex) => {
    const children = this.state.children.map(item => (
      <Template
        key={item.key}
        {...item.props}
        lastScenarioIndex={id}
        lastTemplateIndex={lastTemplateIndex || ''}
      />
    ))
    this.setState({ children })
  }

  goToTraining = () => {
    const {
      history,
      mazeBuilder: { mazeId }
    } = this.props
    history.push(`/maze/${mazeId}/edit/publish`)
  }

  goToTemplate = code => {
    const el = document.getElementById(`maze-template-${code}`)
    // el.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'center',
    //   inline: 'center'
    // })

    console.log('Select', code, el)
    if (el) {
      const container = document.querySelector('.create-maze__left-contents')
      container.scrollTop = el.offsetTop - el.getBoundingClientRect().height

      el.classList.remove('maze-template-blink')

      setTimeout(function() {
        el.classList.add('maze-template-blink')
      }, 10)
    }
  }

  handleDeadEnd = (code, isUnmark = false) => {
    const parents = this.state.scenarioParents.map(item => {
      if (item.id === code) {
        return { ...item, is_dead_end: isUnmark ? false : true }
      } else return item
    })

    const data = this.state.data.map(item => {
      if (item.code === code) {
        return { ...item, is_dead_end: isUnmark ? false : true }
      } else return item
    })

    this.setState(
      {
        scenarioParents: parents,
        data,
        children: this.state.children.map((item, index) => (
          <Template {...item.props} key={item.key} data={data[index]} />
        ))
      },
      () => {
        this.handleSubmit()
        this.checkDeadEnd()
      }
    )
  }

  handleScenarioLink = (linkedScenarioId, templateId) => {
    this.setState(
      {
        data: this.state.data.map(item => {
          if (item.code === templateId) {
            return { ...item, possible_scenario_code: linkedScenarioId }
          }
          return item
        })
      },
      () => this.handleSubmit()
    )
  }

  closeChoiceModal = () => {
    this.setState({ showChoiceModal: false }, () => this.addScenario())
  }

  onMazeGenerated = dataArray => {
    this.setState(
      {
        ...defaultState,
        showChoiceModal: false,
        mazeScenarioLoading: true
      },
      () => {
        this.onEdit(dataArray, true)
      }
    )
  }

  render() {
    const {
      t,
      maze: { updatingMaze },
      fetching
    } = this.props
    const {
      data,
      children,
      isLayerMax,
      isMazeError,
      showLeftPanel,
      showRightPanel,
      scenarioParents,
      showChoiceModal,
      mazeScenarioLoading
    } = this.state

    const isNodeToDraw = this.state.scenarioParents.find(
      item => item.nodeToDraw && !item.is_dead_end
    )

    const legend = (
      <>
        <div className="maze-tree-wrapper__legend__container">
          <div className="maze-tree-wrapper__legend ">
            <div className="maze-tree-wrapper__legend__type">
              Tree Node Type
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape blue"></div>
              <span>Correct Option or Scenario</span>
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape green"></div>
              <span>Incorrect Option</span>
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape white"></div>
              <span>Option or Scenario without any text in it</span>
            </div>

            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__shape red"></div>
              <span>Dead End (Red Border)</span>
            </div>
          </div>

          <div className="maze-tree-wrapper__legend">
            <div className="maze-tree-wrapper__legend__type">Paths</div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__path blue">
                &#8594;
              </div>
              <span>Correct Path</span>
            </div>
            <div className="maze-tree-wrapper__legend__item">
              <div className="maze-tree-wrapper__legend__item__path black">
                &#8594;
              </div>
              <span>
                Incorrect Path / Path where correct option is not selected.
              </span>
            </div>
          </div>
        </div>

        <br />

        <Alert
          type="info"
          message="When you double-click on a tree node, the text editor will automatically scroll to display the content of that specific node in the view."
        />
      </>
    )

    if (!fetching && showChoiceModal) {
      return (
        <ChoiceModal
          onClose={this.closeChoiceModal}
          onMazeGenerated={this.onMazeGenerated}
        />
      )
    }

    if (fetching)
      return <Spin className="maze__loader" tip="Fetching data..." />

    if (mazeScenarioLoading)
      return (
        <div className="maze__loader">
          <Alert
            type="info"
            message="Please wait while we are generating the maze."
          />
          <hr />
          <img
            alt=""
            id="shl-animate-logo"
            className="shl-animate-logo"
            src={loadingLogo}
          />
          <Quotes />
        </div>
      )

    return (
      <>
        <section className="section">
          <div className="create-maze">
            <div
              className={`${
                !showRightPanel && showLeftPanel
                  ? 'show__left'
                  : showLeftPanel
                  ? ''
                  : 'hide__panel'
              } create-maze__left `}
            >
              <div className="hide__map" onClick={this.toggleLeftPanel}>
                <img alt="" src={HideIcon} />
                Hide
              </div>
              <div className="create-maze__left-contents">
                {children.map(item => item)}
                <div className="maze__action">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.addScenario}
                    disabled={isLayerMax || !isNodeToDraw}
                  >
                    Add Scenario
                  </Button>
                  <Button
                    type="default"
                    shape="round"
                    onClick={this.addOption}
                    disabled={this.state.optionIndex === 4 || isLayerMax}
                  >
                    Add Option
                  </Button>
                </div>
              </div>
            </div>
            {!showLeftPanel && (
              <img
                alt=""
                className="hide_bar"
                src={LeftBar}
                onClick={this.toggleLeftPanel}
              />
            )}
            <div
              className={`${
                !showLeftPanel && showRightPanel
                  ? 'show__right'
                  : showRightPanel
                  ? ''
                  : 'hide__panel'
              } create-maze__right `}
            >
              <div className="hide__map" onClick={this.toggleRightPanel}>
                <img alt="" src={HideIcon} />
                Hide
              </div>

              <MazeTree
                {...this.props}
                data={data}
                showRightPanel={showRightPanel}
                goToTemplate={this.goToTemplate}
                markDeadEnd={this.handleDeadEnd}
                unmarkDeadEnd={code => this.handleDeadEnd(code, true)}
              />

              <div className="maze-tree-wrapper__bottom">
                <Popover content={legend} title="Legend" trigger="click">
                  <Icon type="info" />
                </Popover>
              </div>
            </div>
            {!showRightPanel && (
              <img
                alt=""
                className="hide_bar"
                src={RightBar}
                onClick={this.toggleRightPanel}
              />
            )}
          </div>
        </section>

        <div className="p-footer">
          <div className="p-footer__inner p-footer__inner_justify">
            <div className="p-footer__col">
              <div className="p-footer__item">
                <Button className="rounded" onClick={this.goBack}>
                  Back
                </Button>
              </div>
            </div>
            <div className="p-footer__col">
              <div className="p-footer__item">
                <Button
                  className="rounded"
                  loading={updatingMaze}
                  onClick={this.handleSubmit}
                >
                  {t('buttons:save_for_later')}{' '}
                </Button>
              </div>
              <div className="p-footer__item">
                <Button
                  type="primary"
                  className="rounded"
                  onClick={this.checkValues}
                >
                  {t('buttons:publish')}
                  <Icon type="right" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        {isMazeError && (
          <Modal
            className="ant-error-modal"
            visible={isMazeError}
            onCancel={() => this.setState({ isMazeError: false })}
            footer={false}
            centered={true}
          >
            <ErrorModal error={this.state.error} />
          </Modal>
        )}
      </>
    )
  }
}

export default withTranslation('mazescenario')(MazeScenario)
