import React, { useState } from 'react'
import { api } from '../../../services'
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Spin
} from 'antd'
import { useTranslation } from 'react-i18next'
import './AiCourseForm.scss'

const FormItem = Form.Item
const RadioGroup = Radio.Group
const { TextArea } = Input

const defaultErrorObj = {
  hasError: false,
  msg: ''
}

const AiCourseForm = ({
  onFormSubmit,
  loading,
  courseTitle = '',
  isFromKnowledgeCenter,
  ...props
}) => {
  const [isStandardRequired, setIsStandardRequired] = useState(false)
  const [isIndustrySpecific, setIsIndustrySpecific] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(defaultErrorObj)

  const onStandardChange = e => {
    setIsStandardRequired(e.target.value === 'yes')
  }

  const onIndustryChange = e => {
    setIsIndustrySpecific(e.target.value === 'yes')
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        setIsSubmitting(true)
        const {
          courseTitle,
          learningObjective,
          standardDetails,
          standard,
          industry,
          industrySpecific,
          duration
        } = values

        api.courses
          .askAiCourseCreation({
            params: {
              prompt: JSON.stringify({
                course_title: courseTitle,
                learning_objective: learningObjective,
                standard_details: standardDetails,
                standard: standard,
                industry: industry,
                industry_specific: industrySpecific,
                duration: duration + ' minutes'
              }),
              context: []
            }
          })
          .then(res => {
            setIsSubmitting(false)
            onFormSubmit(values, res.data.body[0])
            setError(defaultErrorObj)
          })
          .catch(err => {
            setIsSubmitting(false)
            setError({ hasError: true, msg: err.message })
          })
      }
    })
  }

  const { getFieldDecorator } = props.form
  const { t } = useTranslation()

  return (
    <Spin spinning={loading}>
      {!isFromKnowledgeCenter && (
        <div className="course-creation-ai-modal__header">
          <h2>{t('v3:ai_course_creation')}</h2>
        </div>
      )}

      <Form
        className="ai-course-form"
        layout="vertical"
        onSubmit={handleSubmit}
      >
        <FormItem label={t('v3:ai_course_title')}>
          {getFieldDecorator('courseTitle', {
            initialValue: courseTitle,
            rules: [
              { required: true, message: t('v3:ai_course_title_training') }
            ]
          })(<Input placeholder={t('v3:ai_course_title_training')} />)}
        </FormItem>

        <FormItem label={t('v3:learning_objective_label')}>
          {getFieldDecorator('learningObjective', {
            rules: [
              { required: true, message: t('v3:learning_objective_message') }
            ]
          })(<TextArea placeholder={t('v3:ai_course_object')} />)}
        </FormItem>

        <FormItem label={t('v3:standard_question')}>
          {getFieldDecorator('standard', {
            initialValue: 'no'
          })(
            <RadioGroup onChange={onStandardChange}>
              <Radio value="yes">{t('general:yes')}</Radio>
              <Radio value="no">{t('general:no')}</Radio>
            </RadioGroup>
          )}
        </FormItem>

        {isStandardRequired && (
          <FormItem label={t('v3:standard_details_label')}>
            {getFieldDecorator('standardDetails', {
              rules: [
                { required: true, message: t('v3:standard_details_message') }
              ]
            })(<TextArea />)}
          </FormItem>
        )}

        {/* <Divider /> */}

        <FormItem
          className="ai-course-form__duration"
          label={t('v3:estimated_duration_label')}
        >
          {getFieldDecorator('duration', {
            rules: [
              { required: true, message: t('v3:estimated_duration_message') },
              {
                type: 'number',
                min: 5,
                message: t('v3:min_duration_message')
              }
            ]
          })(<InputNumber min={1} placeholder={t('v3:ai_mins')} />)}

          {/* <span></span> */}
        </FormItem>

        {/* <Divider /> */}

        <FormItem label={t('v3:industry_specific_question')}>
          {getFieldDecorator('industrySpecific', {
            initialValue: 'no'
          })(
            <RadioGroup onChange={onIndustryChange}>
              <Radio value="yes">{t('general:yes')}</Radio>
              <Radio value="no">{t('general:no')}</Radio>
            </RadioGroup>
          )}
        </FormItem>

        {isIndustrySpecific && (
          <FormItem label={t('v3:industry_label')}>
            {getFieldDecorator('industry', {
              rules: [{ required: true, message: t('v3:industry_message') }]
            })(<Input />)}
          </FormItem>
        )}

        {error.hasError && (
          <Alert
            message={error.msg}
            type="error"
            showIcon
            style={{ marginTop: 20 }}
          />
        )}

        {/* <Divider /> */}

        <div className="ai-course-form__submit">
          <FormItem>
            <Button
              size="large"
              shape="round"
              loading={isSubmitting}
              htmlType="submit"
              className="shl-primary-btn"
            >
              {t('v2:button_submit')}
            </Button>
          </FormItem>
        </div>
      </Form>
    </Spin>
  )
}

const WrappedAiCourseForm = Form.create({ name: 'ai_course_form' })(
  AiCourseForm
)

export default WrappedAiCourseForm
