import http, { httpShareBotInstance } from './http'

function generateImage(data) {
  return http({
    method: 'POST',
    url: '/midjourney/imagine',
    data
  })
}

function generateVariation(data) {
  return http({
    method: 'POST',
    url: '/midjourney/variation',
    data
  })
}

function upscaleImage(data) {
  return http({
    method: 'POST',
    url: '/midjourney/upscale',
    data
  })
}

function conversationAI(data) {
  return httpShareBotInstance({
    method: 'POST',
    url: '/conversationalai',
    data
  })
}

export { generateImage, upscaleImage, generateVariation, conversationAI }
