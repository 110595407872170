import React, { Component } from 'react'
import { isSafari } from 'react-device-detect'
import './Audio.scss'
import { AudioPlayer } from '../../../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Audio/components'
//import iconPlay from './images/icon-play.png'
//import iconPause from './images/icon-pause.png'

export default class extends Component {
  /*state = {
    isPlaying: false
  }*/

  //audio = new window.Audio()

  /*componentDidMount () {
    const { node: { params } } = this.props

    this.audio.src = params.content
    this.audio.addEventListener('ended', this.stop)
  }*/

  /*stop = () => {
    this.audio.currentTime = 0
    this.pause()
  }

  play = () => {
    this.setState({
      isPlaying: true
    }, () => {
      this.audio.play()
    })
  }

  pause = () => {
    this.audio.pause()

    this.setState({
      isPlaying: false
    })
  }

  componentWillUnmount () {
    this.pause()
  }*/

  render() {
    const {
      elementHeight,
      elementWidth,
      node: { params }
    } = this.props

    //const { isPlaying } = this.state
    //const image = isPlaying ? iconPause : iconPlay

    return (
      <div
        /*className="b-audio"*/
        /*onClick={() => isPlaying ? this.pause() : this.play()}*/
        /* style={{ marginTop: '79px', marginLeft: '43px' }}*/
      >
        {AudioPlayer({
          width: (elementWidth && isSafari ? elementWidth : params.width),
          height: (elementHeight ? elementHeight : params.height),
          src: params.content
        })}
      </div>
    )
  }
}
