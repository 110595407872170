import React, { Component, createRef } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Form,
  Icon,
  Input,
  InputNumber,
  Button,
  Spin,
  Modal,
  Card,
  message
} from 'antd'
import { withTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import { arrayMove } from 'react-sortable-hoc'
import { DropzoneScormLoading } from '../../internal/CourseDropzone/styled'

import notification from '../../../../../views/Library/components/notification'
import api from '../../../../../services/api'
import { config, general } from '../../../../../constants'
import './CourseCreateMain.scss'

import Badge from './components/Badge'
import Certificate from './components/Certificate'
import Dropzone from 'react-dropzone'
import { EditImageContainer } from '../../../../../components/TextOnImage'
import { Row, Col } from '../../../../../styles'
import { FormHeader } from './components'
import { getSession } from '../../../../../services/api/user'
import ImageUpload from '../../../../../components/Core/ImageUpload/ImageUpload'
import ImageUploadModal from '../../../../../components/Core/ImageUpload/ImageUploadModal'
class CourseCreateMain extends Component {
  constructor(props) {
    super(props)
    this.getStatus = null
    this.getScanStatus = null
    this.state = {
      isCover: false,
      isMarketPlace: false,
      isCoverLoading: props.courseId && true,
      isFinishing: false,
      options: [],
      value: [],
      group: null,
      inputValue: '',
      isTopicLoading: false,
      image: null, // your-image-url or as base64
      file: null,
      isTopicsMax: false,
      newImageUrl: null,
      showTextModal: false,
      selectedContent: '',
      content: '',
      contentHTML: '',
      textPosition: {
        height: 200,
        width: 250,
        x: 23,
        y: 100
      },
      applyChanges: false,
      isImageLoading: false,
      canSendToMarketPlace: false,
      isCertificateLoading: false,
      isBadgeLoading: false,
      isFileLoading: false,
      openBadgeModal: false,
      openCertificateModal: false,
      uploadedFile: {},
      fileUploaded: false,
      fileUploadPercent: 0,
      filefetchPercent: 0,
      fileextractPercent: 0,
      isCancel: false,
      showFetchProgress: false,
      showUploadProgress: false,
      showExtractProgress: false,
      sessionToken: '',
      isScanning: false,
      isCheckingScorm: false,
      scanStatusMsg: '',
      scanStatus: '',
      checkScormStatusMsg: '',
      checkScormStatus: ''
    }
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.imageRef = createRef()
  }

  componentDidMount() {
    const {
      courseBuilder: { courseId },
      match: {
        params: { id }
      }
    } = this.props

    if (id || courseId) {
      // getLessonsList(id || courseId)
      api.courses.getCourseById(id || courseId).then(response => {
        const {
          data: { topics }
        } = response
        this.getOldTopics(topics)
        this.getScormFile(id || courseId)
      })
    }
    getSession()
      .then(session => {
        this.setState({ sessionToken: 'Bearer ' + session.idToken.jwtToken })
      })
      .catch(err => {
        console.log('error', err)
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const { showTextModal, contentHTML } = this.state
      const div = document.getElementById('showtext')
      if (!showTextModal && contentHTML && div) {
        div.innerHTML = contentHTML
        return div
      }
    }
  }

  handleValue = (field, value) => {
    const { courseBuilderActions } = this.props
    courseBuilderActions.handleCourseInput(field, value)
    this.handleSubmit()
  }

  handleTitle = _.debounce(v => this.handleValue('courseTitle', v), 1000)
  handleCourseType = _.debounce(v => this.handleValue('courseType', v), 1000)
  handleCategory = _.debounce(v => this.handleValue('courseCategory', v), 1000)
  handleSwitch = _.debounce(v => this.handleValue('courseIsFree', v), 1000)
  handlePrice = _.debounce(v => this.handleValue('coursePrice', v), 1000)
  handleVideoLink = _.debounce(
    v => this.handleValue('courseVideoLink', v),
    1000
  )
  handleNumberOfLessons = _.debounce(
    v => this.handleValue('courseNoOfLessons', v),
    1000
  )
  handleNumberOfVideos = _.debounce(
    v => this.handleValue('courseNoOfVideos', v),
    1000
  )
  handleOrganization = _.debounce(
    v => this.handleValue('courseOrganization', v),
    1000
  )
  handleGroup = _.debounce(v => this.handleValue('courseGroup', v), 1000)

  handleOrganiser = _.debounce(
    v => this.handleValue('courseOrganiser', v),
    1000
  )
  handleTopics = _.debounce(v => this.handleValue('courseTags', v), 1000)
  handleDescription = _.debounce(
    v => this.handleValue('courseDescription', v),
    1000
  )
  handleMarketPlaceDescription = _.debounce(
    v => this.handleValue('courseMarketPlaceDescription', v),
    1000
  )
  handleObjectives = _.debounce(
    v => this.handleValue('courseObjectives', v),
    1000
  )
  handleOutline = _.debounce(v => this.handleValue('courseOutline', v), 1000)
  handlePassScore = _.debounce(
    v => this.handleValue('coursePassScore', v),
    1000
  )

  // onDropAccepted = () => {
  //   this.setState({ isCoverLoading: true })
  // }

  handleGroupSelect = val => {
    this.handleGroup(val)
    this.setState({ group: val })
  }

  onDrop = (file, handleAfterUpload, handlebeforeUpload) => {
    const image = URL.createObjectURL(file)
    this.handleAfterUpload = handleAfterUpload
    this.handlebeforeUpload = handlebeforeUpload
    this.setState({
      file,
      image
    })
  }

  useCanva = type => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: type
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle, type)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title, imageType) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title,
      size: blob.size,
      type: blob.type
    }
    if (imageType === 'Presentation') {
      this.handleUploadImage(body)
    } else {
      this.badgePresentationUpload(body, imageType)
    }
  }

  badgePresentationUpload = (body, imageType) => {
    const { upload } = this.props
    this.toggleLoadingState(imageType)
    this.setState({ openBadgeModal: false, openCertificateModal: false })
    upload(body)
      .then(res => {
        const { id } = res
        this.toggleLoadingState(imageType)
        this.handleRewardImageUpload(imageType, id)
      })
      .catch(() => {
        this.toggleLoadingState(imageType)
        message.error('Error occured. Try Again !')
      })
  }

  handleRewardImageUpload = (type, id) => {
    const { courseBuilderActions } = this.props
    if (type === 'Certificate') {
      courseBuilderActions.handleCourseInput('courseCertId', id)
    } else {
      courseBuilderActions.handleCourseInput('courseBadgeId', id)
    }
    this.handleSubmit()
  }

  toggleLoadingState = type => {
    if (type === 'Certificate') {
      this.setState(state => ({
        isCertificateLoading: !state.isCertificateLoading
      }))
    } else {
      this.setState(state => ({ isBadgeLoading: !state.isBadgeLoading }))
    }
    // } else if (type === 'ZipFile') {
    //   this.setState(state => ({ isFileLoading: !state.isFileLoading }))
  }

  deleteLesson = (e, lessonId) => {
    e.stopPropagation()

    const {
      courseBuilderActions: { deleteLessonFromCourse },
      courseBuilder: { courseId }
    } = this.props

    deleteLessonFromCourse(courseId, lessonId)
  }

  handleSubmit = e => {
    e && e.preventDefault()
    const { value, group } = this.state
    const {
      form,
      courseBuilderActions: { updateCourse, createCourse },
      courseBuilder: {
        courseImgId,
        courseId,
        isCreation,
        coursePassScore,
        courseCertId,
        courseBadgeId
      },
      user: {
        info: { username }
      }
    } = this.props
    form.validateFields((err, values) => {
      if (!courseImgId) {
        this.setState({ isCover: true })
        return
      }

      if (isCreation) {
        return
      }

      if (coursePassScore < 1 || coursePassScore > 99) {
        return
      }

      const courseData = {
        ...values,
        cover_id: courseImgId,
        cert_id: courseCertId,
        badge_id: courseBadgeId,
        course_type: 'scorm'
      }
      courseData.topics = _.map(value, 'id')
      courseData.group_id = group

      if (courseData.category_id) {
        this.setState({
          canSendToMarketPlace: true
        })
      }

      if (!courseData.title || !courseData.cover_id) return

      this.handleCourseType('scorm')
      if (courseId) {
        const {
          category_id,
          cover_id,
          group_id,
          is_free,
          cert_id,
          badge_id,
          no_of_lesson,
          no_of_video,
          organiser,
          organization_id,
          price,
          description,
          title,
          topics,
          trainer,
          video_link,
          course_obj,
          course_outline,
          course_description,
          pass_score
        } = courseData

        let newCourseData = {
          title: title,
          organiser: organiser,
          cover_id: cover_id,
          topics: topics,
          cert_id: cert_id,
          badge_id: badge_id,
          pass_score: pass_score,
          description: description,
          course_type: 'scorm',
          ...((category_id ||
            trainer ||
            is_free ||
            price ||
            video_link ||
            no_of_video ||
            no_of_lesson ||
            organization_id ||
            group_id ||
            course_obj ||
            course_outline ||
            course_description) && {
            marketplace: {
              trainer: trainer,
              username: username,
              category_id: category_id,
              is_free: is_free,
              price: Number(price),
              video_link: video_link,
              no_of_video: Number(no_of_video),
              no_of_lesson: Number(no_of_lesson),
              organization_id: organization_id,
              group_id: group_id,
              course_obj: course_obj,
              course_outline: course_outline,
              course_description: course_description
            }
          })
        }
        const debouncedUpdate = _.debounce(() => {
          updateCourse(newCourseData, courseId)
        }, 500)

        debouncedUpdate()
      } else {
        createCourse(courseData)
      }
    })
  }

  showErrorMessageForMarketPlace = () => {
    const {
      courseBuilder: {
        courseId,
        courseTitle,
        courseImgId,
        courseOrganiser,
        courseCategory,
        courseIsFree,
        courseMarketPlaceDescription,
        courseNoOfLessons,
        courseNoOfVideos,
        courseObjectives,
        courseOrganization,
        courseOutline,
        coursePrice,
        courseGroup,
        courseVideoLink,
        coursePassScore
      },
      t,
      user: {
        info: { isAdmin }
      }
    } = this.props

    if (!isAdmin) return
    if (!courseId) return

    let data = {
      courseId,
      courseTitle,
      courseImgId,
      courseOrganiser,
      courseCategory,
      courseIsFree,
      courseMarketPlaceDescription,
      courseNoOfLessons,
      courseNoOfVideos,
      courseObjectives,
      courseOrganization,
      courseOutline,
      coursePrice,
      courseGroup,
      courseVideoLink,
      coursePassScore
    }

    let errorData = Object.keys(data).filter(k => {
      if (data[k] === '' || data[k] === undefined || data[k] === null) {
        return true
      } else {
        return false
      }
    })

    if (errorData.length) {
      message.warning(t('v4:not_complete'))
    } else {
      message.success(t('v4:complete'))
    }
  }

  finish = isPublish => {
    const { value } = this.state
    const {
      form,
      courseBuilder: {
        courseImgId,
        courseId,
        isCreation,
        courseCertId,
        courseBadgeId
      },
      history,
      rootPath
    } = this.props

    form.validateFields((err, values) => {
      if (isCreation) {
        return
      }

      const courseData = {
        ...values,
        cover_id: courseImgId,
        cert_id: courseCertId,
        badge_id: courseBadgeId,
        course_type: 'scorm',
        is_finished: true
      }
      courseData.topics = _.map(value, 'id')

      this.setState({ isFinishing: true })
      return api.courses.courseUpdate(courseData, courseId).then(() => {
        this.setState({ isFinishing: false })
        if (isPublish) {
          history.push(`${rootPath}/publish`)
        } else {
          history.push('/workshop')
        }
      })
    })
  }

  showLessonById = id => {
    const { fetchLessonById, history, rootPath } = this.props

    fetchLessonById(id)
    history.push(`${rootPath}/lesson-create/${id}`)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const {
      courseBuilder: { lessons, courseId },
      reorderLessons
    } = this.props
    const newLessons = arrayMove(lessons.list, oldIndex, newIndex)
    const newOrderIds = newLessons.map(el => el.id)

    if (reorderLessons) {
      reorderLessons(newLessons)
    }

    api.courses.updateLessonsOrderByCourseId(courseId, newOrderIds)
  }

  getOldTopics = topics => {
    const {
      topics: { list: topicList, isFetching }
    } = this.props
    if (!isFetching) {
      const topic = topics.map(item =>
        _.head(topicList.filter(topic => topic.id === item))
      )
      const newValue = topic.map(item => item && this.createOption(item))
      this.setState({
        value: newValue
      })
    }
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleSubmit()
          this.handleValue('courseTags', _.map(newValue, 'id'))
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 5) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleSubmit()
          this.handleValue('courseTags', _.map(newValue, 'id'))
        }
      )
    }
    this.isModalSelectOpen()
  }

  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 5) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.handleSubmit()
              this.handleValue('courseTags', _.map([...value, newOption], 'id'))
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  handleUploadImage = file => {
    const { upload, courseBuilderActions } = this.props
    this.handlebeforeUpload && this.handlebeforeUpload()
    this.setState({
      isCoverLoading: true,
      openBadgeModal: false
    })
    return upload(file)
      .then(res => {
        const { id, link } = res
        courseBuilderActions.handleCourseInput('courseImgId', id)
        courseBuilderActions.handleCourseInput('courseImg', link)
        this.handleImageSuccess(res)
      })
      .catch(() => {
        this.handleAfterUpload && this.handleAfterUpload()
      })
  }

  // handleFileInsert = item => {
  //   const imageEditorInst = this.imageRef.current.imageEditorInst
  //   const isEdited = !imageEditorInst.isEmptyUndoStack()
  //   if (isEdited === true) {
  //     const data = imageEditorInst.toDataURL()
  //     const { courseBuilderActions } = this.props
  //     const { id } = item
  //     courseBuilderActions.handleCourseInput('courseImgId', id)
  //     const body = {
  //       croppedImage: data,
  //       lastModifiedDate: new Date(),
  //       name: item.filename,
  //       size: item.file_size,
  //       type: item.media_mime_type
  //     }
  //     this.setState(
  //       {
  //         isImageLoading: true,
  //         modalOpen: false,
  //         selectedItem: null
  //       },
  //       () => {
  //         this.handleUploadImage(body)
  //       }
  //     )
  //     courseBuilderActions.handleCourseInput('courseImg', data)
  //   } else {
  //     const { courseBuilderActions } = this.props
  //     const { id, link } = item
  //     courseBuilderActions.handleCourseInput('courseImgId', id)
  //     courseBuilderActions.handleCourseInput('courseImg', link)
  //     this.setState(
  //       {
  //         isImageLoading: true,
  //         modalOpen: false,
  //         selectedItem: null
  //       },
  //       () => {
  //         this.handleImageSuccess(item)
  //       }
  //     )
  //   }
  // }
  handleFileInsert = item => {
    const imageEditorInst = this.imageRef.current
      ? this.imageRef.current.imageEditorInst
      : null

    // Check if imageEditorInst exists before accessing its properties
    const isEdited = imageEditorInst
      ? !imageEditorInst.isEmptyUndoStack()
      : false

    if (isEdited) {
      // Upload the edited image
      const data = imageEditorInst.toDataURL()
      const { courseBuilderActions } = this.props
      const { id } = item

      // Set courseImgId and courseImg in courseBuilder state
      courseBuilderActions.handleCourseInput('courseImgId', id)
      courseBuilderActions.handleCourseInput('courseImg', data)

      const body = {
        croppedImage: data,
        lastModifiedDate: new Date(),
        name: item.filename,
        size: item.file_size,
        type: item.media_mime_type
      }

      this.setState(
        {
          isImageLoading: true,
          modalOpen: false,
          selectedItem: null
        },
        () => {
          // Upload the edited image
          this.handleUploadImage(body)
        }
      )
    } else {
      // Use the original image URL
      const { courseBuilderActions } = this.props
      const { id, link } = item

      // Set courseImgId and courseImg in courseBuilder state
      courseBuilderActions.handleCourseInput('courseImgId', id)
      courseBuilderActions.handleCourseInput('courseImg', link)

      this.setState(
        {
          isImageLoading: true,
          modalOpen: false,
          selectedItem: null
        },
        () => {
          // Process the original image URL
          this.handleImageSuccess(item)
        }
      )
    }
  }

  handleImageSuccess = res => {
    const { id } = res

    if (id) {
      this.setState(
        {
          isCover: false,
          isCoverLoading: false,
          file: null,
          image: null,
          newImageUrl: null,
          showTextModal: false,
          selectedContent: '',
          content: '',
          contentHTML: '',
          textPosition: {
            height: 200,
            width: 250,
            x: 23,
            y: 100
          },
          applyChanges: false,
          isImageLoading: false
        },
        () => {
          this.handleAfterUpload && this.handleAfterUpload()
        }
      )
    }
    this.handleSubmit()
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
      this.handleAfterUpload()
    }
  }

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  hideModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  handleShowTextModal = () => {
    this.setState({ showTextModal: !this.state.showTextModal })
  }

  onToggleMarketPlace = () => {
    this.setState({ isMarketPlace: !this.state.isMarketPlace }, () => {
      this.showErrorMessageForMarketPlace()
    })
  }

  toggleBadgeModal = () => {
    this.setState(state => ({ openBadgeModal: !state.openBadgeModal }))
  }

  toggleCertificateModal = () => {
    this.setState(state => ({
      openCertificateModal: !state.openCertificateModal
    }))
  }

  onDrop = (file, handleAfterUpload, handlebeforeUpload) => {
    const image = URL.createObjectURL(file)
    this.handleAfterUpload = handleAfterUpload
    this.handlebeforeUpload = handlebeforeUpload
    this.setState({
      file,
      image
    })
  }

  getCancelToken = () => {
    const CancelToken = axios.CancelToken
    return CancelToken.source()
  }

  handleCancelUpload = () => {
    const { uploadedFile } = this.state
    console.log('file uploaded to cnancle', uploadedFile)
    if (uploadedFile.source) {
      // cancel the request (the message parameter is optional)
      uploadedFile.source.cancel()
    }
  }

  onDropFile = dropped => {
    const file = dropped[0]
    if (!file) {
      return
    }
    const {
      courseBuilder: { courseId }
    } = this.props

    return this.setState(
      {
        uploadedFile: file,
        isFileLoading: true,
        isCancel: false,
        showFetchProgress: true,
        checkScormStatus: '',
        scanStatus: ''
      },
      () => {
        let scormKey = ''
        const source = this.getCancelToken()
        // const opts = {
        //   headers: { 'Content-Type': 'application/json', 'Authorization': this.state.sessionToken },
        //   onUploadProgress: (progressEvent) => {
        //     if (progressEvent.lengthComputable) {
        //       let percent = (progressEvent.loaded / progressEvent.total * 100).toFixed(0);
        //       this.setState({ showFetchProgress: true, filefetchPercent: percent })
        //     }
        //   }
        // }
        // api.scorm.postScormCourseById(`${config.baseURL}scorm/course/${courseId}/signedurl`, { filename: file.name }, opts)
        api.scorm
          .postScormCourseById(courseId, file)
          .then(({ data }) => {
            scormKey = data.key
            const newFile = file
            newFile.source = source
            if (data.link) {
              axios({
                method: 'put',
                url: data.link,
                data: file,
                headers: { 'Content-Type': 'application/x-zip-compressed' },
                cancelToken: source.token, //Cancel event
                onUploadProgress: progressEvent => {
                  // Upload Progress Bar Event
                  if (progressEvent.lengthComputable) {
                    let val = (
                      (progressEvent.loaded / progressEvent.total) *
                      100
                    ).toFixed(0)
                    this.setState({
                      showFetchProgress: false,
                      filefetchPercent: 0,
                      showUploadProgress: true,
                      fileUploadPercent: parseInt(val),
                      uploadedFile: newFile
                    })
                  }
                }
              })
                .then(response => {
                  console.log('after file upload', response)
                  if (response.status === 200) {
                    const payload = { key: scormKey }
                    // const options = {
                    //   headers: { 'Content-Type': 'application/json', 'Authorization': this.state.sessionToken },
                    //   onUploadProgress: (progressEvent) => {
                    //     if (progressEvent.lengthComputable) {
                    //       let percent = (progressEvent.loaded / progressEvent.total * 100).toFixed(0);
                    //       this.setState({showFetchProgress: false, showUploadProgress: false, fileUploadPercent: 0, showExtractProgress: true, fileextractPercent: percent})
                    //     }
                    //   }
                    // }
                    this.setState(
                      {
                        showUploadProgress: false,
                        fileUploadPercent: 0,
                        showExtractProgress: true
                      },
                      () => {
                        // api.scorm.postScormFile(`${config.baseURL}scorm/extract`, payload, options)
                        this.postScormFile(payload)
                      }
                    )
                  }
                })
                .catch(err => {
                  console.log('err catch', err)
                  if (axios.isCancel(err)) {
                    message.error('File upload cancelled.')
                    this.setState({
                      showFetchProgress: false,
                      showUploadProgress: false,
                      showExtractProgress: false,
                      fileUploadPercent: 0,
                      isCancel: true,
                      isFileLoading: false,
                      fileUploaded: false,
                      uploadedFile: ''
                    })
                  }
                })
            }
          })
          .catch(err => {
            console.log('err catch', err)
            this.setState({ fileextractPercent: 0, showExtractProgress: false })
          })
      }
    )
  }

  postScormFile = data => {
    api.scorm
      .postScormFile(data)
      .then(resp => {
        if (resp.status === 200) {
          // this.setState({ isFileLoading: false, fileUploaded: true, showExtractProgress: false, fileextractPercent: 0 }, () => {
          this.setState(
            {
              showExtractProgress: false,
              fileextractPercent: 0,
              isScanning: true,
              isCheckingScorm: true
            },
            () => {
              this.getStatus = setInterval(() => {
                this.getScormStatus(this.props.courseBuilder.courseId)
              }, 2000)
              this.getScanStatus = setInterval(() => {
                this.getScormScanStatus(this.props.courseBuilder.courseId)
              }, 2000)
              const media_format = this.state.uploadedFile.type.split('/')[0]
              const custom_title = this.state.uploadedFile.name
              const display_title = `Your ${media_format.toLowerCase()} file ${custom_title} have completed uploading.`
              notification(media_format, 'success', display_title, 600)
            }
          )
        }
      })
      .catch(err => {
        console.log('err catch', err)
        this.setState({ fileextractPercent: 0, showExtractProgress: false })
      })
  }

  getScormStatus = courseId => {
    api.scorm
      .getScormStatus(courseId)
      .then(response => {
        if (response.data && response.data.status) {
          if (
            response.data.status === 'unsupported_version' ||
            response.data.status === 'not_scorm_standard' ||
            response.data.status === 'error' ||
            response.data.status === 'completed'
          ) {
            clearInterval(this.getStatus)
            this.getStatus = null
            this.setState(state => ({
              fileUploaded:
                state.scanStatus === 'clean' &&
                response.data.status === 'completed'
                  ? true
                  : false,
              isFileLoading: state.scanStatus ? false : true,
              isCheckingScorm: false,
              checkScormStatus: response.data.status
              // checkScormStatusMsg: response.data.status === 'completed'
              //   ? 'File check succeeded.'
              // : 'Your uploaded file is Not SCORM 1.2 standard, Please upload file again.',
            }))
            return
          }

          this.setState({
            isCheckingScorm: false,
            checkScormStatus: response.data.status
            // checkScormStatusMsg: 'File checking in progress...'
          })
        }
      })
      .catch(err => {
        console.log('response err', err)
      })
  }

  getScormScanStatus = courseId => {
    api.scorm
      .getScormScanStatus(courseId)
      .then(response => {
        if (response.data && response.data.status) {
          if (
            response.data.status === 'clean' ||
            response.data.status === 'infected'
          ) {
            clearInterval(this.getScanStatus)
            this.getScanStatus = null
            this.setState(state => ({
              fileUploaded:
                state.checkScormStatus === 'completed' &&
                response.data.status === 'clean'
                  ? true
                  : false,
              isFileLoading: state.checkScormStatus ? false : true,
              isScanning: false,
              scanStatus: response.data.status
              // scanStatusMsg: response.data.status === 'clean'
              //   ? 'Your file is free from virus.'
              //   : 'Your file is infected. Try to upload other file.'
            }))
            return
          }

          this.setState({
            isScanning: false,
            scanStatus: response.data.status
            // scanStatusMsg: 'Scanning your uploaded file. Wait'
          })
        }
      })
      .catch(err => {
        console.log('response err', err)
      })
  }

  getScormFile = id => {
    api.scorm
      .getScormByCourseId(id)
      .then(response => {
        if (
          response.status === 200 &&
          response.data.id &&
          response.data.status === 'completed'
        ) {
          this.setState({ fileUploaded: true })
        }
      })
      .catch(err => console.log('error', err))
  }

  render() {
    const {
      form: { getFieldDecorator },
      courseBuilder: {
        courseTitle,
        courseOrganiser,
        courseImg,
        courseImgId,
        courseId,
        coursePassScore,
        courseBadgeId,
        courseCertId
      },
      course: { isFetching },
      user: {
        info: {
          isAdmin,
          active_organization_id: activeOrgId,
          is_super_admin: isSuperAdmin
        }
      },
      t
    } = this.props

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }

    const customStyles = {
      control: provided => ({
        ...provided,
        borderRadius: '0px',
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
        marginBottom: isTopicsMax ? '0px' : '24px'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }

    const {
      isCover,
      isCoverLoading,
      isFinishing,
      value,
      options,
      isTopicLoading,
      isTopicsMax,
      image,
      file,
      modalOpen,
      selectedItem,

      isImageLoading,
      isBadgeLoading,
      isFileLoading,
      isCertificateLoading,
      openBadgeModal,
      uploadedFile,
      openCertificateModal,
      fileUploaded,
      showFetchProgress,
      showUploadProgress,
      showExtractProgress,
      fileUploadPercent,
      isCancel,
      isScanning,
      scanStatus,
      isCheckingScorm,
      checkScormStatus
    } = this.state
    console.log('log percent here', this.props, 'passscore', coursePassScore)

    let scanCSS = {}
    if (scanStatus === 'clean') {
      scanCSS = {
        color: 'white',
        backgroundColor: 'green',
        width: '70%',
        padding: '6px'
      }
    } else if (scanStatus === 'infected') {
      scanCSS = {
        color: 'white',
        backgroundColor: 'red',
        width: '70%',
        padding: '6px'
      }
    } else {
      scanCSS = { width: '70%', padding: '6px' }
    }

    let fileCheckCSS = {}
    if (checkScormStatus === 'completed') {
      fileCheckCSS = {
        color: 'white',
        backgroundColor: 'green',
        width: '70%',
        padding: '6px'
      }
    } else if (
      checkScormStatus === 'unsupported_version' ||
      checkScormStatus === 'not_scorm_standard' ||
      checkScormStatus === 'error'
    ) {
      fileCheckCSS = {
        color: 'white',
        backgroundColor: 'red',
        width: '70%',
        padding: '6px'
      }
    } else {
      fileCheckCSS = { width: '70%', padding: '6px' }
    }

    return (
      <Spin className="global_ant_spin" spinning={isFetching}>
        <section className="section" style={{ paddingBottom: '10rem' }}>
          <FormHeader
            courseId={courseId}
            t={t}
            hide
            isAdmin={isAdmin}
            // isMarketPlace={isMarketPlace}
            // onToggleMarketPlace={this.onToggleMarketPlace}
          />
          {!courseId && message.warning(t('v4:scorm_not_complete'))}
          <Form onSubmit={this.handleSubmit}>
            <div className="create-course">
              <div className="create-course__content">
                {/* marker */}

                <Row>
                  <Col md={6}>
                    <Form.Item
                      label={t('labels:title')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('title', {
                        rules: [
                          {
                            required: true,
                            message: t('errors:title_course')
                          },
                          {
                            whitespace: true,
                            pattern: '.{3,}',
                            message: t('errors:min_3char')
                          }
                        ],
                        initialValue: courseTitle
                      })(
                        <Input
                          size="large"
                          placeholder={t('placeholders:title')}
                          maxLength={100}
                          onChange={e => this.handleTitle(e.target.value)}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col md={6}>
                    <Form.Item
                      label={t('labels:organiser')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('organiser', {
                        initialValue: courseOrganiser
                      })(
                        <Input
                          addonBefore={t('general:by')}
                          size="large"
                          tighter="true"
                          placeholder={t('placeholders:organiser')}
                          onChange={e => this.handleOrganiser(e.target.value)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
                      {t('labels:topics').toUpperCase()}
                    </div>
                    <CreatableSelect
                      styles={customStyles}
                      components={{
                        ClearIndicator: null,
                        DropdownIndicator: null,
                        LoadingIndicator: this.LoadingIndicator
                      }}
                      isMulti
                      placeholder=""
                      inputId="react-select-2-input"
                      isLoading={isTopicLoading}
                      menuIsOpen={this.isModalSelectOpen()}
                      onBlur={() => this.setState({ inputValue: '' })}
                      onChange={this.handleChange}
                      onCreateOption={this.handleCreate}
                      onInputChange={this.loadOptions}
                      options={options}
                      value={value}
                    />

                    {isTopicsMax && (
                      <div className="error_message">
                        {t('errors:topics_max')}
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      label={t('v4:pass_score')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('pass_score', {
                        initialValue: coursePassScore
                        // rules: [
                        //   {
                        //     validator: (rule, value, cb) =>
                        //       value > 0 && value <= 100,
                        //     message: ('v4:pass_score_mark')
                        //   }
                        // ]
                      })(
                        <InputNumber
                          size="large"
                          tighter="true"
                          style={{ width: '100%' }}
                          disabled={coursePassScore === undefined}
                          onChange={value => this.handlePassScore(value)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="canva-editor-items">
                      <Button
                        className="item"
                        shape="round"
                        icon="pic-right"
                        onClick={this.toggleCertificateModal}
                        loading={isCertificateLoading}
                        style={{
                          backgroundColor: courseCertId ? 'green' : null,
                          color: courseCertId ? 'white' : null
                          // pointerEvents: courseCertId ? 'none' : 'all'
                        }}
                      >
                        {courseCertId
                          ? t('v4:certificate_added')
                          : t('v4:create_certificate')}
                        {courseCertId && <Icon type="check" />}
                      </Button>
                      <Button
                        className="item"
                        icon="security-scan"
                        onClick={this.toggleBadgeModal}
                        shape="round"
                        loading={isBadgeLoading}
                        style={{
                          backgroundColor: courseBadgeId ? 'green' : null,
                          color: courseBadgeId ? 'white' : null
                          // pointerEvents: courseBadgeId ? 'none' : 'all'
                        }}
                      >
                        {courseBadgeId
                          ? t('v4:badge_added')
                          : t('v4:create_badge')}
                        {courseBadgeId && <Icon type="check" />}
                      </Button>

                      {/* <span className="text">
                        <Icon type="warning" />{' '}
                        {t('v4:certificate_warning')}
                      </span> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Form.Item
                      label={t('labels:picture')}
                      colon={false}
                      required={false}
                      className="no_bottom_margin"
                    >
                      <ImageUpload
                        cover={courseImgId && courseImg}
                        error={isCover ? 1 : 0}
                        disabled={isCoverLoading}
                        loadImage={isImageLoading || isCoverLoading}
                        handleDrop={this.onDrop}
                        handleOpenSelectLibraryModal={e => {
                          e.stopPropagation()
                          this.setState({
                            modalOpen: true
                          })
                        }}
                        // handleAiImage={this.handleFileInsert}
                        handleAiImage={({ id, link }) =>
                          this.handleImageUrl(
                            link,
                            'ai_generated_image',
                            'Presentation'
                          )
                        }
                        handleOpenCanva={e => {
                          e.stopPropagation()
                          this.useCanva('Presentation')
                        }}
                      />
                      {/* <CourseDropzone
                        onDrop={this.onDrop}
                        cover={courseImgId && courseImg}
                        error={isCover ? 1 : 0}
                        disabled={isCoverLoading}
                        app={this}
                        loadImage={isImageLoading || isCoverLoading}
                      /> */}
                    </Form.Item>
                  </Col>
                  <Col md={4}>
                    <Form.Item
                      label={t('v4:scorm_zip')}
                      colon={false}
                      required={false}
                      className="no_bottom_margin"
                    >
                      <Dropzone
                        accept={'application/zip, application/x-zip-compressed'}
                        multiple={false}
                        onDrop={this.onDropFile}
                        disabled={
                          !courseId ||
                          showFetchProgress ||
                          showUploadProgress ||
                          showExtractProgress
                        }
                      >
                        {() => {
                          return isFileLoading ? (
                            <DropzoneScormLoading type="loading" />
                          ) : (
                            <div className="wrap_upload_buttons">
                              <Button
                                className="rounded"
                                size="large"
                                icon="file-text"
                                style={{ top: '60px' }}
                              >
                                {t('media:upload_file')}
                              </Button>
                            </div>
                          )
                        }}
                      </Dropzone>
                      {fileUploaded && (
                        <div>
                          {uploadedFile.name} {t('v3:attach_file_course')}
                        </div>
                      )}
                    </Form.Item>
                    {showFetchProgress && (
                      <div className="upload-file-scorm">
                        <div className="upload-file-scorm__percent-border">
                          <div
                            className={`upload-file-scorm__percent ${
                              isCancel
                                ? 'upload-file-scorm__percent__cancel'
                                : ''
                            }`}
                            style={{ width: 0 + '%' }}
                          />
                          {t('v3:fetch_reques')}
                        </div>
                      </div>
                    )}
                    {showUploadProgress && (
                      <div className="upload-file-scorm">
                        <div className="upload-file-scorm__percent-border">
                          <div
                            className={`upload-file-scorm__percent ${
                              isCancel
                                ? 'upload-file-scorm__percent__cancel'
                                : ''
                            }`}
                            style={{ width: fileUploadPercent + '%' }}
                          />
                          {fileUploadPercent > 0 && (
                            <div>
                              {t('v3:uploading_file')} {fileUploadPercent}%
                            </div>
                          )}
                          {!isCancel && (
                            <Button
                              className="rounded"
                              style={{ margin: '5px' }}
                              role="button"
                              onClick={this.handleCancelUpload}
                            >
                              {t('buttons:cancel')}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                    {showExtractProgress && (
                      <div className="upload-file-scorm">
                        <div className="upload-file-scorm__percent-border">
                          <div
                            className={`upload-file-scorm__percent ${
                              isCancel
                                ? 'upload-file-scorm__percent__cancel'
                                : ''
                            }`}
                            style={{ width: 100 + '%' }}
                          />
                          {t('v3:finilize_upload')}
                        </div>
                      </div>
                    )}
                    {isScanning ? (
                      <div style={{ marginTop: 10, fontSize: 16 }}>
                        {t('v3:scorm_scanned')}
                      </div>
                    ) : (
                      scanStatus && (
                        <>
                          <Card
                            size="small"
                            title={
                              checkScormStatus !== 'unzipping' &&
                              (scanStatus === 'clean' ||
                                scanStatus === 'infected')
                                ? 'Overall Result'
                                : 'Scan and Check in Progress, Please wait...'
                            }
                            style={{ width: 400, marginTop: 30 }}
                          >
                            <h2>{t('v3:scan_status')}</h2>
                            <p style={scanCSS}>
                              {scanStatus === 'clean' ||
                              scanStatus === 'infected'
                                ? `${t('v3:scan_completed')} ${scanStatus}`
                                : t('v3:scan_progress')}
                            </p>
                            <h2>File status:</h2>
                            {isCheckingScorm ? (
                              <p>{t('v3:check_scorm')}</p>
                            ) : (
                              checkScormStatus && (
                                <p style={fileCheckCSS}>
                                  {checkScormStatus === 'completed'
                                    ? t('v3:file_check_success')
                                    : checkScormStatus === 'unzipping' ||
                                      checkScormStatus === 'parsing'
                                    ? t('v3:file_check_progress')
                                    : t('v3:scorm_not_upload')}
                                </p>
                              )
                            )}
                          </Card>
                        </>
                      )
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </section>
        <div className="p-footer">
          <div className="p-footer__inner">
            <div className="p-footer__item">
              <Button className="rounded" onClick={this.handleSubmit}>
                {t('buttons:save_for_later')}{' '}
                {courseId && <span>({t('general:autosaved')})</span>}
              </Button>
            </div>
            <div className="p-footer__item">
              {!activeOrgId || isAdmin || isSuperAdmin ? (
                <Button
                  onClick={() => this.finish(true)}
                  loading={isFinishing}
                  type="primary"
                  className="rounded"
                  disabled={!courseId || !fileUploaded}
                >
                  {t('buttons:publish')}
                  <Icon type="right" />
                </Button>
              ) : (
                <Button
                  onClick={() => this.finish(false)}
                  loading={isFinishing}
                  type="primary"
                  className="rounded"
                  disabled={!courseId || !fileUploaded}
                >
                  {t('buttons:finish')}
                </Button>
              )}
            </div>
          </div>
        </div>

        {image && (
          <EditImageContainer
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
          />
        )}
        {modalOpen && (
          <ImageUploadModal
            modalOpen={modalOpen}
            selectedItem={selectedItem}
            hideModal={this.hideModal}
            imageRef={this.imageRef}
            handleInsertData={this.handleInsertData}
            handleFileInsert={this.handleFileInsert}
            hidePreviewModal={this.hidePreviewModal}
          ></ImageUploadModal>
          // <Modal
          //   visible={modalOpen}
          //   onCancel={this.hideModal}
          //   footer={null}
          //   width={'80%'}
          //   style={modal_style}
          // >
          //   <div className="wrap_modal_tabs">
          //     <PopupMedia
          //       isPageBuilder
          //       handleInsertData={this.handleInsertData}
          //       types="images"
          //     />
          //   </div>
          //   {selectedItem && (
          //     <Modal
          //       visible={selectedItem}
          //       width="70%"
          //       onCancel={this.hidePreviewModal}
          //       footer={null}
          //     >
          //       <Editor library={selectedItem} ref={this.imageRef} />

          //       <div className="wrap_btn_actions">
          //         <Button
          //           type="danger"
          //           size="large"
          //           className="action_button"
          //           onClick={this.hidePreviewModal}
          //         >
          //           {general.CANCEL}
          //         </Button>

          //         <Button
          //           type="primary"
          //           size="large"
          //           className="action_button"
          //           onClick={() => this.handleFileInsert(selectedItem)}
          //         >
          //           {general.INSERT}
          //         </Button>
          //       </div>
          //     </Modal>
          //   )}
          // </Modal>
        )}
        {openBadgeModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openBadgeModal}
            onCancel={this.toggleBadgeModal}
            footer={null}
            width="1229px"
            centered
          >
            <Badge
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Badge')
              }
              {...this.props}
            />
          </Modal>
        )}

        {openCertificateModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openCertificateModal}
            onCancel={this.toggleCertificateModal}
            footer={null}
            centered
            width="1229px"
          >
            <Certificate
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Certificate')
              }
              {...this.props}
            />
          </Modal>
        )}
      </Spin>
    )
  }
}

export default Form.create()(withTranslation('coursecreate')(CourseCreateMain))
