import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { Button, Tabs, Row, Col, Modal, Pagination } from 'antd'
import { general } from '../../../../../../../constants'
import { AudioCard, AudioPopUpCard } from './components'
import { DropzoneAudio, FilterAudio, ShowDetailAudio } from './internal/View'
import Spinner from '../../../../../../../components/Spinner/Spinner'
import { withTranslation } from 'react-i18next'
import className from 'classnames'
import { removeMedias } from '../../../../../../../services/api/library'
import './styles.scss'

class Audio extends Component {
  state = {
    isFetching: true,
    data: {},
    pagePublic: 1,
    pageSizePublic: 9,
    pageSizeOrg: 9,
    publicLibrary: {},
    orgLibrary: {},
    libraryType: general.AUDIO,
    tab: general.MY_MEDIA_LIBRARY,
    isRemovingMedia: false,
    fileNotCompatible: false,
    fileSizeExceed: false,
    cardSelected: '',
    search: '',
    searchPublic: '',
    searchOrg: '',
    sortBy: 'NEWEST',
    page: 1,
    pageOrg: 1,
    pageSize: 6,
    orgMediaLoaded: false,
    publicMediaLoaded: false
  }

  componentDidMount() {
    this.handleGetLibraryList().then(() => {
      //console.log("data", data);
    })
  }

  handleGetLibraryList = async () => {
    const {
      getLibraryList,
      library: { isFetching }
    } = this.props
    const { page, pageSize, libraryType, search, sortBy } = this.state
    this.setState({
      isFetching,
      isRemovingMedia: true
    })
    await getLibraryList({
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      search,
      sort: sortBy,
      type: libraryType
    })

    const {
      library: { data }
    } = this.props
    this.setState({
      data,
      isFetching: false,
      isRemovingMedia: false
    })
  }

  handleGetOrgList = async () => {
    const { getOrgList } = this.props
    const { pageOrg, pageSizeOrg, searchOrg, libraryType, sortBy } = this.state

    await getOrgList({
      offset: this.createOffset(pageOrg, pageSizeOrg),
      limit: pageSizeOrg,
      search: searchOrg,
      sort: sortBy,
      type: libraryType,
      isOrg: true,
      orgMediaLoaded: false
    })

    const { orgLibrary } = this.props.library
    this.setState({
      orgLibrary,
      isFetching: false
    })
  }

  getMediaListing = list => {
    return list ? list.rows : []
  }

  allowUpdateStatus = type => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    if (isSuperAdmin) {
      return type === general.MY_MEDIA_LIBRARY
    }
    return false
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  handleGetPublicMedia = async () => {
    const { getPublicList } = this.props
    const {
      pageSizePublic,
      pagePublic,
      libraryType,
      searchPublic,
      sortBy
    } = this.state

    await getPublicList({
      offset: this.createOffset(pagePublic, pageSizePublic),
      limit: pageSizePublic,
      type: libraryType,
      search: searchPublic,
      sort: sortBy,
      isPublic: true,
      publicMediaLoaded: false
    })

    const {
      library: { publicLibrary }
    } = this.props
    this.setState({
      publicLibrary,
      isFetching: false
    })
  }

  handleChangeTabs = async tab => {
    const { publicMediaLoaded, orgMediaLoaded } = this.state
    this.setState({
      tab,
      cardSelected: ''
    })
    if (tab === general.PUBLIC_MEDIA_LIBRARY && !publicMediaLoaded) {
      this.setState({ isFetching: true })
      await this.handleGetPublicMedia()
      this.setState({
        publicMediaLoaded: true,
        isFetching: false
      })
    } else if (tab === general.ORGANIZATION_LIBRARY && !orgMediaLoaded) {
      this.setState({ isFetching: true })
      await this.handleGetOrgList()
      this.setState({
        orgMediaLoaded: true,
        isFetching: false
      })
    }
  }

  onDrop = dropped => {
    const { upload } = this.props
    if (dropped.length === 0) {
      this.setState({ fileNotCompatible: true })
    } else if (dropped[0].size > 3788888) {
      this.setState({ fileSizeExceed: true })
    } else {
      return upload(dropped[0])
    }
  }

  /**
   * Check upload progress bar status
   */
  checkedCompleteUpload = item => {
    const {
      library: { uploadSuccess }
    } = this.props
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )

    return !(uploadSuccess.length && filterSuccessFiles.length)
  }

  uploadListClassName = list => {
    return list.length <= 0 ? 'upload-modal__list-wrapper__no-border' : ''
  }

  handlePrivateFileSelect = item => {
    const { data } = this.state
    const { rows } = data
    const newRows = rows.map(value => {
      value.isSelected = value.id === item.id
      return value
    })

    return {
      count: data.count,
      rows: newRows
    }
  }

  handlePublicFileSelect = item => {
    const { publicLibrary } = this.state
    const { rows } = publicLibrary
    const newRows = rows.map(value => {
      if (value.id === item.id) {
        value.isSelected = !value.isSelected
      } else {
        value.isSelected = false
      }
      return value
    })

    return {
      count: publicLibrary.count,
      rows: newRows
    }
  }

  handleOrgFileSelect = item => {
    const { orgLibrary } = this.state
    const { rows } = orgLibrary
    const newRows = rows.map(value => {
      if (value.id === item.id) {
        value.isSelected = !value.isSelected
      } else {
        value.isSelected = false
      }
      return value
    })

    return {
      count: orgLibrary.count,
      rows: newRows
    }
  }

  handleViewDetails = (id, item) => {
    console.log('item: ', item)
    console.log('id: ', id)
    const { isPageBuilder, handleInsertData } = this.props
    const { publicLibrary, orgLibrary } = this.state

    if (isPageBuilder && id) {
      const newPrivateMediaData = this.handlePrivateFileSelect(item)
      const newPublicMediaData = !_.isEmpty(publicLibrary)
        ? this.handlePublicFileSelect(item)
        : null
      const newOrgMediaData = !_.isEmpty(orgLibrary)
        ? this.handleOrgFileSelect(item)
        : null

      this.setState(
        {
          data: newPrivateMediaData,
          publicLibrary: newPublicMediaData || null,
          orgLibrary: newOrgMediaData || null,
          cardSelected: id
        },
        () => {
          handleInsertData(
            newPrivateMediaData,
            newPublicMediaData,
            newOrgMediaData
          )
        }
      )
    }
  }

  resetFileInCompatibleMessage = () => {
    this.setState({ fileNotCompatible: false, fileSizeExceed: false })
  }

  handleRemoveAudio = async audioId => {
    this.setState({ isRemovingMedia: true, cardSelected: '' })
    const data = {
      mediaIds: [audioId]
    }
    await removeMedias(data)
    await this.handleGetLibraryList()
    await this.handleGetPublicMedia()
    await this.handleGetOrgList()
  }

  removeCardSelection = () => {
    this.setState({ cardSelected: '' })
  }

  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
        isFetching: true,
        cardSelected: ''
      },
      () => {
        this.handleGetLibraryList()
      }
    )
  }

  onChangePagePublic = (pagePublic, pageSizePublic) => {
    this.setState(
      {
        pagePublic,
        pageSizePublic,
        isFetching: true,
        cardSelected: ''
      },
      () => {
        this.handleGetPublicMedia()
      }
    )
  }

  onChangePageOrg = (pageOrg, pageSizeOrg) => {
    this.setState(
      {
        pageOrg,
        pageSizeOrg,
        isFetching: true
      },
      this.handleGetOrgList
    )
  }

  handlePagination = (type, event) => {
    const { pageSize, pageSizePublic, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return this.onChangePagePublic(event, pageSizePublic)
    } else if (type === general.MY_MEDIA) {
      this.onChangePage(event, pageSize)
    } else {
      return this.onChangePageOrg(event, pageSizeOrg)
    }
  }

  getCurrent = type => {
    const { page, pagePublic, pageOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pagePublic
    } else if (type === general.MY_MEDIA) {
      return page
    } else {
      return pageOrg
    }
  }

  getPageSize = type => {
    const { pageSizePublic, pageSize, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pageSizePublic
    } else if (type === general.MY_MEDIA) {
      return pageSize
    } else {
      return pageSizeOrg
    }
  }

  onEditAudioDetail = id => {
    const { showEditAudioModal } = this.props
    showEditAudioModal({
      contentId: id,
      isShowEditAudio: true
    })
  }

  getNotFoundMsg = (type, t) => {
    if (type === general.PUBLIC_MEDIA_LIBRARY) {
      return t('audio:public_audio_not_found')
    } else if (type === general.ORGANIZATION_LIBRARY) {
      return t('audio:org_audio_not_found')
    } else {
      return t('audio:private_audio_not_found')
    }
  }

  renderAudio = (audioList, type) => {
    if (audioList === null) {
      return
    }
    const { t, isPageBuilder } = this.props
    const { rows } = audioList
    const { cardSelected } = this.state
    const NOT_FOUND_MESSAGE = this.getNotFoundMsg(type, t)
    const classNames = className(
      'media',
      { 'media-page__item': !cardSelected },
      { 'media-page__item-on-select': cardSelected }
    )
    const currentPage = this.getCurrent(type)
    const currentPageSize = this.getPageSize(type)
    return (
      <Fragment>
        {!audioList || _.isEmpty(rows) ? (
          <div className="media-page__no-medial-list">{NOT_FOUND_MESSAGE}</div>
        ) : cardSelected ? (
          <Fragment>
            <Row className="bg-modal">
              <Col span={14}>
                {' '}
                <div className="media-page__list bg-modal-left">
                  {this.getMediaListing(audioList).map(item => (
                    <div className={classNames} key={item.id}>
                      <AudioCard
                        data={item}
                        type={type}
                        confirmDeleteItem={this.confirmDeleteItem}
                        onEditAudioDetail={this.onEditAudioDetail}
                        handleViewDetails={this.handleViewDetails}
                        isPageBuilder={isPageBuilder}
                        cardSelected={
                          item.id === cardSelected ? cardSelected : ''
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="media-page__controls">
                  <Pagination
                    total={audioList.count}
                    current={currentPage}
                    pageSize={currentPageSize}
                    onChange={event => this.handlePagination(type, event)}
                  />
                </div>
              </Col>
              <Col span={10}>
                <ShowDetailAudio
                  handleRemoveAudio={this.handleRemoveAudio}
                  t={t}
                  data={rows}
                  removeCardSelection={this.removeCardSelection}
                />
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Fragment>
            <div className="media-page__list bg-modal">
              {this.getMediaListing(audioList).map(item => (
                <div className={classNames} key={item.id}>
                  <AudioCard
                    data={item}
                    type={type}
                    confirmDeleteItem={this.confirmDeleteItem}
                    onEditAudioDetail={this.onEditAudioDetail}
                    handleViewDetails={this.handleViewDetails}
                    isPageBuilder={isPageBuilder}
                    cardSelected={item.id === cardSelected ? cardSelected : ''}
                  />
                </div>
              ))}
            </div>
            <div className="media-page__controls">
              <Pagination
                total={audioList.count}
                current={currentPage}
                pageSize={currentPageSize}
                onChange={event => this.handlePagination(type, event)}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  onSearch = _.debounce((value, type) => this.onHandleSearch(value, type), 1000)

  onHandleSearch = (value, type) => {
    if (type === general.MY_MEDIA_LIBRARY) {
      this.setState(
        {
          search: value,
          page: 1,
          isFetching: true
        },
        () => {
          this.handleGetLibraryList()
        }
      )
    } else if (type === general.PUBLIC_MEDIA_LIBRARY) {
      this.setState(
        {
          searchPublic: value,
          pagePublic: 1,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    } else {
      this.setState(
        {
          searchOrg: value,
          pageOrg: 1,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  onFilter = (order, tab) => {
    this.setState({ isFetching: true })
    // Filter for My Media tab
    if (tab === general.MY_MEDIA_LIBRARY) {
      this.setState(
        {
          page: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetLibraryList()
        }
      )
    }

    // Filter for Public Library tab
    if (tab === general.PUBLIC_MEDIA_LIBRARY) {
      this.setState(
        {
          pagePublic: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    }

    // Filter for Org Library tab
    if (tab === general.ORGANIZATION_LIBRARY) {
      this.setState(
        {
          pageOrg: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  onOkClicked = id => {
    this.handleRemoveAudio(id).then(() => {
      console.log('Item removed')
    })
  }

  onCancelClicked = () => {
    console.log('Item removal cancelled')
  }

  confirmDeleteItem = ({ data: { title, id } }) => {
    const { t } = this.props
    Modal.confirm({
      title: t('audio:are_you_sure_to_delete'),
      centered: true,
      content: `${title}.mp3`,
      okText: general.OK,
      cancelText: general.CANCEL,
      onOk: () => this.onOkClicked(id),
      onCancel: this.onCancelClicked
    })
  }

  render() {
    const {
      publicLibrary,
      orgLibrary,
      isFetching,
      data,
      fileNotCompatible,
      fileSizeExceed,
      isRemovingMedia,
      tab
    } = this.state
    const { t, library, onShowCreateModal, getSelectedFile, src } = this.props
    const { uploadProgress } = library
    const { TabPane } = Tabs
    return (
      <Fragment>
        <Button
          type="success"
          shape="round"
          size="default"
          className="button-create btn-success"
          onClick={() => onShowCreateModal()}
        >
          {t('media:create')}
        </Button>

        {!_.isEmpty(data) && (
          <AudioPopUpCard onShowCreateModal={onShowCreateModal} />
        )}
        <div className="tab-nav-margin">
          <Tabs
            defaultActiveKey="1"
            onChange={this.handleChangeTabs}
            animated={false}
          >
            <TabPane tab={t('media:my_media')} key={general.MY_MEDIA_LIBRARY}>
              <FilterAudio
                onSearch={this.onSearch}
                t={t}
                tab={tab}
                onFilter={this.onFilter}
                type={general.MY_MEDIA_LIBRARY}
              />
              {isFetching || isRemovingMedia ? (
                <Spinner />
              ) : (
                this.renderAudio(data, general.MY_MEDIA_LIBRARY)
              )}
            </TabPane>
            <TabPane
              tab={t('media:public_library')}
              key={general.PUBLIC_MEDIA_LIBRARY}
            >
              <FilterAudio
                onSearch={this.onSearch}
                t={t}
                tab={tab}
                onFilter={this.onFilter}
                type={general.PUBLIC_MEDIA_LIBRARY}
              />
              {isFetching || isRemovingMedia ? (
                <Spinner />
              ) : (
                this.renderAudio(publicLibrary, general.PUBLIC_MEDIA_LIBRARY)
              )}
            </TabPane>

            <TabPane
              tab={t('media:organization_library')}
              key={general.ORGANIZATION_LIBRARY}
            >
              <FilterAudio
                onSearch={this.onSearch}
                t={t}
                tab={tab}
                onFilter={this.onFilter}
                type={general.ORGANIZATION_LIBRARY}
              />
              {isFetching || isRemovingMedia ? (
                <Spinner />
              ) : (
                this.renderAudio(orgLibrary, general.ORGANIZATION_LIBRARY)
              )}
            </TabPane>

            <TabPane
              tab={t('media:upload_from_computer')}
              key={general.UPLOAD_FROM_COMPUTER}
            >
              <DropzoneAudio
                t={t}
                getSelectedFile={getSelectedFile}
                uploadProgress={uploadProgress}
                fileNotCompatible={fileNotCompatible}
                fileSizeExceed={fileSizeExceed}
                src={src}
                onDrop={this.onDrop}
                uploadListClassName={this.uploadListClassName}
                checkedCompleteUpload={this.checkedCompleteUpload}
                resetFileInCompatibleMessage={this.resetFileInCompatibleMessage}
              />
            </TabPane>
          </Tabs>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('audio')(Audio)
