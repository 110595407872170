import React, { Component } from 'react'
import { Spinner, Modal, RenderArrows } from '../../../../../components'
import Template from './Template'
import { message } from 'antd'
import { ModalInner, Footer, Save } from './styled'
import {
  HeadWrap,
  ZoomBtn,
  Btn,
  LandscapePageContent,
  LandscapePageWrapper
} from '../../styled'
import { Pagination } from '../../../../../styles/blocks'
import api from '../../../../../services/api'
import { withTranslation } from 'react-i18next'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

class ModalLessonLandscape extends Component {
  constructor(props) {
    super(props)

    const {
      course: { courses_lessons: lessons = [], enrollment = {} },
      activeLessonId
    } = props

    const lesson = lessons.find(i => i.id === activeLessonId)
    const uids = lesson.info.pages_uids || []
    const completedUids =
      (enrollment && enrollment.progress && enrollment.progress[lesson.id]) ||
      []
    const index = uids.findIndex(uid => !completedUids.includes(uid))

    this.modalInner = null
    this.pageContent = null

    this.setModalInnerRef = el => {
      this.modalInner = el
      this.doResize()
    }

    this.setPageContentRef = el => {
      this.pageContent = el
      this.doResize()
    }

    this.state = {
      currentPage: index < 1 ? 1 : index,
      scale: 1,
      isMoveable: false,
      isGoToNextPageDisabled: false
    }
  }

  doResize = () => {
    if (this.pageContent && this.modalInner) {
      const contentWidth = this.pageContent.clientWidth
      const contentHeight = this.pageContent.clientHeight
      const wrapperWidth = this.modalInner.clientWidth
      const wrapperHeight = this.modalInner.clientHeight

      let scale = Math.min(
        wrapperWidth / contentWidth,
        wrapperHeight / contentHeight
      )

      if (scale > 1) scale = 1
      this.setState({ scale })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.doResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.doResize)
    this.postCheckOutData('willUnMount landscape')
  }

  postCheckOutData = param => {
    const {
      userToken,
      course: { id }
    } = this.props
    const data = {
      user_token: userToken
    }
    api.courses
      .checkOutCourse(id, data)
      .then(({ data }) => {
        console.log('response log checkout', data)
      })
      .catch(e => {})
  }

  changePage = page => {
    const {
      lesson: {
        item: { pages_landscape, id: lessonId }
      },
      course: { id },
      enrollCourse,
      t
    } = this.props

    const { currentPage, isGoToNextPageDisabled } = this.state

    if (isGoToNextPageDisabled) {
      message.warning(t('v3:warning_complete_quiz'))
      return
    }

    if (page > currentPage + 1) {
      return
    }

    this.setState({ currentPage: page })

    const { uid } = pages_landscape[page - 1]

    console.log('enroll course data check', id, lessonId, uid)

    enrollCourse(id, lessonId, uid)
  }

  openNextLesson = id => {
    this.postCheckOutData()
    this.setState({ currentPage: 1 })
    this.props.openNextLesson(id)
  }

  onDragStart = () => this.setState({ isMoveable: true })
  onDragStop = () => this.setState({ isMoveable: false })

  // toggle user's access to go to next page in the lesson
  toggleQuizRestriction = booleanValue => {
    this.setState({ isGoToNextPageDisabled: booleanValue })
  }

  render() {
    const {
      lesson: { fetch, item },
      toggleModal,
      modalVisible,
      course: { id, title, courses_lessons: lessons },
      activeLessonId,
      t
    } = this.props

    const { currentPage, scale, isMoveable } = this.state

    const isLast =
      activeLessonId && activeLessonId === lessons[lessons.length - 1].id

    return (
      <Modal
        width="1074px"
        visible={modalVisible}
        style={{ height: '692px' }}
        onCancel={() => toggleModal()}
        withFooter
        destroyOnClose
      >
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={7}
          limitToBounds={true}
          initialPositionX={0}
          initialPositionY={0}
          wheel={{ disabled: true }}
          disabled={isMoveable}
          doubleClick={{
            mode: 'reset'
          }}
          panning={{ excluded: ['input', 'textarea'] }}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <ModalInner>
              <section
                className="section"
                style={{ paddingBottom: 0, paddingLeft: 0 }}
              >
                <HeadWrap
                  style={{
                    paddingBottom: '4px',
                    position: 'relative',
                    top: '-30px'
                  }}
                >
                  <h2
                    style={{
                      fontSize: '26px',
                      fontWeight: '400',
                      color: '#2b2d2d'
                    }}
                  >
                    {title}
                  </h2>
                  {!fetch && (
                    <div className="btns">
                      <ZoomBtn
                        shape="round"
                        icon="zoom-in"
                        onClick={() => zoomIn()}
                      >
                        {t('v4:zoom_in')}
                      </ZoomBtn>
                      <ZoomBtn
                        shape="round"
                        icon="zoom-out"
                        onClick={() => zoomOut()}
                      >
                        {t('v4:zoom_out')}
                      </ZoomBtn>
                      <ZoomBtn shape="round" onClick={() => resetTransform()}>
                        {t('v4:zoom_reset')}
                      </ZoomBtn>
                    </div>
                  )}
                </HeadWrap>
              </section>
              {/* <CourseOwner style={{ padding: 0, position: 'relative', top: '-30px' }}>
            {organiser && (
              <CourseOwner.Name>
                {t('general:by')}&nbsp;
                <span>{organiser}</span>
              </CourseOwner.Name>
            )}
          </CourseOwner> */}
              <TransformComponent
                wrapperStyle={{ width: `100%` }}
                contentStyle={{ width: `100%` }}
              >
                <LandscapePageWrapper
                  ref={this.setModalInnerRef}
                  size={
                    item.pages_landscape &&
                    item.pages_landscape.length &&
                    item.pages_landscape[currentPage - 1].size
                  }
                  scale={scale}
                >
                  {fetch ? (
                    <Spinner size={10} top={35} />
                  ) : (
                    <LandscapePageContent
                      ref={this.setPageContentRef}
                      scale={scale}
                      size={
                        item.pages_landscape &&
                        item.pages_landscape.length &&
                        item.pages_landscape[currentPage - 1] &&
                        item.pages_landscape[currentPage - 1].size
                      }
                    >
                      <>
                        {/* <span>content goes here</span> */}

                        {item.pages_landscape &&
                          item.pages_landscape.length &&
                          item.pages_landscape[currentPage - 1].data.map(
                            page => {
                              if (
                                page.params.content === '' ||
                                !page.params.content
                              ) {
                                return ''
                              }
                              return (
                                <Template
                                  key={page.uid}
                                  node={page}
                                  ids={{
                                    courseId: id,
                                    lessonId: activeLessonId,
                                    pageId:
                                      item.pages_landscape[currentPage - 1].id
                                  }}
                                  onDragStart={this.onDragStart}
                                  onDragStop={this.onDragStop}
                                  toggleQuizRestriction={
                                    this.toggleQuizRestriction
                                  }
                                />
                              )
                            }
                          )}
                      </>
                    </LandscapePageContent>
                  )}
                </LandscapePageWrapper>
              </TransformComponent>
            </ModalInner>
          )}
        </TransformWrapper>
        <Footer>
          <Footer.Wrapp>
            <div className="styled-pagination">
              <Pagination.SlPagination
                total={item.pages_landscape && item.pages_landscape.length}
                defaultPageSize={1}
                onChange={this.changePage}
                itemRender={RenderArrows}
                current={
                  item.pages_landscape && item.pages_landscape.length
                    ? currentPage
                    : 0
                }
                simple
                disabled={fetch}
              />
            </div>
            <Footer.Right>
              <Save onClick={() => toggleModal(true)}>
                {t('buttons:back_to_course')}
              </Save>
              <Btn
                type="primary"
                className="rounded"
                onClick={() => this.openNextLesson(item.id)}
              >
                {isLast ? t('buttons:close_lesson') : t('buttons:next_lesson')}
              </Btn>
            </Footer.Right>
          </Footer.Wrapp>
        </Footer>
      </Modal>
    )
  }
}

export default withTranslation('coursepage')(ModalLessonLandscape)
