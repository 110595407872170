import React, { useState } from 'react'

import { Icon, Modal } from 'antd'

import './styles.scss'
import AiMazeGeneration from '../AiMazeGeneration'

const ChoiceModal = props => {
  const [isAiModalVisible, setIsAiModalVisible] = useState(false)

  const { onClose } = props

  if (isAiModalVisible) {
    return <AiMazeGeneration {...props} />
  }

  return (
    <Modal visible footer={false} centered={true} closable={false} width={800}>
      <div className="choice-modal">
        <p> What kind of maze would you like to create?</p>

        <div className="choice-modal__items">
          <div className="choice-modal__item" onClick={onClose}>
            <Icon type="user" />
            <h3>Manual Metamaze Creation</h3>

            <p>
              Manually create a maze by customizing the scenario and option tree
            </p>
          </div>

          <div
            className="choice-modal__item"
            onClick={() => setIsAiModalVisible(true)}
          >
            <Icon type="robot" />
            <h3>AI Metamaze Generation</h3>

            <p>Automatically generate a maze with AI</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChoiceModal
