import React, { useState } from 'react'
import moment from 'moment'
import { Alert, Button, Modal, Radio, Tag } from 'antd'
import { Spinner } from '../../components'
import history from '../../history'
import { api } from '../../services'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const defaultState = {
  data: [],
  pagination: {}
}

const SharebotSubscription = React.memo(
  ({ title, data, loading, orgIdValue }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isShareBotLoading, setIsShareBotLoading] = useState(false)
    const [isActiveSharebot, setIsActiveSharebot] = useState(defaultState)
    const [isTrialSharebot, setIsTrialSharebot] = useState(defaultState)
    const [subOpen, setSubOpen] = useState(false)
    const [subValue, setSubValue] = useState(null)
    const [unSubscriptionModal, setUnSubscriptionModal] = useState(false)

    const handleCancel = () => {
      setIsOpen(false)
      setSubOpen(false)
      setSubValue(null)
    }

    const isSharebotFree = (OrgId, cusEmail) => {
      setIsShareBotLoading(true)
      const shareBotTrialObj = {
        organization_id: OrgId,
        email: cusEmail
      }

      api.sharebot
        .createTrialSubscription(shareBotTrialObj)
        .then(() => {
          history.push('/knowledge-center')
        })
        .catch(err => {
          console.log('subscriptionUrl2------------------------->', err)
        })
        .finally(() => {
          setIsShareBotLoading(false)
          setIsOpen(false)
          setIsActiveSharebot(!isActiveSharebot)
          setIsTrialSharebot(!isTrialSharebot)
        })
    }

    const isSharebotSubscribe = () => {
      if (data.stripe_status === 'month' || data.stripe_status === 'year') {
        console.log(orgIdValue, data.id, 'active org id......?')
        setUnSubscriptionModal(true)
        setIsOpen(true)
      } else {
        setIsOpen(true)
        setSubOpen(true)
      }
    }

    const unSubConfirmBox = () => {
      setIsShareBotLoading(true)
      api.sharebot
        .unsubscribeSharebot(data.id)

        .then(res => {
          window.location.reload()
          console.log('res................>', res)
        })
        .catch(err => {
          console.log('subscriptionUrl2------------------------->', err)
        })
    }
    const handleNewSubscription = e => {
      setSubValue(e.target.value)
    }

    const subscribeShareBot = () => {
      setIsShareBotLoading(true)
      if (subValue === 1 || subValue === 2) {
        const shareBotTrialObj = {
          // organization_id: user.info.active_organization_id,
          organization_id: orgIdValue,
          type: subValue === 1 ? 'monthly' : 'year'
        }

        api.sharebot
          .createSharebotSubscription(shareBotTrialObj)
          .then(res => {
            if (res.data) {
              setIsOpen(false)
              const redirectLink = res.data && res.data
              window.location.href = redirectLink
            }
            console.log(res, 'check stripe data.....')
            //
          })
          .catch(err => {
            setIsOpen(false)
            console.log('subscriptionUrl2------------------------->', err)
          })
          .finally(() => {
            setIsShareBotLoading(false)
            setIsOpen(false)
            setIsActiveSharebot(!isActiveSharebot)
            setIsTrialSharebot(!isTrialSharebot)
            setSubValue(null)
          })
      }
    }

    const trialDatePeroid = tPeroid => {
      const tdate = moment(new Date()).format('DD-MM-YYYY')

      const date1 = moment(tdate, 'DD-MM-YYYY').valueOf()
      const date2 = moment(tPeroid, 'DD-MM-YYYY').valueOf()

      if (date2 >= date1) {
        return false
      }
      return true
    }
    const { t } = useTranslation()
    return (
      <>
        <div className="account-subscription-title">&#x2022; {title}</div>
        <div className="account-subscription-content">
          {loading ? (
            <Spinner />
          ) : data && Object.keys(data).length > 0 ? (
            <>
              <div className="account-subscription-content-card">
                <img src="/images/sharebot.png" alt="sharebot" />
                <div className="account-subscription-content-card-info">
                  <h2
                    className="account-subscription-content-card-info-title"
                    onClick={() =>
                      isSharebotFree(orgIdValue, data.customer.email)
                    }
                  >
                    {t('v3:sharebot_intel_knowledge_cente')}
                  </h2>
                  <div className="account-subscription-content-card-info-description">
                    {` ${t('v3:sharebot_welcome_paragraph')}`}
                  </div>

                  {/* share bot expire ui */}
                  <div className="account-subscription-content-card-info-price">
                    {data.stripe_status === 'trial' && (
                      <span>
                        <h1>
                          <Tag
                            style={{
                              borderRadius: '10px',
                              marginBottom: '10px',
                              color: '#fff',
                              backgroundColor: trialDatePeroid(
                                data.trial_start === data.trial_end
                                  ? moment(data.trial_start)
                                      .add(15, 'days')
                                      .format('DD-MM-YYYY')
                                  : moment(data.trial_end).format('DD-MM-YYYY')
                              )
                                ? 'red'
                                : 'green'
                            }}
                          >
                            {trialDatePeroid(
                              data.trial_start === data.trial_end
                                ? moment(data.trial_start)
                                    .add(15, 'days')
                                    .format('DD-MM-YYYY')
                                : moment(data.trial_end).format('DD-MM-YYYY')
                            )
                              ? t('v3:sharebot_expired')
                              : t('v3:sharebot_active')}
                          </Tag>
                        </h1>
                        15-Day Free Trial
                      </span>
                    )}

                    {(data.stripe_status === 'month' ||
                      data.stripe_status === 'year') && (
                      <span>
                        <h1>
                          <Tag
                            style={{
                              borderRadius: '10px',
                              marginBottom: '10px',
                              color: '#fff',
                              backgroundColor: trialDatePeroid(
                                moment(data.exp_date).format('DD-MM-YYYY')
                              )
                                ? 'red'
                                : 'green'
                            }}
                          >
                            {trialDatePeroid(
                              moment(data.exp_date).format('DD-MM-YYYY')
                            )
                              ? t('v3:sharebot_expired')
                              : t('v3:sharebot_active')}
                          </Tag>
                        </h1>
                        <h1>
                          <span>$</span>
                          {data.stripe_status === 'year'
                            ? `${data.price.unit_amount / 100}`
                            : `${data.price.unit_amount / 100}`}
                        </h1>
                        {data.stripe_status === 'year'
                          ? t('v3:yearly_subscription')
                          : t('v3:monthly_subscription')}
                      </span>
                    )}
                    {data.stripe_status === 'trial' && (
                      <div className="account-subscription-content-card-info-date">
                        <span className="start-date">
                          {t('v3:sharebot_start_date')}
                          {moment(data.trial_start).format('DD.MM.YYYY')}
                        </span>

                        <span className="end-date">
                          {t('v3:sharebot_end_date')}
                          {data.trial_start === data.trial_end
                            ? moment(data.trial_start)
                                .add(15, 'days')
                                .format('DD.MM.YYYY')
                            : moment(data.trial_end).format('DD.MM.YYYY')}
                        </span>
                      </div>
                    )}

                    {(data.stripe_status === 'month' ||
                      data.stripe_status === 'year') && (
                      <div className="account-subscription-content-card-info-date">
                        <span className="start-date">
                          {t('v3:sharebot_start_date')}
                          {moment(data.start_date).format('DD.MM.YYYY')}
                        </span>

                        <span className="end-date">
                          {t('v3:sharebot_end_date')}{' '}
                          {moment(data.exp_date).format('DD.MM.YYYY')}
                        </span>
                      </div>
                    )}

                    <Button
                      className={`sharebot-btn account-subscription-content-card-info-button ${
                        isActiveSharebot
                          ? 'sharebot-btn-primary'
                          : 'blue-bordered-button'
                      }`}
                      shape="round"
                      type={
                        isActiveSharebot ||
                        data.stripe_status === 'month' ||
                        data.stripe_status === 'year'
                          ? 'default'
                          : 'primary'
                      }
                      onClick={() => isSharebotSubscribe()}
                    >
                      {data.stripe_status === 'month' ||
                      data.stripe_status === 'year'
                        ? t('v3:unsubscribe')
                        : t('v3:subscribe_now')}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="account-subscription-error">
              <Alert message={t('v3:no_sharebot_org')} type={'info'} />
            </div>
          )}
        </div>

        <Modal
          title=""
          footer={null}
          visible={isOpen}
          onCancel={handleCancel}
          centered
        >
          <div className="account-subscription-delete">
            <div className="account-subscription-delete-title">
              <h2>{t('v3:sharebot_sure')}</h2>
              <h2>
                {t('v3:sharebot_do_you_want')}
                {subOpen
                  ? t('v3:subscribe_what')
                  : unSubscriptionModal
                  ? t('v3:unsubscribe_what')
                  : t('v3:sharebot_start_free_trial')}
              </h2>
              {subOpen && (
                <>
                  <Radio.Group
                    className="account-subscription-options"
                    onChange={handleNewSubscription}
                    value={subValue}
                  >
                    {[
                      {
                        id: 1,
                        title: t('v3:sub_month'),

                        price: `${data.price.unit_amount / 100}`
                      },
                      {
                        id: 2,
                        title: t('v3:sub_year'),

                        price: `${data.price.unit_amount / 10}`
                      }
                    ].map(option => (
                      <Radio
                        className="account-subscription-options-item"
                        key={option.id}
                        value={option.id}
                      >
                        <div className="account-subscription-options-item-container">
                          <span>
                            {option.title} ${option.price}
                          </span>

                          <div
                            className="account-subscription-content-card-info-description"
                            style={{ textTransform: 'initial' }}
                          >
                            {`${t('v3:question_per_month')}`}
                          </div>
                        </div>
                      </Radio>
                    ))}
                  </Radio.Group>

                  <div className="account-subscription-delete-buttons">
                    <Button
                      shape="round"
                      className="no-button"
                      onClick={handleCancel}
                    >
                      {t('buttons:cancel')}
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={() => subscribeShareBot()}
                      loading={isShareBotLoading}
                    >
                      {t('v3:confirm')}
                    </Button>
                  </div>
                </>
              )}
              {unSubscriptionModal && (
                <>
                  <div
                    style={{
                      marginTop: '10px',
                      textTransform: 'initial',
                      color: '#000'
                    }}
                  >
                    {t('v3:sharebot_para')}
                  </div>
                  <div className="account-subscription-delete-buttons">
                    <Button
                      shape="round"
                      className="no-button"
                      onClick={handleCancel}
                    >
                      {isActiveSharebot ? t('general:no') : t('buttons:cancel')}
                    </Button>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={() => unSubConfirmBox()}
                      loading={isShareBotLoading}
                    >
                      {isActiveSharebot ? t('general:yes') : t('v3:ai_confirm')}
                    </Button>
                  </div>
                </>
              )}
              {!subOpen && !unSubscriptionModal && (
                <div className="account-subscription-delete-buttons">
                  <Button
                    shape="round"
                    className="no-button"
                    onClick={handleCancel}
                  >
                    {isActiveSharebot ? t('general:no') : t('buttons:cancel')}
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={() =>
                      isSharebotFree(orgIdValue, data.customer.email)
                    }
                    loading={isShareBotLoading}
                  >
                    {isActiveSharebot ? t('general:yes') : t('v3:ai_confirm')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </>
    )
  }
)

export default SharebotSubscription
