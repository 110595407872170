//import { Row, Col, Field } from '../../../../../../../../src/styles/index'
import React from 'react'
import { Input } from 'antd'
import './Description.scss'

const Description = ({ t, setDescription, description }) => {
  const onDescriptionChange = e => {
    setDescription(e.target.value)
  }

  return (
    <div className="decription">
      <div className="decription__content">
        <div className="ant-row ant-form-item">
          <div className="description-input">
            <div className="description-input-title">
              {t('v3:podcast_description')}
            </div>
            <Input.TextArea
              value={description}
              placeholder={t('v3:podcast_description')}
              onChange={onDescriptionChange}
              rows={3}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Description
