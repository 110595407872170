import React, { useState, useEffect, Fragment } from 'react'
import {
  Form,
  Modal,
  Button,
  Rate,
  Input,
  Spin,
  Empty,
  message,
  Divider
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import * as reviewsActions from '../../store/actions/reviews'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const ReviewsButton = props => {
  const { id, form, type, userId, avgRating, podcast } = props
  const [showrating, setShowRating] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const reviews = useSelector(state => state.reviews.reviews)
  const [reviewed, setReviewed] = useState(true)
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.reviews.isFetching)
  const { t } = useTranslation()
  const handleRate = () => {
    setShowRating(true)
  }

  const handleAddSubmit = async values => {
    const data = values
    await dispatch(reviewsActions.addReviewsRequest(id, type, data))
    setShowRating(false)
    form.resetFields()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setFormLoading(true)
      form.validateFields(async (err, values) => {
        if (err) {
          console.log(err)
        }
        if (!values.rating && !values.review) {
          return message.error(t('v2:rating_review_req'))
        }
        await handleAddSubmit(values)
      })
    } catch (err) {
      console.log(err)
    } finally {
      setFormLoading(false)
    }
  }

  useEffect(() => {
    if (reviews && reviews.length > 0) {
      const checkreviews = reviews.filter(item => item.user_id === userId)
      if (checkreviews.length <= 0) {
        setReviewed(false)
      } else {
        setReviewed(true)
      }
    } else {
      setReviewed(false)
    }
  }, [reviews, userId])

  return (
    <>
      {showrating && (
        <Modal
          title={null}
          className="rating__modal"
          visible={showrating}
          onCancel={() => setShowRating(false)}
          footer={null}
        >
          <div className="rating">
            <h2>
              {t('v2:how_rate_this')} {type}?
            </h2>
            <p>{t('v2:amazing_above_expectations')}</p>
            <Form onSubmit={handleSubmit}>
              <Form.Item>
                {form.getFieldDecorator('rating', {
                  rules: [{ required: true, message: t('v2:rating_req') }],
                  initialValue: 0
                })(<Rate />)}
              </Form.Item>
              {form.getFieldValue('rating') > 0 && (
                <Form.Item>
                  {form.getFieldDecorator('review', {
                    rules: [{ required: true, message: t('v2:review_req') }],
                    initialValue: ''
                  })(
                    <Input.TextArea
                      rows={7}
                      placeholder={t('v2:please_add_review')}
                    ></Input.TextArea>
                  )}
                </Form.Item>
              )}
              <Button
                className="rating__btn"
                type="primary"
                size="large"
                shape="round"
                htmlType="submit"
                block
                loading={formLoading}
              >
                {t('v2:post')}
              </Button>
            </Form>
          </div>
        </Modal>
      )}
      {podcast ? (
        <div className="podcast-rating">
          <div className="podcast-rating-body">
            <Rate value={avgRating} />
          </div>
          <div className="podcast-rating-value">{avgRating}</div>
          {/* <div>
            <Button
              type="link"
              onClick={handleRate}
              disabled={reviewed || isFetching}
            >
              Review
            </Button>
          </div> */}
        </div>
      ) : (
        <Button
          type="primary"
          className="rate-btn"
          shape="round"
          onClick={handleRate}
          disabled={reviewed || isFetching}
        >
          {t('v2:rate')}
        </Button>
      )}
    </>
  )
}

export const useFetchReviews = (id, type) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(reviewsActions.getReviewsRequest(id, type))
    return () => {
      dispatch(reviewsActions.clearReviews())
    }
  }, [dispatch])
}

const ReviewsList = props => {
  const { userId, podcast, refreshReview } = props
  const { t } = useTranslation()
  const reviews = useSelector(state => state.reviews.reviews)
  const avgRate = useSelector(state => state.reviews.avg_rating)
  const isFetching = useSelector(state => state.reviews.isFetching)
  return (
    <>
      <Spin spinning={isFetching}>
        <div className="rate__header">
          {avgRate && !podcast && (
            <div className="rate-avg">
              <Rate value={avgRate} disabled={true} />
              <span className="rate-avg__value">{avgRate.toFixed(2)}</span>
            </div>
          )}
        </div>
        {reviews && reviews.length <= 0 && !isFetching && (
          <Empty description={t('v2:no_review_found')}></Empty>
        )}
        <div className="rating__lists">
          {reviews &&
            reviews.map(item => (
              <ReviewListItem
                refreshReview={refreshReview}
                key={item.id}
                item={item}
                userId={userId}
                podcast={podcast}
              />
            ))}
        </div>
      </Spin>
    </>
  )
}

const ReviewListItem = props => {
  const { item, userId, podcast, refreshReview } = props
  const [isEdit, setIsEdit] = useState(null)
  const [review, setReview] = useState('')
  const [rating, setRating] = useState(item.rating)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleTextareaChange = e => {
    setReview(e.target.value)
  }

  const handleRatingChange = value => {
    setRating(value)
  }

  const handleEdit = item => {
    setIsEdit(item)
  }

  const handleSave = async () => {
    const data = {
      review,
      rating
    }
    if (!review && !rating) {
      return message.error(t('v2:rating_review_req'))
    }
    await dispatch(reviewsActions.editReviewsRequest(isEdit.id, data))
    setIsEdit(null)
    if (podcast) {
      setTimeout(() => {
        refreshReview()
      }, 2500)
    }
  }

  const handleCancel = async () => {
    setIsEdit(null)
    setRating(item.rating)
    setReview(item.review)
  }

  useEffect(() => {
    if (isEdit !== null) {
      setRating(isEdit.rating)
      setReview(isEdit.review)
    }
  }, [isEdit, setReview, setRating])

  return (
    <div className="rating__item">
      {podcast ? (
        <Fragment>
          <div className="rating__item__header">
            <div className="rating__item__header_left">
              <div className="name">
                <span className="bold">{item.username}: </span>
                <span>{moment(item.updated_at).format('LL')}</span>
              </div>
            </div>
            <div className="rating__item__header_right">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="rate">
                  <Rate
                    value={rating}
                    disabled={isEdit === null}
                    onChange={handleRatingChange}
                  ></Rate>
                  <span
                    style={{
                      color: '#000',
                      fontSize: '1.5rem',
                      fontWeight: '600',
                      paddingLeft: '0.6rem'
                    }}
                  >
                    {item.rating}
                  </span>
                </div>
                {isEdit === null && item.user_id === userId && (
                  <Button type="text" onClick={() => handleEdit(item)}>
                    <EditOutlined />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <Divider style={{ margin: '0.5rem 0' }} />
        </Fragment>
      ) : (
        <div className="rating__item__header">
          <div className="rating__item__header_left">
            <div className="rate">
              <Rate
                value={rating}
                disabled={isEdit === null}
                onChange={handleRatingChange}
              ></Rate>
            </div>
            <div className="name">
              <span className="bold">{item.username}: </span>
              <span>{moment(item.updated_at).format('DD MM YYYY')}</span>
            </div>
          </div>
          <div className="rating__item__header_right">
            {isEdit === null && item.user_id === userId && (
              <Button type="text" onClick={() => handleEdit(item)}>
                <EditOutlined />
              </Button>
            )}
          </div>
        </div>
      )}
      {isEdit !== null ? (
        <>
          <Input.TextArea
            rows={5}
            value={review}
            onChange={handleTextareaChange}
          ></Input.TextArea>
          <div className="btn-actions">
            <Button type="text" size="small" onClick={handleCancel}>
              {t('buttons:cancel')}
            </Button>
            <Button type="primary" size="small" onClick={handleSave}>
              {t('buttons:save')}
            </Button>
          </div>
        </>
      ) : (
        <div className="rating__item__content">{item.review}</div>
      )}
    </div>
  )
}

export const ReviewsButtonModal = Form.create()(ReviewsButton)

export default ReviewsList
